import axiosInstance from 'api_adapter/axios_instance';
import schema from 'shared/schema';

export const readProfExps = async (request) => {
    const response = await axiosInstance.get('/professionalExperience', {
        params: {
            q: {
                useOtherStudent: request.readProfExpData.useOtherStudent,
                studentName: request.readProfExpData.studentName,
            }
        }
    });
    return response.data;
};

export const writeProfExp = async (request) => {
    const response = await axiosInstance.post('/professionalExperience', request);
    return response.data;
};

export const uploadProfExpPhoto = async (request) => {
    const response = await axiosInstance.post('/professionalExperience/imageUpload',
        request.photo,
        {
            headers: { "Content-Type": "multipart/form-data" },
        },
    );
    return response;
};