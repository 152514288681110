import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import AboutPage from 'about_page';
import { AccountProvider } from 'account';
import apiAdapter from 'api_adapter';
import HomePage from 'home_page';
import LoginPage from 'login_page';
import ServicePage from 'service_page';
//import TextbooksPage from 'textbooks_page';
import ProfExpHomePage from 'professional_experiences';
import AdminHomePage from 'admin_page';
import ProfileHomePage from 'profile_page';
import DocumentsPage from 'documents_page';
import ResourcesPage from 'resources_page';
import ServiceApprovalPage from 'service_approval_page';
import ProfessionalExperienceApprovalPage from 'professional_experience_approval_page';
import ReunionPage from 'reunion_page';
import React, { useEffect } from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom';
import * as PATHS from 'routing/paths';
import defaultTheme from 'theme';

const queries = async () => {
  // console.log(
  //   await apiAdapter.login({ username: 'kingpz', password: 'password' }),
  // );
  // console.log(await apiAdapter.readUser());
  console.log(await apiAdapter.readDocumentCategories());
  console.log(
    await apiAdapter
      .readDocuments({
        categoryIds: ['other', 'honors_handbook'],
        pagination: {
          pageNo: 0,
          rowsPerPage: 5,
          order: {
            orderType: 2,
            orderBy: 'name',
          },
        },
      }),
  );
  console.log(
    await apiAdapter
      .readServiceHours({
        useSession: true,
        reviewStatus: 1,
        pagination: {
          pageNo: 0,
          rowsPerPage: 5,
          order: {
            orderType: 2,
            orderBy: 'name',
          },
        },
      }),
  );
  console.log(await apiAdapter.readServiceHour({ id: 2446 }));
  // console.log(await apiAdapter.writeServiceHour({
  //   serviceHour: {
  //     description: 'This is a description for the test service hour',
  //     type: 1,
  //     location: 'Student Center',
  //     supervisor: {
  //       name: 'Melissa Richards',
  //       contact: 'richarmc@clarkson.edu',
  //     },
  //     serviceDate: { unixTime: 1573493829 },
  //     durationRequested: {
  //       minutes: 30,
  //       hours: 1,
  //     },
  //   },
  // }));
  // console.log(await apiAdapter.logout());
};

export default function App() {
  useEffect(() => { queries(); }, []);

  return (
    <AccountProvider>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={defaultTheme}>
          <BrowserRouter>
            <Routes>
              <Route path={PATHS.HOME} element={<HomePage />} />
              <Route path={`${PATHS.ABOUT}/*`} element={<AboutPage />} />
              <Route path={PATHS.LOGIN} element={<LoginPage />} />
              <Route path={`${PATHS.SERVICE}/*`} element={<ServicePage />} />
              <Route path={`${PATHS.DOCUMENTS}/*`} element={<DocumentsPage />} />
              <Route path={`${PATHS.RESOURCES}/*`} element={<ResourcesPage />} />
              <Route path={`${PATHS.PROFEXP}/*`} element={<ProfExpHomePage />} />
              <Route path={`${PATHS.SERVICEAPPROVE}/*`} element={<ServiceApprovalPage />} />
              <Route path={`${PATHS.PROFEXPAPPROVE}/*`} element={<ProfessionalExperienceApprovalPage />} />
              <Route path={`${PATHS.ADMIN}/*`} element={<AdminHomePage />} />
              <Route path={`${PATHS.PROFILE}/*`} element={<ProfileHomePage />} />
              <Route path={`${PATHS.REUNION}/*`} element={<ReunionPage />} />            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </LocalizationProvider>
    </AccountProvider>
  );
}
// <Route path={`${PATHS.TEXTBOOKS}/*`} element={<TextbooksPage />} />
