import ViewModel from "pattern/view_model";

export default class SubmitProfExpViewModel extends ViewModel {
    constructor(isSuccessful, errorMessage) {
        super();
        this.isSuccessful = isSuccessful;
        this.errorMessage = errorMessage;
    }
}

const create = ({
    isSuccessful = false,
    errorMessage = '',
} = {}) => { new SubmitProfExpViewModel(isSuccessful, errorMessage) };

export const submitProfExpViewModelFactory = {
    create,
}