import ViewModel from 'pattern/view_model';

export const COMMITTEE_SUMMARY_TYPE = {
  PARAGRAPH: 'paragraph',
  LIST: 'list',
};

/**
 * ViewModel for the Committee .
 *
 * @property {String} type the type of summary (i.e. paragraph or text).
 * @property {String} paragraphText the summary text represented as a paragraph.
 * @property {Array<String>} listText the summary text represented as an
 * unordered list.
 */
export default class CommitteeSummaryViewModel extends ViewModel {
  /**
   * @param {String} type the type of summary (i.e. paragraph or text).
   * @param {String} paragraphText the summary text represented as a paragraph.
   * @param {Array<String>} listText the summary text represented as an
   * unordered list.
   */
  constructor(type, paragraphText, listText) {
    super();

    this.type = type;
    this.paragraphText = paragraphText;
    this.listText = listText;
  }
}

/**
 * Creates a new instance of the {@link CommitteeSummaryViewModel}.
 *
 * @param {Object} kwargs
 * @param {String} kwargs.type
 * @param {String} kwargs.paragraphText
 * @param {Array<String>} kwargs.listText
 *
 * @returns {CommitteeSummaryViewModel} the new instance.
 */
const create = ({
  type = COMMITTEE_SUMMARY_TYPE.PARAGRAPH,
  paragraphText = '',
  listText = [],
} = {}) => new CommitteeSummaryViewModel(type, paragraphText, listText);

/**
 * Converts a POJO into a new instance of the {@link CommitteeSummaryViewModel}.
 *
 * @param {Object} model object model for summary.
 *
 * @returns {CommitteeSummaryViewModel} the new instance.
 */
const convert = (model) => {
  if (
    model.type === COMMITTEE_SUMMARY_TYPE.PARAGRAPH &&
    typeof model.text === 'string'
  ) {
    return new CommitteeSummaryViewModel(model.type, model.text, []);
  } if (
    model.type === COMMITTEE_SUMMARY_TYPE.LIST &&
    Array.isArray(model.text)
  ) {
    model.text.forEach((itemText) => {
      if (typeof itemText !== 'string') {
        throw new Error('item in model.text must be string.');
      }
    });

    return new CommitteeSummaryViewModel(model.type, '', model.text);
  }

  throw new Error('the model.text must match the model.type provided.');
};

export const committeeSummaryViewModelFactory = {
  create,
  convert,
};
