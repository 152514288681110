import {
    Typography,
} from '@mui/material';
import { styled } from "@mui/system";

export const Container = styled('div')`
    font-size: 20px;
    color: black;
`;

export const Inner = styled(Typography)`
    display: flex;
    flex-direction: column;
    padding: 50px 5%;
    max-width: 1100px;
    margin: auto;
`;
