import axiosInstance from 'api_adapter/axios_instance';
import schema from 'shared/schema';

const _ReadDocumentCategoriesResponsePB =
  schema.lookup('http.read_document_categories.ReadDocumentCategoriesResponse');
const _ReadDocumentsResponsePB =
  schema.lookup('http.read_documents.ReadDocumentsResponse');

export const readDocumentCategories = async () => {
  const response = await axiosInstance.get('/documents/categories');

  return _ReadDocumentCategoriesResponsePB.fromObject(response.data);
};

export const readDocuments = async (request) => {
  const response =
    await axiosInstance.get(
      '/documents',
      {
        params: {
          categoryIds: request?.categoryIds ?? undefined,
          searchText: request?.searchText ?? undefined,
          pageNo: request?.pagination?.pageNo ?? undefined,
          rowsPerPage: request?.pagination?.rowsPerPage ?? undefined,
          orderBy: request?.pagination?.order?.orderBy ?? undefined,
          orderType: request?.pagination?.order?.orderType ?? undefined,
        },
      },
    );

  return _ReadDocumentsResponsePB.fromObject(response.data);
};
