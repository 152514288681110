import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import styled from '@mui/material/styles/styled';

// Button container styles ================================================

export default styled(Button)`
    border-radius: ${({ border }) => border === "rounded" ? "35px" : "5px"};
    height: fit-content;
    min-width: 0px;
    padding: 0px 0px;
    width: fit-content;
`;