/**
 * Base class for a viewmodel. A viewmodel models the data that is passed to
 * react components.
 *
 * The data is de-normalized (with a few exceptions such
 * as the PaginationViewModel) and requires no further processing or
 * transormations to be shown properly by the component it is passed to.
 *
 * A view-model may be used by several different components and can be
 * generally mapped to a data layer object (e.g. Documents proto can be mapped
 * to the DocumentsViewModel).
 */
export default class ViewModel {}
