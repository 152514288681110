/* eslint-disable import/prefer-default-export */
import { responsiveFontSizes } from '@mui/material';
import axiosInstance from 'api_adapter/axios_instance';
import schema from 'shared/schema';

const _ReadServiceHourResponsePB =
  schema.lookup('http.read_service_hour.ReadServiceHourResponse');
const _ReadServiceHoursResponsePB =
  schema.lookup('http.read_service_hours.ReadServiceHoursResponse');
const _WriteServiceHourResponsePB =
  schema.lookup('http.write_service_hour.WriteServiceHourResponse');

export const readServiceHours = async (request) => {
  const response =
    await axiosInstance.get(
      '/serviceHours',
      {
        params: {
          useSession: request?.useSession ?? undefined,
          username: request?.username ?? undefined,
          searchText: request?.searchText ?? undefined,
          reviewStatus: request?.reviewStatus ?? undefined,
          pageNo: request?.pagination?.pageNo ?? undefined,
          rowsPerPage: request?.pagination?.rowsPerPage ?? undefined,
          orderBy: request?.pagination?.order?.orderBy ?? undefined,
          orderType: request?.pagination?.order?.orderType ?? undefined,
        },
      },
    );

  const data = _ReadServiceHoursResponsePB.fromObject(response.data);
  return data;
};

export const readServiceHour = async (request) => {
  const response =
    await axiosInstance.get(`/serviceHours/${request?.id ?? -1}`);

  return _ReadServiceHourResponsePB.fromObject(response.data);
};

export const writeServiceHour = async (request) => {
  const response =
    await axiosInstance.post(
      '/serviceHours',
      {
        description: request?.serviceHour?.description,
        type: request?.serviceHour?.type,
        location: request?.serviceHour?.location,
        supervisorName: request?.serviceHour?.supervisor?.name,
        supervisorContact: request?.serviceHour?.supervisor?.contact,
        serviceDate: request?.serviceHour?.serviceDate?.unixTime,
        hoursRequested: request?.serviceHour?.durationRequested?.hours,
        minutesRequested: request?.serviceHour?.durationRequested?.minutes,
        imgPath: request?.serviceHour?.imgPath
      },
    );
  return response.data;
};

export const uploadServicePhoto = async (request) => {
  const response = await axiosInstance.post('/serviceHours/imageUpload',
    request.photo,
    {
      headers: { "Content-Type": "multipart/form-data" },
    },
  );
  return response;
};
