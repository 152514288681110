import React from 'react';
import {
    Button,
    Box,
    FormControl,
    TextField,
    Input,
    Typography,
    Modal,
    Select,
    InputLabel,
    FormLabel,
} from '@mui/material';
import {
    DatePicker,
} from '@mui/x-date-pickers';
import { styled } from '@mui/material/styles';

export const ContainerSx = styled(Box)`
  align-items: center;
  color: black;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 1100px;
  padding: 50px 0;
  font-family: "Roboto", san-serif;
`;

export const ColumnsSx = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: inherit;
    justify-content: space-between;
`;

export const ServiceSubmitFormSx = styled(FormControl)`
    padding: 0px 10px;
    margin-bottom: 5px;
    width: calc(0.9 * 100vw);
    max-width: 1100px;
`;
ServiceSubmitFormSx.defaultProps = {
    component: 'form',
    noValidate: true,
    fullWidth: true,
};

export const ServiceSubmitButtonSx = styled(Button)`
    font-size: 15 px;
    font-weight: bold;
    margin-top: 15px;
    margin-botton: 5px;
    width: 100%;
`;
ServiceSubmitButtonSx.defaultProps = {
    type: 'submit',
    variant: 'contained',
};

export const ServiceSubmitErrorTextSx = styled(Typography)`
  color: red;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-transform: none;
  min-height: 24px;
  margin-top: 10px;
`;

// Old Service Fields: Type, Date, Description, Supervisor, Contact, Location
// New Service Fields: Type, Date, Description, Supervisor, Location, Photo Upload

export const ServiceSubmitDescSx = styled(TextField)``;
ServiceSubmitDescSx.defaultProps = {
    margin: 'normal',
    required: true,
    fullWidth: true,
    minRows: 4,
};

export const ServiceSubmitSupervisorSx = styled(TextField)``;
ServiceSubmitSupervisorSx.defaultProps = {
    margin: 'normal',
    required: true,
    fullWidth: true,
};

export const ServiceSubmitSupervisorContactSx = styled(TextField)``;
ServiceSubmitSupervisorContactSx.defaultProps = {
    margin: 'normal',
    required: true,
    fullWidth: true,
};

export const ServiceSubmitLocationSx = styled(TextField)``;
ServiceSubmitLocationSx.defaultProps = {
    margin: 'normal',
    required: true,
    fullWidth: true,
};

export const ServiceSubmitTypeSx = styled(Select)``;
ServiceSubmitTypeSx.defaultProps = {
    margin: 'normal',
    required: true,
    fullWidth: true,
};

export const ServiceSubmitDateSx = styled(DatePicker)`
    color: black;
    width: 49%;
`;
ServiceSubmitDateSx.defaultProps = {
    margin: 'normal',
    required: true,
};

export const BlankField = styled(TextField)`
    color: black;
`;
BlankField.defaultProps = {
    margin: 'normal',
    required: true,
    readOnly: true,
};

export const ServiceSubmitHoursSx = styled(TextField)`
    width: 49%;
`;
ServiceSubmitHoursSx.defaultProps = {
    margin: 'normal',
    required: true,
    inputProps: { min: 0, step: 0.25, default: 0.00 },
    type: 'number',
};

export const ServiceTypeOptionSx = styled(Typography)`
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    text-transform: none;
    min-height: 24px;
    margin-left: 10px;
`;

export const FormInputLabelSx = styled(InputLabel)`
    margin-left: 10px;
`;

export const ImageInputLabelSx = styled(FormLabel)``;

export const ImageUploadButtonSx = styled(Button)`
    font-size: 15 px;
    font-weight: bold;
    margin-top: 15px;
    margin-botton: 5px;
    width: 100%;
`;

export const ImageUploadSx = ({ buttonText, handleChange, inputId, buttonId }) => {
    return <div style={{ maxWidth: 'max-content' }}>
        <input onChange={handleChange} accept='image/*' type='file' name={inputId} id={inputId} style={{ display: 'none' }} />
        <label htmlFor={inputId}>
            <Button variant='contained' component="span" id={buttonId}>
                {buttonText}
            </Button>
        </label>
    </div >;
};

export const ImageDataSx = ({ imageSizeId }) => {
    return <div style={{ maxWidth: 'max-content' }}>
        <p>Image Size: <span id={imageSizeId}>None Selected</span></p>
    </div>
};

export const ViewToggle = ({ idPast, idSubmit }) => {
    const goToPast = (event) => {
        document.getElementById(idPast).style.display = "flex";
        document.getElementById(idSubmit).style.display = "none";
    };

    const goToForm = (event) => {
        document.getElementById(idPast).style.display = "none";
        document.getElementById(idSubmit).style.display = "flex";
    };

    return <div style={{ maxWidth: 'max-content' }}>
        <Button onClick={goToPast}>View Past Hours</Button>
        <Button onClick={goToForm}>Submit Form</Button>
    </div>
};
