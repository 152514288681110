import PropTypes from 'prop-types';
import React, {
  ReactElement,
} from 'react';
import {
  ContainerSx,
  ImageSx,
  NameSx,
  PositionSx,
  EmailSx,
} from 'about_page/member_card/styles/member_card.style';

/**
 * MemberCard is used to visualize the information for the members of the
 * steering board.
 *
 * @param {ReactElement} children the content of the component.
 *
 * @returns the component
 */
export default function MemberCard({ children }) {
  return <ContainerSx>{children}</ContainerSx>;
}

MemberCard.propTypes = {
  children: PropTypes.node.isRequired,
};

/**
 * Displays the email of the member.
 *
 * @param {String} href the URL to link to when the email is clicked.
 * @param {String} children the email address of the member.
 *
 * @returns the component
 */
MemberCard.Email = function MemberCardEmail({ href, children }) {
  return <EmailSx href={href}>{children}</EmailSx>;
};

MemberCard.Email.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};

/**
 * Displays the image of the member. If a proper path isn't provided then
 * a blank profile is used.
 *
 * @param {String} href The URL to link to when the button is clicked.
 *
 * @returns {ReactElement} the component
 */
MemberCard.Image = function MemberCardImage({ src }) {
  // TODO: validate the image src
  const validatedSrc = src !== '' ? src : '/images/members/blank_profile.png';
  return <ImageSx src={validatedSrc} />;
};

MemberCard.Image.propTypes = {
  src: PropTypes.string.isRequired,
};

/**
 * Displays the name of the member.
 *
 * @param {String} children the content of the component
 *
 * @returns {ReactElement} the component
 */
MemberCard.Name = function MemberCardName({ children }) {
  return <NameSx>{children}</NameSx>;
};

MemberCard.Name.propTypes = {
  children: PropTypes.string.isRequired,
};

/**
 * Displays the position of the member.
 *
 * @param {String} children the content of the component
 *
 * @returns {ReactElement} the component
 */
MemberCard.Position = function MemberCardPosition({ children }) {
  return <PositionSx>{children}</PositionSx>;
};

MemberCard.Position.propTypes = {
  children: PropTypes.string.isRequired,
};
