import React, {
    ReactElement,
} from 'react';
import PageTemplate from 'page_template';
import HeaderCard from 'components/header_card';
import Button from 'components/button';
import * as PATHS from 'routing/paths';

import {
    Container,
    Inner,
    ColumnsSx,
} from './styles/documents_page.style';
import {
    Heading,
    Content,
    DocuemtnsContainer,
    DocumentsText,
    List,
    ListItem,
    RowList,
} from './styles/landing_page_documents.style';

// add navagation to login page if they are not already logged in

export default function DocumentLandingPage() {
    return (
        <PageTemplate active="documents">
            <HeaderCard bkgSrc="/images/headers/Textbooks.jpg">
                <HeaderCard.Title>Documents</HeaderCard.Title>
            </HeaderCard>
            <Container>
                <Inner>
                    <RowList>
                        <ListItem>
                            <h2 align-items = "center">Handbook</h2>
                            <br></br>
                            <embed src="/files/Clarkson_Honors_Handbook_2024-2025.pdf" width="399px" height="562px" />
                        </ListItem>
                        <ListItem>
                            <h2 text-align = "center" align-items = "center">Capstone Guide </h2>
                            <br></br>
                            <embed src="/files/Honors Capstone Guide 2024.pdf" width="399px" height="562px" />
                        </ListItem>
                    </RowList>    
                </Inner>
            </Container>
        </PageTemplate>
    );
}

{/*
DocumentsLandingPage.DocumentsButton = function DocumentsLandingPageTextbooksButton({ children, ...otherProps }) {
    return (
        <DocumentsContainer>
            <Button variant="contained" border="rounded" {...otherProps}>
                <DocumentsText to={PATHS.DOCUMENTS + '/need_to_put_pathing_here'} state={{ from: location.pathname }}>{children}</DocumentsText>
            </Button>
        </DocumentsContainer>
    );
};*/}