import axiosInstance from 'api_adapter/axios_instance';
import schema from 'shared/schema';

export const readNewService = async (request) => {
    const response = await axiosInstance.get('/approve/newService', {});
    return response;
};

export const getStudentList = async (request) => {
    const response = await axiosInstance.get('/approve/studentList', {});
    return response;
};

export const updateServiceHour = async (request) => {
    const response = await axiosInstance.post('/approve/updateHour', request);
    return response;
};

export const getStudentHours = async (request) => {
    const response = await axiosInstance.get('/approve/studentIndividualHours', {
        params: {
            q: request.searchName,
        },
    });
    return response;
};

export const countBlankService = async (request) => {
    const response = await axiosInstance.get('/approve/countNewService', {});
    return response.data.countService[0].count;
};
