import ViewModel from "pattern/view_model";

export default class SubmitServiceViewModel extends ViewModel {
    /**
     * @param {Boolean} isSuccessful
     * @param {String} errorMessage
     */
    constructor(isSuccessful, errorMessage) {
        super();
        this.isSuccessful = isSuccessful;
        this.errorMessage = errorMessage;
    }
}

const create = ({
    isSuccessful = false,
    errorMessage = '',
    data = '',
} = {}) => new SubmitServiceViewModel(isSuccessful, errorMessage);

export const submitServiceViewModelFactory = {
    create,
};