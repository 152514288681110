import ViewModel from 'pattern/view_model';
import * as PATHS from 'routing/paths';

/**
 * ViewModel for the nav bar item.
 *
 * @property {String} id id of the nav bar item.
 * @property {String} label label of the nav bar item.
 * @property {String} path path to navigate to after nav bar item is clicked.
 */
export default class NavBarItemViewModel extends ViewModel {
  constructor(id, label, path) {
    super();

    this.id = id;
    this.label = label;
    this.path = path;
  }
}

/**
 * Creates a new instance of the account view-model.
 *
 * @param {Object} kwargs
 * @param {String} kwargs.id id of the nav bar item.
 * @param {String} kwargs.label label of the nav bar item.
 * @param {String} kwargs.path path to navigate to after nav bar item is
 * clicked.
 *
 * @returns {NavBarItemViewModel} view-model for the account.
 */
const create = ({
  id = '',
  label = '',
  path = PATHS.HOME,
} = {}) => new NavBarItemViewModel(id, label, path);

/** Factory for creating {@link NavBarItemViewModel}. */
export const navBarItemViewModelFactory = {
  create,
};
