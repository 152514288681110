import {
  Box,
  Link,
  Typography,
} from '@mui/material';
import {
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  Twitter as TwitterIcon,
} from '@mui/icons-material';
import styled from '@mui/material/styles/styled';

// Footer container styles =====================================================

export const ContainerSx = styled(Box)`
  background-color: ${({ theme }) => theme.palette.primary.main}
`;

export const InnerSx = styled(Box)`
  display: grid;
  grid-template-columns: [col1-start] 50% [col1-end col2-start] 50% [col2-end];
  grid-template-rows: [row1-start] 50% [row1-end row2-start] 50% [row2-end];
  padding: 10px 20px;
`;

// Contact styles ==============================================================

export const ContactContainerSx = styled(Box)`
  grid-row-start: row1-start;
  grid-row-end: row2-end;
  color: white;
`;

export const ContactHeaderSx = styled(Typography)`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: 15px;
  margin: 0px;

  ${(props) => props.theme.breakpoints.down('xs')} {
    font-size: 9px;
  }
`;
ContactHeaderSx.defaultProps = {
  variant: 'h6',
};

export const ContactTextSx = styled(Typography)`
  color: white;
  font-size: 12px;
  margin-top: 5px;

  ${(props) => props.theme.breakpoints.down('xs')} {
    font-size: 7px;
  }
`;
ContactTextSx.defaultProps = {
  variant: 'body2',
};

// Social Media styles==========================================================

export const SocialMediaContainerSx = styled('span')`
  align-items: center;
  align-self: start;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  gap: 15px;
  grid-row-start: row1-start;
  grid-row-end: row1-end;
  grid-column-start: col2-start;
  grid-column-end: col2-end;
  justify-self: end;
`;

export const SocialMediaLinkSx = styled(Link)`
  align-items: center;
  color: white;
  cursor: pointer;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  text-decoration: none;
`;

// Social Media icon styles ====================================================

export const FacebookIconSx = styled(FacebookIcon)``;

export const InstagramIconSx = styled(InstagramIcon)``;

export const TwitterIconSx = styled(TwitterIcon)``;

// Copyright styles ============================================================

export const CopyrightTextSx = styled(Typography)`
  align-self: end;
  color: white;
  font-size: 11px;
  grid-column-start: col2-start;
  grid-column-end: col2-end;
  grid-row-start: row2-start;
  grid-row-end: row2-end;
  justify-self: end;
  text-align: right;

  ${(props) => props.theme.breakpoints.down('xs')} {
    font-size: 7px;
  }
`;
CopyrightTextSx.defaultProps = {
  variant: 'body2',
};
