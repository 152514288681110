import React, {
    ReactElement,
  } from 'react';
  import {
    Routes,
    Route,
  } from 'react-router-dom';
  import AdminHomePage from './admin_home_page';
  import ErrorPage from 'error_page';
  
  /**
   * Component for textbooks page. The textbooks page consists of a picture of saul goodman
   *
   * @returns {ReactElement} the textbooks page component.
   */
  
  export default function AdminPage() {
    return (
      <Routes>
        <Route exact path="/" element={<AdminHomePage />} />
        <Route exact path="*" element={<ErrorPage />} />
      </Routes>
    );
  }
  