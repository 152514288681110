import {
  ContainerSx,
  ErrorButtonSx,
  ErrorImageSx,
  HeadingSx,
  SubHeadingSx,
} from 'error_page/styles/error_page.style';
import PageTemplate from 'page_template';
import PropTypes from 'prop-types';
import React, {
  ReactElement,
} from 'react';
import * as ROUTES from 'routing/paths';

function getErrorImageSrc(errorType) {
  if (errorType === '404') {
    return '/images/error_page/404_logo.png';
  } if (errorType === 'access_denied') {
    return '/images/error_page/access_denied_logo.png';
  }

  throw new Error(`invalid error type provided: '${errorType}'`);
}

function getHeadingText(errorType) {
  if (errorType === '404') {
    return 'OOPS! PAGE NOT FOUND';
  } if (errorType === 'access_denied') {
    return 'OOPS! ACCESS DENIED';
  }

  throw new Error(`invalid error type provided: '${errorType}'`);
}

function getSubHeadingText(errorType) {
  if (errorType === '404') {
    return 'The page you are looking for has possibly been deleted, moved,' +
      ' renamed, or never existed.';
  } if (errorType === 'access_denied') {
    return 'You do not have permission to access this page. Contact the' +
      ' administrator if this is a mistake.';
  }

  throw new Error(`invalid error type provided: '${errorType}'`);
}

/**
 * Error Page. There are two different error pages:
 *  * 404 error page
 *  * access denied error page
 *
 * @param {Object} param
 * @param {String} param.errorType
 *
 * @returns {ReactElement}
 */
export default function ErrorPage({ errorType }) {
  return (
    <PageTemplate active="error">
      <ContainerSx>
        <ErrorImageSx src={getErrorImageSrc(errorType)} />
        <HeadingSx>
          {getHeadingText(errorType)}
        </HeadingSx>
        <SubHeadingSx>
          {getSubHeadingText(errorType)}
        </SubHeadingSx>
        <ErrorButtonSx to={ROUTES.HOME}>
          Go Home
        </ErrorButtonSx>
      </ContainerSx>
    </PageTemplate>
  );
}

ErrorPage.propTypes = {
  errorType: PropTypes.string,
};

ErrorPage.defaultProps = {
  errorType: '404',
};
