import apiAdapter from 'api_adapter';
import LoginViewModel, {
  loginViewModelFactory,
} from 'login_page/view_model/login.viewmodel';
import {
  encryptCeasar,
} from './helper/encryptPW';
import schema from 'shared/schema';

const _StatusPB = schema.lookup('core.status.Status');

/**
 * Logs in the user.
 *
 * @param {Object} kwargs
 * @param {String} kwargs.username the username for the user.
 * @param {String} kwargs.password the password for the user.
 *
 * @return {Promise<LoginViewModel>} login view model.
 */
const login = async ({ username = '', password = '' } = {}) => {
  if (!password || password === '' || !username || username === '') {
    return loginViewModelFactory
      .create({
        isSuccessful: false,
        errorMessage: 'Username and password must be entered',
      });
  }

  try {
    const response = await apiAdapter.login({ username, password: await encryptCeasar(password) });
    if (_StatusPB[response.header.status] === _StatusPB[_StatusPB.SUCCESS]) {
      return loginViewModelFactory.create({ isSuccessful: true });
    }
    return loginViewModelFactory
      .create({
        isSuccessful: false,
        errorMessage: response.header.message,
      });
  } catch (error) {
    return loginViewModelFactory
      .create({ isSuccessful: false, errorMessage: error.message });
  }
};

const loginPageController = {
  login,
};

export default loginPageController;
