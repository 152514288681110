import React from 'react';
import {
    Button,
    TextField,
} from '@mui/material';
import {
    DatePicker,
} from '@mui/x-date-pickers';
import { styled } from '@mui/material/styles';

export const ProfExpSubmitEventName = styled(TextField)``;
ProfExpSubmitEventName.defaultProps = {
    margin: 'normal',
    required: true,
    fullWidth: true,
};

export const ProfExpSubmitEventLocation = styled(TextField)``;
ProfExpSubmitEventLocation.defaultProps = {
    margin: 'normal',
    required: true,
    fullWidth: true,
};

export const ProfExpSubmitReflection = styled(TextField)``;
ProfExpSubmitReflection.defaultProps = {
    margin: 'normal',
    required: true,
    fullWidth: true,
    minRows: 4,
};

export const ProfExpSubmitDate = styled(DatePicker)`
    color: black;
    width: 100%;
`;
ProfExpSubmitDate.defaultProps = {
    margin: 'normal',
    required: true,
};

export const ViewToggle = ({ idPast, idSubmit }) => {
    const goToPast = (event) => {
        document.getElementById(idPast).style.display = "flex";
        document.getElementById(idSubmit).style.display = "none";
    };

    const goToForm = (event) => {
        document.getElementById(idPast).style.display = "none";
        document.getElementById(idSubmit).style.display = "flex";
    };

    return <div style={{ maxWidth: 'max-content' }}>
        <Button onClick={goToPast}>View Past Experiences</Button>
        <Button onClick={goToForm}>Submit Form</Button>
    </div>
};
