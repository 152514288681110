import ViewModel from 'pattern/view_model';
import schema from 'shared/schema';

const _UserPB = schema.lookup('core.account.User');
const _PermissionsPB = schema.lookup('core.account.Permissions');

export const AccountPermissions = {
  INVALID: 'INVALID',
  UNAUTH: 'UNAUTH',
  WEBMASTER: 'WEBMASTER',
  ADMIN: 'ADMIN',
  REGULAR_STUDENT: 'REGULAR_STUDENT',
  SERVICE_CHAIR: 'SERVICE_CHAIR',
  CLASS_SERVICE_REP: 'CLASS_SERVICE_REP',
  CO_CHAIR: 'CO_CHAIR',
  ACTIVITIES_CHAIR: 'ACTIVITIES_CHAIR',
  ACTIVITIES_REP: 'ACTIVITIES_REP',
};

/** ViewModel for the user account. */
export default class AccountViewModel extends ViewModel {
  /**
   * @param {String} username username of the user.
   * @param {String} name name of the user.
   * @param {String} permissions permissions of the user.
   */
  constructor(username, name, permissions) {
    super();

    this.username = username;
    this.name = name;
    this.permissions = permissions;
  }
}

/**
 * Maps a permissions enum value to a string for the viewmodel.
 *
 * @param {Object} permissions the permissions enum value.
 *
 * @returns {String} the permissions of the user as a string.
 */
const _convertPermissions = (permissions) => {
  switch (permissions) {
    case _PermissionsPB.UNAUTH:
      return AccountPermissions.UNAUTH;
    case _PermissionsPB.WEBMASTER:
      return AccountPermissions.WEBMASTER;
    case _PermissionsPB.ADMIN:
      return AccountPermissions.ADMIN;
    case _PermissionsPB.REGULAR_STUDENT:
      return AccountPermissions.REGULAR_STUDENT;
    case _PermissionsPB.CLASS_SERVICE_REP:
      return AccountPermissions.CLASS_SERVICE_REP;
    case _PermissionsPB.SERVICE_CHAIR:
      return AccountPermissions.SERVICE_CHAIR;
    case _PermissionsPB.CO_CHAIR:
      return AccountPermissions.CO_CHAIR;
    case _PermissionsPB.ACTIVITIES_CHAIR:
      return AccountPermissions.ACTIVITIES_CHAIR;
    case _PermissionsPB.ACTIVITIES_REP:
      return AccountPermissions.ACTIVITIES_REP;
    case _PermissionsPB.INVALID:
    default:
      return AccountPermissions.INVALID;
  }
};

/**
 * Creates a new instance of the account view-model.
 *
 * @param {Object} kwargs
 * @param {String} kwargs.username username of the user.
 * @param {String} kwargs.name name of the user.
 * @param {String} kwargs.permissions permissions of the user.
 *
 * @returns {AccountViewModel} view-model for the account.
 */
const create = ({
  username = '',
  name = '',
  permissions = AccountPermissions.UNAUTH,
} = {}) => new AccountViewModel(username, name, permissions);

/**
 * Converts the user proto to the account view-model.
 *
 * @param {Object} account the account proto instance.
 *
 * @throws {Error} throws error if the account is not a valid user proto
 * @throws {Error} throws error if the user type is not included in the
 * user proto.
 *
 * @returns {AccountViewModel} view-model for the account.
 */
const convert = (account) => {
  const err = _UserPB.verify(account);
  if (err) {
    throw new Error(`[AccountViewModelFactory.convert] ${err}`);
  }

  if (Object.prototype.hasOwnProperty.call(account, 'studentUser')) {
    return new AccountViewModel(
      account?.studentUser?.username ?? '',
      account?.studentUser?.name ?? '',
      _convertPermissions(account.studentUser.permissions),
    );
  } if (Object.prototype.hasOwnProperty.call(account, 'adminUser')) {
    return new AccountViewModel(
      account?.adminUser?.username ?? '',
      account?.adminUser?.name ?? '',
      _convertPermissions(account.adminUser.permissions),
    );
  } if (Object.prototype.hasOwnProperty.call(account, 'unauthUser')) {
    return new AccountViewModel(
      /* username= */ '',
      /* name= */ '',
      AccountPermissions.UNAUTH,
    );
  }
  throw new Error(
    'student_user, admin_user or unauth_user must be set in user proto',
  );
};

/** Factory for creating {@link AccountViewModel}. */
export const accountViewModelFactory = {
  convert,
  create,
};
