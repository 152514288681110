import {
  Box,
  Typography,
} from '@mui/material';
import styled from '@mui/material/styles/styled';

export const SummaryContainerSx = styled(Box)``;

export const SummaryItemSx = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: ${({ direction }) => direction};
  gap: 30px;
  justify-content: space-between;
  margin: auto;
  overflow: hidden;
  padding: 50px 5%;

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 9px;
    flex-direction: column;
    padding: 20px 10%;
  }
`;

export const SummaryPaneSx = styled(Box)`
  width: 50%;

  ${({ theme }) => theme.breakpoints.down('md')} {
    text-align: center;
    width: 100%;
  }
`;

export const SummaryImageSx = styled('img')`
  height: auto;
  width: 100%;
`;

export const SummaryTitleSx = styled(Typography)`
  color: black;
  font-size: 28px;
  font-weight: bold;
  line-height: 1.1;
  margin-bottom: 8px;
  text-align: left;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    font-size: 22px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 16px;
  }
`;
SummaryTitleSx.defaultProps = {
  variant: 'h3',
};

export const SummaryTextSx = styled(Typography)`
  color: black;
  font-size: 20px;
  font-weight: normal;
  line-height: normal;
  text-align: left;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    font-size: 16px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 14px;
  }
`;
SummaryTextSx.defaultProps = {
  variant: 'body2',
};

export const SummaryDividerSx = styled(Box)`
  border-bottom: 2px solid rgb(200, 200, 200);
`;
