import {
  Box,
  Link,
  Typography,
} from '@mui/material';
import styled from '@mui/material/styles/styled';

export const ContainerSx = styled(Box)`
  align-items: center;
  background-color: white;
  display: flex;
  flex-direction: column;
`;

export const EmailSx = styled(Link)`
  color: black;
  text-decoration-color: black;
`;
EmailSx.defaultProps = {
  variant: 'body1',
};

export const ImageSx = styled('img')`
  margin-bottom: 15px;
  width: 200px;
`;

export const NameSx = styled(Typography)``;
NameSx.defaultProps = {
  variant: 'h5',
};

export const PositionSx = styled(Typography)`
  font-style: italic;
`;
PositionSx.defaultProps = {
  variant: 'body1',
};
