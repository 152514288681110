import apiAdapter from "api_adapter";
import { useAccount } from 'account';

import schema from 'shared/schema';

const _StatusPB = schema.lookup('core.status.Status');

const getProfExpNew = async () => {
    const response = await apiAdapter.getProfExpNew({
        useSession: 'true',
    });
    return response;
}

const getStudentListProfExp = async () => {
    const response = await apiAdapter.getStudentListProfExp({
        useSession: 'true',
    });
    return response;
}

const updateProfExp = async (approvalStatus, comment, experienceID) => {
    const response = await apiAdapter.updateProfessionalExpoerience({
        useSession: 'true',
        experienceApproval: {
            approvalStatus: approvalStatus,
            reviewerComment: comment,
            experienceID: experienceID,
        },
    });
    return response;
}

const getStudentProfExp = async (searchUsername) => {
    const response = await apiAdapter.readProfExps({
        readProfExpData: {
            useOtherStudent: true,
            studentName: searchUsername,
        }
    });
    return response;
}

const professionalExperienceApprovalPageController = {
    getProfExpNew,
    getStudentListProfExp,
    updateProfExp,
    getStudentProfExp,
}

export default professionalExperienceApprovalPageController;
