import apiAdapter from "api_adapter";
import { useAccout } from 'account';
import { UserProfileUpdatePWViewModelFactory } from "profile_page/view_model/user_profile_password_update.viewmodel";
import schema from 'shared/schema';
import { encryptCeasar } from "login_page/controller/helper/encryptPW";

const _StatusPB = schema.lookup('core.status.Stauts');

const sendNewPW = async ({ oldPW, newPW, newPWCheck }) => {
    if (!oldPW || oldPW === '' || !newPW || newPW === '' || !newPWCheck || newPWCheck === '') {
        return UserProfileUpdatePWViewModelFactory.create({
            isSuccessful: false,
            errorMessage: 'Error: Form must be completely filled.'
        });
    }

    if (newPW !== newPWCheck) {
        return UserProfileUpdatePWViewModelFactory.create({
            isSuccessful: false,
            errorMessage: 'Error: New passwords must match.'
        });
    }

    try {
        const response = await apiAdapter.changeOwnPW({
            pwData: {
                oldPW: await encryptCeasar(oldPW),
                newPW: await encryptCeasar(newPW),
            }
        });
        return response;
    } catch (error) {
        return {
            isSuccesful: false, errorMessage: 'What is this error?',
        };
    }
}

const changePasswordSelfController = {
    sendNewPW,
};

export default changePasswordSelfController;
