import React from 'react';
import {
  LockOutlined as LockOutlinedIcon,
} from '@mui/icons-material';
import {
  Avatar,
  Button,
  Box,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const ContainerSx = styled(Box)`
  align-items: center;
  color: black;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 500px;
  padding: 50px 0;

`;

const LoginAvatarIconSx = styled(LockOutlinedIcon)`
  height: 45px;
  width: 45px;
`;

export const LoginAvatarSx = styled(Avatar)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  height: 70px;
  width: 70px;
  margin-bottom: 5px;
`;
LoginAvatarSx.defaultProps = {
  children: (<LoginAvatarIconSx />),
};

export const LoginButtonDefaultSx = styled(Button)`
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 5px;
  width: 100%;
`;
LoginButtonDefaultSx.defaultProps = {
  type: 'submit',
  variant: 'contained',
};

export const LoginErrorTextSx = styled(Typography)`
  color: red;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-transform: none;
  min-height: 24px;
`;

export const LoginFormSx = styled(Box)`
  padding: 0px 10px;
  margin-bottom: 5px;
`;
LoginFormSx.defaultProps = {
  component: 'form',
  noValidate: true,
};

export const LoginHelpTextSx = styled(Typography)`
  margin-top: 35px;
  text-align: center;
`;

export const LoginTitleSx = styled(Typography)`
  font-weight: bold;
  padding-bottom: 20px;
`;
LoginTitleSx.defaultProps = {
  component: 'h1',
  variant: 'h4',
};

export const PasswordFieldSx = styled(TextField)``;
PasswordFieldSx.defaultProps = {
  fullWidth: true,
  margin: 'normal',
  required: true,
  type: 'password',
};

export const UsernameFieldSx = styled(TextField)``;
UsernameFieldSx.defaultProps = {
  margin: 'normal',
  required: true,
  fullWidth: true,
  autoFocus: true,
};
