import React, {
  ReactElement,
} from 'react';
import PageTemplate from 'page_template';
import HeaderCard from 'components/header_card';
import {
  SummaryContainerSx,
  SummaryDividerSx,
  SummaryImageSx,
  SummaryItemSx,
  SummaryPaneSx,
  SummaryTextSx,
  SummaryTitleSx,
} from 'home_page/styles/home_page.style';

/**
 * The home page of the website. It includes a brief description of the website
 * and the honors student government.
 *
 * @returns {ReactElement} the home page component.
 */
export default function HomePage() {
  return (
    <PageTemplate active="home">
      <HeaderCard bkgSrc="/images/headers/OurSpacePriceHall.jpg">
        <HeaderCard.Title>Clarkson Honors Program</HeaderCard.Title>
      </HeaderCard>
      <SummaryContainerSx>
        <SummaryItemSx direction="row">
          <SummaryPaneSx>
            <SummaryImageSx src="/images/home_page/CompletingResearch.jpg" />
          </SummaryPaneSx>
          <SummaryPaneSx>
            <SummaryTitleSx>
              The Clarkson Honors student website is run by the Steering
              Board of the Clarkson University Honors Program.
            </SummaryTitleSx>
            <SummaryTextSx>
              The website provides honors students with information and
              utilities concerning the student-run portion of the program.
            </SummaryTextSx>
            <SummaryTextSx>
              The information on this website includes information
              on the student committees, meeting minutes for the steering
              board, etc.
            </SummaryTextSx>
            <SummaryTextSx>
              The website also includes services such as a textbook exchange,
              a forum just for honors students and the ability to log the
              mandated community service hours for the honors program.
            </SummaryTextSx>
          </SummaryPaneSx>
        </SummaryItemSx>
        <SummaryDividerSx />
        <SummaryItemSx direction="row-reverse">
          <SummaryPaneSx>
            <SummaryImageSx src="/images/home_page/CelebrationOfAccomplishments.jpg" />
          </SummaryPaneSx>
          <SummaryPaneSx>
            <SummaryTitleSx>
              Honors Student Government
            </SummaryTitleSx>
            <SummaryTextSx>
              Honors Student Government is a wonderful opportunity for honors
              students to influence the administration and direction of the
              program. Represents are elected from each class to serve on the
              general Steering Board, and others are chosen to sit on the five
              subcommittees: Service, Honors Ambassadors, Activities,
              Diversity and Inclusion, and Webmasters. Moreover, five students
              are elected each year by their peers to serve on the Honors
              Council, a group of Clarkson administrators, faculty and
              students that oversees admission to the Honors Program and also
              approves thesis proposals.
            </SummaryTextSx>
            <SummaryTextSx>
              Contact information for each committee is listed on their pages.
              Questions and feedback on Honors Student Governance in general
              should be directed to the steering board.
            </SummaryTextSx>
          </SummaryPaneSx>
        </SummaryItemSx>
      </SummaryContainerSx>
    </PageTemplate>
  );
}
