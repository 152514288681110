import React, {
  ReactElement,
} from 'react';
import {
  Routes,
  Route,
} from 'react-router-dom';
import CommitteePage from 'about_page/committee_page';
import LandingPage from 'about_page/landing_page';
import ErrorPage from 'error_page';

/**
 * Component for about page. The about page consists of two sub-routes:
 * landing page and committee page.
 *
 * The committeeId param is used by the CommitteePage component to determine the
 * committee to be displayed.
 *
 * @returns {ReactElement} the about page component.
 */
export default function AboutPage() {
  return (
    <Routes>
      <Route exact path="/" element={<LandingPage />} />
      <Route exact path=":committeeId" element={<CommitteePage />} />
      <Route exact path="*" element={<ErrorPage />} />
    </Routes>
  );
}
