import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const TableProfExpPast = ({ tableBody }) => {
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
                <TableHead>
                    <TableRow>
                        <TableCell align='left'>Event Date</TableCell>
                        <TableCell align='left'>Name</TableCell>
                        <TableCell align='left'>Description</TableCell>
                        <TableCell align='left'>Reflection</TableCell>
                        <TableCell align='left'>Status</TableCell>
                        <TableCell align='left'>Reason</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableBody}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export const TableProfExpPastRow = ({ date, name, description, reflection, approvalStatus, reason }) => {

    let status = "";
    let statusColor = "";

    switch (approvalStatus) {
        case 1:
            status = "Approved";
            statusColor = "green";
            break;
        case 2:
            status = "Rejected";
            statusColor = "red";
            break;
        default:
            status = "Pending";
            statusColor = "darkorange";
            break;
    }

    return (
        <TableRow>
            <TableCell>{new Date(date * 1000).toLocaleDateString()}</TableCell>
            <TableCell>{name}</TableCell>
            <TableCell>{description}</TableCell>
            <TableCell>{reflection}</TableCell>
            <TableCell><p style={{ color: statusColor }}>{status}</p></TableCell>
            <TableCell>{reason}</TableCell>
        </TableRow>
    );
}