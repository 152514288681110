import {
  Card,
  CardActionArea,
  Typography,
} from '@mui/material';
import styled from '@mui/material/styles/styled';

export const CardActionAreaSx = styled(CardActionArea)`
  height: 200px;
  padding: 15px 15px;
  width: 200px;
`;

export const ContainerSx = styled(Card)`
  background-color: ${({ theme }) => theme.palette.primary.main};
`;

export const TextSx = styled(Typography)`
  font-weight: bold;
  text-align: center;
  font-size: 25px;
  color: white;
`;
