import React, {
    ReactElement,
    useEffect,
} from 'react';
import PageTemplate from 'page_template';
import HeaderCard from 'components/header_card';
import { useAccount } from 'account';
import { AccountPermissions } from 'account/view_model/account.viewmodel';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';
import * as PATHS from 'routing/paths';

import {
    Container,
    Inner,
    ColumnsSx,
} from './styles/resources_page.style';
import {
    Heading,
    Content,
    ResourcesContainer,
    ResourcesText,
    List,
    ListItem,
    RowList,
} from './styles/landing_page_resources.style';
import { Innerr } from 'textbooks_page/styles/textbooks_page.style';

// add navagation to login page if they are not already logged in

export default function ResourceLandingPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const { account, refreshAccount } = useAccount();

    useEffect(() => {
        if (account === null || [AccountPermissions.INVALID, AccountPermissions.UNAUTH].includes(account.permissions) === true) {
            navigate(PATHS.LOGIN, { state: { from: location.pathname }, replace: true });
        }

    }, []);

    return (
        <PageTemplate active="resources">
            <HeaderCard bkgSrc="/images/headers/HonorsPintNight.jpg">
                <HeaderCard.Title>Resources</HeaderCard.Title>
            </HeaderCard>
            <Container>
                <Innerr>
                    <h1>Upcoming Events</h1>
                    <iframe src="https://calendar.google.com/calendar/embed?src=c_243a1cc5681703b34f125c6eb1160406ba056577d89a173e0dc42e504d12abc9%40group.calendar.google.com&ctz=America%2FNew_York" width="800" height="600" frameborder="0" text-align="center" margin-left="auto" margin-right="auto" align-items="center" justify-content="center" display="block"></iframe>
                    <p>
                        <h1>Honors Mentorship Discord</h1>
                        <a href="https://discord.com/invite/B7NaSFgaek"> Mentorship link!</a>
                        <br></br>
                        Join the Honors Mentorship Discord with the link above! We would appreciate it if all members of the Honors Program could log on and check out this resource!
                    </p>
                </Innerr>

            </Container>
        </PageTemplate>
    );
}

{/*
    <iframe src="https://calendar.google.com/calendar/embed?src=c_243a1cc5681703b34f125c6eb1160406ba056577d89a173e0dc42e504d12abc9%40group.calendar.google.com&ctz=America%2FNew_York" style="border: 0" width="800" height="600" frameborder="0"></iframe>
DocumentsLandingPage.DocumentsButton = function DocumentsLandingPageTextbooksButton({ children, ...otherProps }) {
    return (
        <DocumentsContainer>
            <Button variant="contained" border="rounded" {...otherProps}>
                <DocumentsText to={PATHS.DOCUMENTS + '/need_to_put_pathing_here'} state={{ from: location.pathname }}>{children}</DocumentsText>
            </Button>
        </DocumentsContainer>
    );
};*/}