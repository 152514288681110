import apiAdapter from "api_adapter";
import { useAccout } from 'account';
import { submitProfExpViewModelFactory } from "professional_experiences/view_model/submit_prof_exp.viewmodel";
import schema from 'shared/schema';

const _StatusPB = schema.lookup('core.status.Stauts');

const sendProfExp = async ({ name, date, location, description, reflection }, photo) => {
    if (!name || name === '' || !eventName || eventName === '' || !date || date === 0 || !location || location === '' || !description || description === '' || !reflection || reflection === '') {
        return submitProfExpViewModelFactory.create({
            isSuccessful: false,
            errorMessage: 'Error: Form must be completely filled.'
        });
    }

    try {
        const imageUploadResponse = await apiAdapter.uploadProfExpPhoto({
            photo: photo
        });

        const response = await apiAdapter.writeProfExp({
            event: {
                name: name,
                location: location,
                description: description,
                reflection: reflection,
                date: {
                    unixTime: date,
                },
                imgPath: imageUploadResponse.data.fileID,
            }
        });
        return response;

    } catch (error) {
        return submitProfExpViewModelFactory.create({
            isSuccessful: false,
            errorMessage: error.message ? error.message : "Error sending data. Please contact a webmaster.",
        });
    }
};

const getProfExp = async (useSession, otherNameIfNot) => {
    try {
        if (useSession) {
            const response = await apiAdapter.readProfExps({
                readProfExpData: {
                    useOtherStudent: false,
                    studentName: null,
                },
            });
            return response;
        } else if (!useSession && otherNameIfNot !== null) {
            const response = apiAdapter.readProfExps({
                readProfExpData: {
                    useOtherStudent: true,
                    studentName: otherNameIfNot,
                },
            });
            return response;
        } else {
            return submitProfExpViewModelFactory.create({
                isSuccessful: false,
                errorMessage: 'Error: Must choose option.'
            });
        }
    } catch (error) {
        return submitProfExpViewModelFactory.create({
            isSuccessful: false,
            errorMessage: error.message ? error.message : "Error sending data. Please contact a webmaster.",
        });
    }

}

const submitProfExpPageController = {
    sendProfExp,
    getProfExp,
};

export default submitProfExpPageController;