import * as authAdapter from 'api_adapter/auth.adapter';
import * as documentsAdapter from 'api_adapter/documents.adapter';
import * as serviceHoursAdapter from 'api_adapter/service_hours.adapter';
import * as serviceApprovalAdapter from 'api_adapter/service_approval.adapter';
import * as professionalExperienceAdapter from 'api_adapter/prof_exp.adapter';
import * as professionalExperienceApprovalAdapter from 'api_adapter/prof_exp_approval.adapter';
import * as adminAdapter from 'api_adapter/admin.adapter';

/**
 * The Api Adapter acts a proxy between the client and the server. It translates
 * protobuf requests into HTTP requests and returns the resposne as the proper
 * protobuf response.
 */
const apiAdapter = {
  ...authAdapter,
  ...documentsAdapter,
  ...serviceHoursAdapter,
  ...serviceApprovalAdapter,
  ...professionalExperienceAdapter,
  ...professionalExperienceApprovalAdapter,
  ...adminAdapter,
};

export default apiAdapter;
