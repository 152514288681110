const schema = require ("./schema")

/**
 * Checks to see whether an object is an enum.
 * 
 * Since both packages and enums are objects we distinguish the two by checking 
 * if the first element if the first element is an object or function. If this
 * is the case then the object is likely a package. 
 * 
 * @param {Object} obj the object being verified.
 * 
 * @returns true if the object is an enum and false otherwise
 */
const _isProtobufEnum = (obj) => {
  if (!obj instanceof Object) {
    return false
  }

  const prop = obj[Object.keys(obj)[0]]
  // assume a package if enum has no entries
  if (prop === null || prop === undefined) { return false } 

  if (prop instanceof Object || prop instanceof Function) {
    return false
  }

  return true
}

/**
 * Looks up schema for a path specified.
 *  
 * @param {string} path specified `path`.
 * @returns {Function|null} Schema if path is valid, null otherwise.
 */
schema.lookup = function lookup(path) {
  let curr = schema
  for (const token of path.split('.')) {
    if (curr && curr.hasOwnProperty(token)) {
      curr = curr[token]
    } else {
      return null
    }
  }

  if (curr instanceof Function || _isProtobufEnum(curr)) {
    return curr
  } else {
    return null
  }
}


module.exports = schema