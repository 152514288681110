import CommitteeSummaryViewModel, {
  committeeSummaryViewModelFactory,
} from 'about_page/view_model/committee_summary.viewmodel';
import ViewModel from 'pattern/view_model';

/** @type {Set<String>} */
export const COMMITTEE_IDS =
  new Set([
    'activity',
    'service',
    'ambassador',
    'historian',
    'diversity',
    'webmaster',
  ]);

/**
 * ViewModel for the Committee .
 *
 * @property {String} id the id of the committee
 * @property {String} label the label of the committee
 * @property {Array<CommitteeSummaryViewModel>} summary the summary of the
 * committee.
 */
export default class SteeringCommitteeViewModel extends ViewModel {
  /**
 * @param {String} id the id of the committee
 * @param {String} label the label of the committee
 * @param {Array<CommitteeSummaryViewModel>} summary the summary of the
 * committee.
 */
  constructor(id, label, summary) {
    super();

    this.id = id;
    this.label = label;
    this.summary = summary;
  }
}

/**
 * Creates a new instance of the {@link SteeringCommitteeViewModel}.
 *
 * @param {Object} kwargs
 * @param {String} kwargs.id
 * @param {String} kwargs.label
 * @param {Array<CommitteeSummaryViewModel>} kwargs.summary
 *
 * @returns {SteeringCommitteeViewModel} the new instance.
 */
const create = ({
  id = '',
  label = '',
  summary = [],
} = {}) => new SteeringCommitteeViewModel(id, label, summary);

/**
 * Converts a POJO into a new instance of the {@link CommitteeSummaryViewModel}.
 *
 * @param {Object} model object model for summary.
 *
 * @returns {SteeringCommitteeViewModel} the new instance.
 */
const convert = (model) => {
  if (!COMMITTEE_IDS.has(model.id)) {
    throw new Error('the model.id is not valid.');
  }
  if (typeof model.label !== 'string') {
    throw new Error('the model.label is not valid');
  }

  return new SteeringCommitteeViewModel(
    model.id,
    model.label,
    model.summary.map((item) => committeeSummaryViewModelFactory.convert(item)),
  );
};

export const steeringCommitteeViewModelFactory = {
  create,
  convert,
};
