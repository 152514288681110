import {
  Box,
  List,
  ListItem,
  Snackbar,
  Typography,
} from '@mui/material';
import styled from '@mui/material/styles/styled';

// About Page container styles =================================================

export const ContainerSx = styled(Box)``;

export const InnerSx = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 50px 20%;
`;

// Heading styles ==============================================================

export const HeadingSx = styled(Typography)`
  color: black;
  font-weight: bold;
  margin: 35px 5px;
`;
HeadingSx.defaultProps = {
  variant: 'h3',
};

// Summary styles ==============================================================

export const SummaryTextSx = styled(Typography)`
  margin-top: 15px;
`;
SummaryTextSx.defaultProps = {
  variant: 'body1',
};

export const SummaryListSx = styled(List)``;

export const SummaryListItemSx = styled(ListItem)`
  list-style-type: circle;
`;

export const SummaryListItemTextSx = styled(Typography)``;
SummaryTextSx.defaultProps = {
  variant: 'body1',
};

// About Page group styles =====================================================

export const CommitteeGroupSx = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 80px;
  justify-content: space-around;
`;

export const MemberGroupSx = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 60px;
  justify-content: space-around;
`;

// About Page misc component styles ===========================================

export const SnackbarSx = styled(Snackbar)``;
