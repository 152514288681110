import React, {
    ReactElement,
    useEffect,
    useState,
    useRef,
} from 'react';
import { useAccount } from 'account';
import { AccountPermissions } from 'account/view_model/account.viewmodel';
import serviceApprovalPageController from './controller/service_approval.controller';
import PageTemplate from 'page_template';
import HeaderCard from 'components/header_card';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';
import * as PATHS from 'routing/paths';
import {
    TextField,
} from '@mui/material';
import {
    Heading,
    ContainerSx,
} from "../service_page/styles/landing_page.style";
import {
    ViewToggle,
    TableServiceHoursNewApproval,
    NewHoursRow
} from "./styles/landing_page.styles"
import {
    StudentRow,
    TableAllStudentOverview,
    FilterButton,
} from "./styles/student_overview.styles";

export default function LandingPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const { account, refreshAccount } = useAccount();

    const [hoursData, setHoursData] = useState([]);
    const [studentData, setStudentData] = useState([]);
    const [yearFilter, setYearFilter] = useState(null);
    const [lowerBound, setLowerBound] = useState(null);
    const [upperBound, setUpperBound] = useState(null);
    const [nameFilter, setNameFilter] = useState('');

    const [studentHoursData, setStudentHoursData] = useState([]);
    const [studentName, setStudentName] = useState('')

    const insertNewHours = async () => {
        const request = await serviceApprovalPageController.getServiceNew();
        const dataResponse = request.data.newHours;
        const listData = [];
        dataResponse.forEach(hour => {
            const newRow = <NewHoursRow serviceID={hour.id} name={hour.name} yearGrad={hour.graduation_year} unixSubmit={hour.submission_timestamp} unixService={hour.service_date} type={hour.type} location={hour.location} description={hour.description} minReq={hour.minutes_requested} minAppr={hour.minutes_approved} supName={hour.supervisor_name} supContact={hour.supervisor_contact} imgPath={hour.img_path} reviewerID={hour.reviewer_uid} reviewerComment={hour.reviewer_comment} reviewerName={hour.reviewer_name} isCoChair={account.permissions == AccountPermissions.CO_CHAIR}></NewHoursRow>;
            listData.push(newRow);
        });
        setHoursData(listData);
    }

    const insertUserList = async (addedFilterBool, yearFilterValue, lowerBound, upperBound, nameFilterValue) => {
        const request = await serviceApprovalPageController.getStudentList();
        const dataResponse = request.data.studentData;
        const listData = [];
        dataResponse.forEach(student => {
            if (addedFilterBool) {
                if ((yearFilterValue == null || yearFilterValue == student.graduation_year) && (lowerBound == null || (lowerBound <= (((student.total == 0 && student.required_hours == 0 ? 1 : student.total) / student.required_hours) * 100))) && (upperBound == null || (upperBound >= (((student.total == 0 && student.required_hours == 0 ? 1 : student.total) / student.required_hours) * 100))) && ((nameFilterValue.length < 1) || (student.name.toLowerCase().includes(nameFilterValue.toLowerCase())))) {
                    const newRow = <StudentRow studentID={student.uid} name={student.name} gradYear={student.graduation_year} hoursRequired={student.required_hours} hoursDone={student.total}></StudentRow>
                    listData.push(newRow);
                }
            } else {
                const newRow = <StudentRow studentID={student.uid} name={student.name} gradYear={student.graduation_year} hoursRequired={student.required_hours} hoursDone={student.total}></StudentRow >
                listData.push(newRow);
            }
        });
        setStudentData(listData);
        setTimeout(() => {
            enableButtonPush();
        }, 250);
    }

    const setupRefreshScroll = () => {
        setTimeout(() => {
            var scrollpos = localStorage.getItem('scrollpos');
            if (scrollpos) window.scrollTo(0, scrollpos);
            localStorage.setItem('scrollpos', 0);
        }, 500);
    }

    const filterResults = async (yearFilterValue, lowerValue, upperValue, nameValue) => {
        setStudentData([]);
        insertUserList(true, yearFilterValue, lowerValue, upperValue, nameValue);
    }

    useEffect(() => {
        if (!account || account === null || [AccountPermissions.INVALID, AccountPermissions.UNAUTH, AccountPermissions.REGULAR_STUDENT, AccountPermissions.ACTIVITIES_CHAIR, AccountPermissions.ACTIVITIES_REP].includes(account.permissions) === true) {
            navigate(PATHS.LOGIN, { state: { from: location.pathname }, replace: true });
        } else {
            setupRefreshScroll();
            insertNewHours();
            insertUserList(false, null, null, null);
        }
    }, []);

    const enableButtonPush = () => {
        const studentButtonList = document.getElementsByClassName("studentSubHours");
        Array.prototype.forEach.call(studentButtonList, function (btn) {
            const name = btn.id;
            btn.addEventListener("click", async () => {
                setStudentName(name);
                const request = await serviceApprovalPageController.getStudentHours(name);
                const dataResponse = request.data.studentHours;
                const listData = [];
                dataResponse.forEach(hour => {
                    const newRow = <NewHoursRow serviceID={hour.id} name={hour.name} yearGrad={hour.graduation_year} unixSubmit={hour.submission_timestamp} unixService={hour.service_date} type={hour.type} location={hour.location} description={hour.description} minReq={hour.minutes_requested} minAppr={hour.minutes_approved} supName={hour.supervisor_name} supContact={hour.supervisor_contact} imgPath={hour.img_path} reviewerID={hour.reviewer_uid} reviewerComment={hour.reviewer_comment} reviewerName={hour.reviewer_name} newHoursTable={false}></NewHoursRow>;
                    listData.push(newRow);
                });
                setStudentHoursData(listData);
                document.getElementById("studentHourIndividual").style.display = 'flex';
                document.getElementById("studentIndividualTab").style.display = '';
                document.getElementById("studentOverview").style.display = 'none';
                document.getElementById("newHoursList").style.display = 'none';
            });
        });
    }

    return (
        <PageTemplate active="">
            <HeaderCard bkgSrc="/images/headers/Excitement.JPG" >
                <HeaderCard.Title>Service Approval</HeaderCard.Title>
            </HeaderCard>
            <ContainerSx style={{ paddingBottom: '0rem' }}>
                <ViewToggle
                    idAllService="studentOverview"
                    idNewService="newHoursList"
                    idStudent="studentHourIndividual"
                />
            </ContainerSx>
            <ContainerSx id="studentOverview" style={{ display: 'none', paddingTop: '1rem' }}>
                <Heading style={{ textAlign: 'center' }}>Student Overview</Heading>

                <table>
                    <tr>
                        <td><TextField value={yearFilter} onKeyDown={(ev) => { if (ev.key === 'Enter') { ev.preventDefault(); filterResults(yearFilter, lowerBound, upperBound, nameFilter); } }} label="Year Filter" onChange={(e) => { setYearFilter((isNaN(parseInt(e.target.value))) ? null : parseInt(e.target.value)) }}>{yearFilter}</TextField></td>
                        <td><TextField value={lowerBound} onKeyDown={(ev) => { if (ev.key === 'Enter') { ev.preventDefault(); filterResults(yearFilter, lowerBound, upperBound, nameFilter); } }} label="Lower Bound (%)" onChange={(e) => { setLowerBound((isNaN(parseInt(e.target.value))) ? null : parseInt(e.target.value)) }}>{lowerBound}</TextField></td>
                        <td><TextField value={upperBound} onKeyDown={(ev) => { if (ev.key === 'Enter') { ev.preventDefault(); filterResults(yearFilter, lowerBound, upperBound, nameFilter); } }} label="Upper Bound (%)" onChange={(e) => { setUpperBound((isNaN(parseInt(e.target.value))) ? null : parseInt(e.target.value)) }}>{upperBound}</TextField></td>
                    </tr>
                </table>
                <hr />
                <TextField value={nameFilter} onKeyDown={(ev) => { if (ev.key === 'Enter') { ev.preventDefault(); filterResults(yearFilter, lowerBound, upperBound, nameFilter); } }} label="Name Filter" onChange={(e) => { setNameFilter((e.target.value)) }}>{nameFilter}</TextField>
                <FilterButton onClick={() => { filterResults(yearFilter, lowerBound, upperBound, nameFilter) }}>Filter Results</FilterButton>
                <hr />

                <TableAllStudentOverview tableBody={studentData}></TableAllStudentOverview>
            </ContainerSx>
            <ContainerSx id="newHoursList" style={{ paddingTop: '1rem' }}>
                <Heading style={{ textAlign: 'center' }}>New Service Hours</Heading>
                <TableServiceHoursNewApproval tableBody={hoursData}></TableServiceHoursNewApproval>
            </ContainerSx>
            <ContainerSx id="studentHourIndividual" style={{ display: 'none', paddingTop: '1rem' }}>
                <Heading style={{ textAlign: 'center' }}>{studentName}</Heading>
                <TableServiceHoursNewApproval tableBody={studentHoursData}></TableServiceHoursNewApproval>
            </ContainerSx>
        </PageTemplate>);
};
