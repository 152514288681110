import React, {
    ReactElement,
} from 'react';
import ButtonStyle from 'components/button/styles/button.style';

export default function Button({ className, children, variant, ...otherProps }) {
    return <ButtonStyle className={className}
        variant={!variant || variant === "link" ? "text" : variant}
        disableRipple={variant === "link"}
        {...otherProps}>{children}</ButtonStyle>
}

