import React, {
    useEffect,
    useState,
} from 'react';
import { useAccount } from 'account';
import { AccountPermissions } from 'account/view_model/account.viewmodel';
import PageTemplate from 'page_template';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';
import {
    Container,
    Innerr,
} from '../textbooks_page/styles/textbooks_page.style';
import * as PATHS from 'routing/paths';
import {
    Heading,
    Content,
} from '../textbooks_page/styles/landing_page_textbooks.style';
import {
    CurrentPassword,
    NewPassword,
    PasswordChange,
    PasswordChangeButton,
    PasswordChangeErrorText,
} from './styles/password_change.style';
import { UserProfileTable } from './styles/user_profile.style';
import apiAdapter from 'api_adapter';
import changePasswordSelfController from './controller/change_password.controller';

export default function ProfileHomePage() {
    const navigate = useNavigate();
    const location = useLocation();
    const { account, refreshAccount } = useAccount();

    const [currentpassword, setCurrentPassword] = useState('');
    const [newpassword, setNewPassword] = useState('');
    const [newpasswordverify, setNewPasswordVerify] = useState('');
    const [errorText, setErrorText] = useState('');
    const [userTable, setUserTable] = useState('');

    const onPasswordChange = (event) => {
        event.preventDefault();

        // Validation - sets a required level of input - might need to include certain features
        // that a password must have in order to change
        if (currentpassword.length < 1 || newpassword.length < 1 || newpasswordverify.length < 1) {
            setErrorText('Error: Please enter both your current password and new password.');
        }
        else if (newpassword != newpasswordverify) {
            setErrorText('Error: New password does not match in the last two fields.');
            // }
            // else if (currentpassword != account.password) {
            //     // if your current password entered is not your current password
            //     setErrorText('Error: The password entered does not match your current password.');
        } else {
            // Send password to be changed.
            // console.log("Got here");
            changePasswordSelfController.sendNewPW({ oldPW: currentpassword, newPW: newpassword, newPWCheck: newpasswordverify }).then(
                (response) => {
                    if (response.data.header.status === 1) {
                        setErrorText('Success!')
                        setCurrentPassword('');
                        setNewPassword('');
                        setNewPasswordVerify('');
                    } else {
                        setErrorText('Error: The password entered does not match your current password. If issue persists, contact a webmaster.');
                    }
                }
            );
        }
    };

    const getUserData = async () => {
        const userDataResponse = await apiAdapter.getUserProfile();
        const userData = (<UserProfileTable name={userDataResponse.name} gradYear={userDataResponse.graduation_year} requiredHours={userDataResponse.required_hours} requiredExp={userDataResponse.required_prof_exp} doneHours={userDataResponse.total_service} doneExp={userDataResponse.total_prof_exp}></UserProfileTable>);
        setUserTable(userData);
    }

    useEffect(() => {
        if (account === null || [AccountPermissions.INVALID, AccountPermissions.UNAUTH].includes(account.permissions) === true) {
            navigate(PATHS.LOGIN, { state: { from: location.pathname }, replace: true });
        } else {
            getUserData();
        }
    }, []);

    return (
        <PageTemplate active="profile">
            <Container>
                <Innerr>
                    <Heading style={{ textAlign: 'left' }}>Student Overview</Heading>
                    <Content>
                        {userTable}
                    </Content>
                    <Heading style={{ textAlign: 'left' }}>Change Password</Heading>
                    <PasswordChange onSubmit={onPasswordChange}>
                        <CurrentPassword
                            value={currentpassword}
                            onChange={(e) => setCurrentPassword(e.currentTarget.value)}
                            label="Current Password"
                            id="currentpassword"
                            name="current password"
                        />
                        <NewPassword
                            value={newpassword}
                            onChange={(e) => setNewPassword(e.currentTarget.value)}
                            label="New Password"
                            id="newpassword"
                            name="new password"
                        />
                        <NewPassword
                            value={newpasswordverify}
                            onChange={(e) => setNewPasswordVerify(e.currentTarget.value)}
                            label="New Password Verify "
                            id="newpasswordverify"
                            name="new password verify"
                        />
                        <PasswordChangeButton>Change Password</PasswordChangeButton>
                    </PasswordChange>
                    <PasswordChangeErrorText>{errorText}</PasswordChangeErrorText>
                </Innerr>
            </Container>
        </PageTemplate>
    );
}