import React, {
    ReactElement,
} from 'react';
import {
    Routes,
    Route,
} from 'react-router-dom';
import ReunionLandingPage from './landing_page.jsx';
import ReunionFAQLandingPage from './faq_page.jsx';
import ReunionScheduleLandingPage from './schedule_details_page.jsx';
import ReunionSpeakerLandingPage from './speakers_page.jsx';
import ErrorPage from 'error_page';


export default function ReunionPage() {
    return (
        <Routes>
            <Route exact path="/" element={<ReunionLandingPage />} />
            <Route exact path="*" element={<ErrorPage />} />
            <Route exact path="/schedule" element={<ReunionScheduleLandingPage />} />
            <Route exact path="/speakers" element={<ReunionSpeakerLandingPage />} />
            <Route exact path="/faq" element={<ReunionFAQLandingPage />} />
        </Routes>
    );
}