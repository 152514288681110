import React, {
    useState,
    useEffect,
} from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const { updateService, default: serviceApprovalPageController } = require('../controller/service_approval.controller');

export const TableAllStudentOverview = ({ tableBody }) => {
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
                <TableHead>
                    <TableRow>
                        <TableCell align='left'>Student Name</TableCell>
                        <TableCell align='left'>Total Service Hours</TableCell>
                        <TableCell align='left'>Graduation Date</TableCell>
                        <TableCell align='left'>Hours Remaining</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableBody}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

// Colors - Green. Light green, Yellow, Orange, Red
const colorCompletionArray = ['#6de36d', '#c2efc2', '#efefc3', '#efd9c3', '#efc3c3'];

export const StudentRow = ({ studentID, name, gradYear, hoursRequired, hoursDone }) => {
    const ratio = hoursDone / hoursRequired;
    let color;
    if (ratio <= 0.25) {
        color = colorCompletionArray[4];
    } else if (ratio <= 0.5) {
        color = colorCompletionArray[3];
    } else if (ratio <= 0.75) {
        color = colorCompletionArray[2];
    } else if (ratio < 1) {
        color = colorCompletionArray[1];
    } else {
        color = colorCompletionArray[0];
    }

    return (
        <TableRow style={{ backgroundColor: color }}>
            <TableCell><Button className="studentSubHours" id={name}>{name}</Button></TableCell>
            <TableCell>{parseFloat(hoursDone)}</TableCell>
            <TableCell>{gradYear}</TableCell>
            <TableCell>{(hoursDone - hoursRequired < 0) ? (hoursRequired - hoursDone) : 0}</TableCell>
        </TableRow>
    );
};

export const FilterButton = styled(Button)`
    font-size: 15 px;
    font-weight: bold;
    margin-top: 15px;
    margin-botton: 5px;
    width: 100%;
`;
FilterButton.defaultProps = {
    type: 'submit',
    variant: 'contained',
};
