import apiAdapter from "api_adapter";
import { useAccount } from 'account';

import schema from 'shared/schema';

const _StatusPB = schema.lookup('core.status.Status');

const getServiceNew = async () => {
    const response = await apiAdapter.readNewService({
        useSession: 'true',
    });
    return response;
};

const getStudentList = async () => {
    const response = await apiAdapter.getStudentList({
        useSession: 'true',
    });
    return response;
};

const updateService = async (minAppr, comment, typeNew, serviceHourID) => {
    const response = await apiAdapter.updateServiceHour({
        useSession: 'true',
        serviceHourApproval: {
            minutesApproved: minAppr,
            reviewerComment: comment,
            type: typeNew,
            serviceID: serviceHourID,
        }
    });
    return response;
};

const getStudentHours = async (searchUsername) => {
    const response = await apiAdapter.getStudentHours({
        useSession: 'true',
        searchName: searchUsername,
    });
    return response;
};

const serviceApprovalPageController = {
    updateService,
    getServiceNew,
    getStudentList,
    getStudentHours,
}

export default serviceApprovalPageController;