import { createTheme } from '@mui/material';

const WHITE = '#FFFFFF';
const CLARKSON_GREEN = '#004F42';
const CLARKSON_YELLOW = '#F3D200';

export default createTheme({
  breakpoints: {
    values: {
      xs: 365, // override default xs: 0 -> 350
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },

  palette: {
    primary: {
      main: CLARKSON_GREEN,
    },
    secondary: {
      main: CLARKSON_YELLOW,
    },
    navbar: {
      main: WHITE,
    },
  },

  typography: {
    h3: {
      fontSize: '41px',
    },
    h5: {
      fontSize: '20px',
    },
    body1: {
      fontSize: '16px',
    },
    body2: {
      fontSize: '14px',
    },
  },
});
