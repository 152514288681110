import React, {
    ReactElement,
  } from 'react';
  import {
    Routes,
    Route,
  } from 'react-router-dom';
  import ResourceLandingPage from './landing_page_resources.jsx';
  import ErrorPage from 'error_page';
  import {
    Container,
    Inner,
  } from './styles/resources_page.style.js';
  
  export default function ResourcesPage() {
    return (
      <Routes>
        <Route exact path="/" element={<ResourceLandingPage />} />
        <Route exact path="*" element={<ErrorPage />} />
      </Routes>
    );
  }
  