import apiAdapter from 'api_adapter';
import rsaEncrypt from '../../../rsa_encryption/rsa_encryption';

export const encryptCeasar = async (password) => {
    // Get key
    const keyPublicObj = await apiAdapter.getKey();

    const { key } = require('shared/key/key.config.json');
    const keyHex = lettersToHex(key);
    let baseLetters = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];
    let arrayLetters = ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''];
    let x = 0;
    for (let i = 0; i < keyHex.length; i += 1) {
        if (!(arrayLetters.includes(keyHex.charAt(i)))) {
            arrayLetters[x] = keyHex.charAt(i);
            x += 1;
        }
    }
    for (let i = 0; i < baseLetters.length; i += 1) {
        if (!(arrayLetters.includes(baseLetters[i]))) {
            arrayLetters[x] = baseLetters[i];
            x += 1;
        }
    }
    // Now have cipher table, encode
    let resultStr = '';
    const hexPassword = lettersToHex(password);
    for (let i = 0; i < hexPassword.length; i += 1) {
        const indexVal = baseLetters.indexOf(hexPassword.charAt(i));
        resultStr += arrayLetters[indexVal];
    }
    // return await rsaEncrypt(resultStr, keyPublicObj.data);
    return resultStr;
}

export const lettersToHex = (word) => {
    let resultStr = '';
    for (let i = 0; i < word.length; i += 1) {
        resultStr += Number(word.charCodeAt(i)).toString(16);
    }
    return resultStr;
}
