import ViewModel from "pattern/view_model";

export default class ServicePast extends ViewModel {
    /**
     * @param {Boolean} isSuccessful
     * @param {String} errorMessage
     * @param {Array} serviceHoursData
     */
    constructor(isSuccessful, errorMessage, serviceHoursdata) {
        super();
        this.isSuccessful = isSuccessful;
        this.errorMessage = errorMessage;
        this.serviceHoursData = serviceHoursdata;
    }
}

const create = ({
    isSuccessful = false,
    errorMessage = '',
    serviceHoursData = [],
} = {}) => new ServicePast(isSuccessful, errorMessage, serviceHoursData);

export const servicePastViewModelFactory = {
    create,
};