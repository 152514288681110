import { useAccount } from 'account';
import { encryptCeasar } from 'login_page/controller/helper/encryptPW';
import schema from 'shared/schema';
import apiAdapter from 'api_adapter';

const _StatusPB = schema.lookup('core.status.Status');

const changeUserPass = async (cngusradpass, cngusrusername, cngusrnewpassword) => {
    if (cngusradpass.length < 1 || cngusrusername.length < 1 || cngusrnewpassword.length < 1) {
        return {
            isSuccesful: false, errorMessage: 'Incorrect length of parameters.',
        };
    }
    try {
        const response = await apiAdapter.changeUserPass({
            newpassworddata: {
                adminpassword: await encryptCeasar(cngusradpass),
                username: cngusrusername,
                passwordhash: await encryptCeasar(cngusrnewpassword),
            }
        });
        return response;
    }
    catch (error) {
        return {
            isSuccesful: false, errorMessage: 'What is this error?',
        };
    }
};

const createUserAccount = async (cusradpass, cusrname, cusrusername, cusrsemleft, cusrgradyr) => {
    if (cusradpass.length < 1 || cusrname.length < 1 || cusrusername.length < 1 || cusrsemleft.length < 1 || cusrgradyr.length < 1) {
        return {
            isSuccesful: false, errorMessage: 'Incorrect length of paramters',
        };
    }
    try {
        const response = await apiAdapter.createUserAccount({
            createuserdata: {
                adminpassword: await encryptCeasar(cusradpass),
                name: cusrname,
                username: cusrusername,
                semleft: cusrsemleft,
                gradyr: cusrgradyr,
            }
        });
        return response;
    }
    catch (error) {
        return {
            isSuccesful: false, errorMessage: 'Best of luck.',
        };
    }
};

const disableUserAccount = async (disaccadpass, disaccusername) => {
    if (disaccadpass.length < 1 || disaccusername.length < 1) {
        return {
            isSuccesful: false, errorMessage: 'Incorrect length of parameters',
        };
    }
    try {
        const response = await apiAdapter.disableUserAccount({
            disableuserdata: {
                adminpassword: await encryptCeasar(disaccadpass),
                username: disaccusername,
            }
        });
        return response;
    }
    catch (error) {
        return {
            isSuccesful: false, errorMessage: 'Idk, no idea whats wrong',
        };
    }
};

const adminPageController = {
    changeUserPass,
    disableUserAccount,
    createUserAccount,
}

export default adminPageController;