import schema from 'shared/schema';
import AccountViewModel, {
  accountViewModelFactory,
} from 'account/view_model/account.viewmodel';
import apiAdapter from 'api_adapter';

const _StatusPB = schema.lookup('core.status.Status');

/**
 * Retrieves the account of the user.
 *
 * @throws {Error} throws error if the account could not be retrieved.
 *
 * @return {Promise<AccountViewModel>} the account if the user is found and
 * null, otherwise.
 */
const readAccount = async () => {
  try {
    const response = await apiAdapter.readUser();
    if (_StatusPB[response.header.status] === _StatusPB[_StatusPB.ERROR]) {
      throw new Error('Could not fetch account from server.');
    } else if (
      _StatusPB[response.header.status] ===
      _StatusPB[_StatusPB.DATA_NOT_FOUND]) {
      return accountViewModelFactory.create();
    }

    return accountViewModelFactory.convert(response.user);
  } catch (err) {
    return accountViewModelFactory.create();
  }
};

/** Controller for handling user account. */
const accountController = {
  readAccount,
};

export default accountController;
