import steeringBoardModel from 'about_page/model/steering_board.model';
import SteeringCommitteeViewModel, {
  steeringCommitteeViewModelFactory,
} from 'about_page/view_model/steering_committee.viewmodel';
import SteeringMemberViewModel, {
  steeringMemberViewModelFactory,
} from 'about_page/view_model/steering_member.viewmodel';

/**
 * Retrieves the list of steering members.
 *
 * @param {{ committee: String? }} args
 * @param args.committee the id of the committee to filter for. If
 * the id is null all members will be returned.
 *
 * @returns {Promise<Array<SteeringMemberViewModel>>} the list of steering
 * members.
 */
const readSteeringMembers = async ({
  committee = null,
} = {}) => steeringBoardModel.members
  .filter((item) => committee == null || item.committee === committee)
  .map((item) => steeringMemberViewModelFactory.convert(item));

/**
 * Retrieves the list of steering committees.
 *
 * @returns {Promise<Array<SteeringCommitteeViewModel>>} the list of steering
 * members.
 */
const readSteeringCommittees = async () => steeringBoardModel.committees
  .map((item) => steeringCommitteeViewModelFactory.convert(item));

/**
 * Retrieves the steering committee specified.
 *
 * @param {String} committeeId the id of the committee to filter for.
 *
 * @returns {Promise<SteeringCommitteeViewModel|undefined>} the steering
 * committee specified. `undefined` is returned if the committee does not exist.
 */
const readSteeringCommittee = async (
  committeeId,
) => {
  const committee =
    steeringBoardModel.committees.find((item) => item.id === committeeId);
  if (committee === undefined) {
    return undefined;
  }

  return steeringCommitteeViewModelFactory.convert(committee);
};

/** Controller for handling about page. */
const aboutPageController = {
  readSteeringCommittees,
  readSteeringCommittee,
  readSteeringMembers,
};

export default aboutPageController;
