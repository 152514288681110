import PropTypes from 'prop-types';
import React, {
  ReactElement,
} from 'react';
import {
  ContainerSx,
  InnerSx,
  TitleSx,
  DividerSx,
  SubTitleSx,
} from 'components/header_card/styles/header_card.style';

/**
 * The header card is displayed at the top of the page to represent the heading
 * of the page. It consists of the title and subtitle of the page with a
 * relevant background image.
 *
 * @param {ReactElement} children the content of the component.
 * @param {String} bkgSrc the path for the background image of the card.
 *
 * @returns {ReactElement} the header card component.
 */
export default function HeaderCard({ children, bkgSrc }) {
  return (
    <ContainerSx bkgsrc={bkgSrc}>
      <InnerSx>{children}</InnerSx>
    </ContainerSx>
  );
}

HeaderCard.propTypes = {
  children: PropTypes.node.isRequired,
  bkgSrc: PropTypes.string.isRequired,
};

/**
 * Displays the title of the card.
 *
 * @param {String} children the content of the component.
 *
 * @returns {ReactElement} the title component.
 */
HeaderCard.Title = function HeaderCardTitle({ children }) {
  return <TitleSx>{children}</TitleSx>;
};

HeaderCard.Title.propTypes = {
  children: PropTypes.string.isRequired,
};

/**
 * Displays the subtitle of the card.
 *
 * @param {String} children the content of the component.
 *
 * @returns {ReactElement} the subtitle component.
 */
HeaderCard.SubTitle = function HeaderCardSubTitle({ children }) {
  return <SubTitleSx>{children}</SubTitleSx>;
};

HeaderCard.SubTitle.propTypes = {
  children: PropTypes.string.isRequired,
};

HeaderCard.Divider = function HeaderCardDivider() {
  return <DividerSx />;
};
