import {
    Button,
    FormControl,
    TextField,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const GenericPassword = styled(TextField)`
    width: 50%;
`;
GenericPassword.defaultProps = {
    margin: 'normal',
    required: true,
    type: 'password',
};

export const GenericText = styled(TextField)`
    width: 50%;
`;
GenericText.defaultProps = {
    margin: 'normal',
    required: true,
    type: 'normal',
};

export const GenericNumber = styled(TextField)`
    width: 50%;
`;
GenericNumber.defaultProps = {
    margin: 'normal',
    required: true,
    type: 'number',
};

export const GenericSubmitButton = styled(Button)`
    font-size: 15 px;
    font-weight: bold;
    margin-top: 15px;
    margin-botton: 5px;
    width: 50%;
`;
GenericSubmitButton.defaultProps = {
    type: 'submit',
    variant: 'contained',
};

export const CurrentPassword = styled(TextField)`
    width: 50%;
`;
CurrentPassword.defaultProps = {
    margin: 'normal',
    required: true,
    type: 'password',
};

export const NewPassword = styled(TextField)`
    width: 50%;
`;
NewPassword.defaultProps = {
    margin: 'normal',
    required: true,
    type: 'password',
};

export const PasswordChange = styled(FormControl)`
    padding: 0px 10px;
    margin-bottom: 5px;
    width: calc(0.9 * 100vw);
    max-width: 1100px;
`;
PasswordChange.defaultProps = {
    component: 'form',
    noValidate: true,
    fullWidth: true,
};

export const PasswordChangeButton = styled(Button)`
    font-size: 15 px;
    font-weight: bold;
    margin-top: 15px;
    margin-botton: 5px;
    width: 50%;
`;
PasswordChangeButton.defaultProps = {
    type: 'submit',
    variant: 'contained',
};

export const PasswordChangeErrorText = styled(Typography)`
  color: red;
  font-size: 14px;
  font-weight: bold;
  text-transform: none;
  text-align: center;
  width: 50%;
  min-height: 24px;
  margin-top: 10px;
`;