import { useAccount } from 'account';
import { AccountPermissions } from 'account/view_model/account.viewmodel';
import loginPageController from 'login_page/controller/login_page.controller';
import {
  ContainerSx,
  LoginAvatarSx,
  LoginButtonDefaultSx,
  LoginErrorTextSx,
  LoginFormSx,
  LoginHelpTextSx,
  LoginTitleSx,
  PasswordFieldSx,
  UsernameFieldSx,
} from 'login_page/styles/login_page.style';
import PageTemplate from 'page_template';
import React, {
  useEffect,
  useState,
  useRef,
} from 'react';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';
import * as PATHS from 'routing/paths';

export default function LoginPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { account, refreshAccount } = useAccount();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorText, setErrorText] = useState('');
  const usernameRef = useRef(null);

  const onDefaultSubmitRequest = (event) => {
    event.preventDefault();

    if (username === '' || password === '') {
      setErrorText('Username and password must be entered');
    }

    loginPageController.login({ username, password })
      .then((response) => {
        if (response.isSuccessful) {
          refreshAccount();
        } else {
          setErrorText(response.errorMessage);
          setUsername('');
          setPassword('');
          if (usernameRef) usernameRef.current.focus();
        }
      });
  };

  useEffect(() => {
    if (account && account.permissions !== AccountPermissions.UNAUTH) {
      const { from } = location.state ?? { from: PATHS.HOME };
      navigate(from, { replace: true });
    }
  }, [account]);

  return (
    <PageTemplate active="login">
      <ContainerSx>
        <LoginAvatarSx />
        <LoginTitleSx>LOGIN</LoginTitleSx>
        <LoginFormSx onSubmit={onDefaultSubmitRequest}>
          <UsernameFieldSx
            inputRef={usernameRef}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            label="Username"
            id="username"
            name="username"
          />
          <PasswordFieldSx
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            label="Password"
            id="password"
            name="password"
          />
          <LoginButtonDefaultSx>Log In</LoginButtonDefaultSx>
        </LoginFormSx>
        <LoginErrorTextSx>{errorText}</LoginErrorTextSx>
        <LoginHelpTextSx>
          If you have any issues please contact the administrator at
          webmaster@clarksonhonors.org
        </LoginHelpTextSx>
      </ContainerSx>
    </PageTemplate>
  );
}
