/* eslint-disable react/no-array-index-key */
import aboutPageController from 'about_page/controller/about_page.controller';
import MemberCard from 'about_page/member_card';
import {
  ContainerSx,
  HeadingSx,
  InnerSx,
  MemberGroupSx,
  SummaryListSx,
  SummaryListItemSx,
  SummaryListItemTextSx,
  SummaryTextSx,
} from 'about_page/styles/about_page.style';
import {
  COMMITTEE_SUMMARY_TYPE,
} from 'about_page/view_model/committee_summary.viewmodel';
import HeaderCard from 'components/header_card';
import PageTemplate from 'page_template';
import React, {
  Fragment,
  useState,
  useEffect,
} from 'react';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';

export default function CommitteePage() {
  const { committeeId } = useParams();

  // steering committee states and actions
  const [steeringCommittee, setSteeringCommittee] = useState(null);
  useEffect(() => {
    aboutPageController
      .readSteeringCommittee(committeeId)
      .then((committee) => setSteeringCommittee(committee));
  }, []);

  // steering members states and actions
  const [committeeMembers, setCommitteeMembers] = useState([]);
  useEffect(() => {
    aboutPageController
      .readSteeringMembers({ committee: committeeId })
      .then((members) => setCommitteeMembers(members));
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    if (steeringCommittee === undefined) {
      navigate(
        '../',
        {
          replace: true,
          state: {
            errorMessage: `The committee '${committeeId}' does not exist.`,
          },
        },
      );
    }
  }, [steeringCommittee]);
  return (
    <PageTemplate active="about" isLoading={!steeringCommittee}>
      <HeaderCard bkgSrc="/images/headers/governance.jpg">
        <HeaderCard.Title>
          {steeringCommittee ? steeringCommittee.label : ''}
        </HeaderCard.Title>
      </HeaderCard>
      <ContainerSx>
        <InnerSx>
          {steeringCommittee &&
          steeringCommittee.summary.map((summaryItem, ix) => (
            <Fragment key={`summaryitem-${ix}`}>
              {summaryItem.type === COMMITTEE_SUMMARY_TYPE.PARAGRAPH && (
              <SummaryTextSx>
                {summaryItem.paragraphText}
              </SummaryTextSx>
              )}
              {summaryItem.type === COMMITTEE_SUMMARY_TYPE.LIST && (
              <SummaryListSx>
                {summaryItem.listText.map((listItem, jx) => (
                  <SummaryListItemSx key={`summarylistitem-${ix}-${jx}`}>
                    <SummaryListItemTextSx>
                      {`• ${listItem}`}
                    </SummaryListItemTextSx>
                  </SummaryListItemSx>
                ))}
              </SummaryListSx>
              )}
            </Fragment>
          ))}
          {committeeMembers && committeeMembers.length > 0 && (
            <>
              <HeadingSx>Members</HeadingSx>
              <MemberGroupSx>
                {committeeMembers.map((member) => (
                  <MemberCard key={member.id}>
                    <MemberCard.Image src={member.image} />
                    <MemberCard.Name>
                      {member.name}
                    </MemberCard.Name>
                    <MemberCard.Position>
                      {member.position}
                    </MemberCard.Position>
                    <MemberCard.Email href={`mailto:${member.email}`}>
                      {member.email}
                    </MemberCard.Email>
                  </MemberCard>
                ))}
              </MemberGroupSx>
            </>
          )}
        </InnerSx>
      </ContainerSx>
    </PageTemplate>
  );
}
