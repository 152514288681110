import {
  Box,
  CircularProgress,
} from '@mui/material';
import styled from '@mui/material/styles/styled';

export const LoadingScreenContainerSx = styled(Box)`
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
`;

export const CircularProgressSx = styled(CircularProgress)``;
