import axiosInstance from 'api_adapter/axios_instance';
import schema from 'shared/schema';

export const getStudentListProfExp = async (request) => {
    const response = await axiosInstance.get('/approve/studentListProfExp', {});
    return response;
};

export const getProfExpNew = async (request) => {
    const response = await axiosInstance.get('/approve/newProfessionalExperience', {});
    return response;
};

export const updateProfessionalExpoerience = async (request) => {
    const response = await axiosInstance.post('/approve/updateExperience', request);
    return response;
};

export const countBlankProfExp = async (request) => {
    const response = await axiosInstance.get('/approve/countNewProfExp', {});
    return response.data.countProfExp[0].count;
};