import apiAdapter from "api_adapter";
import { useAccount } from 'account';
import SubmitServiceViewModel, {
    submitServiceViewModelFactory,
} from "service_page/view_model/submit_service.viewmodel";
import ServicePast, {
    servicePastViewModelFactory,
} from "service_page/view_model/get_service.viewmodel";
import schema from 'shared/schema';

const _StatusPB = schema.lookup('core.status.Status');

const sendHours = async ({ type = 0, date = 0, hours = 0, description = '', supervisor = '', supContact = '', serviceLocation = '' }, photo) => {
    // Validate Data
    if (type === 0 || !type || !date || date === '' || !hours || hours === 0 || !description || description === '' || !supervisor || supervisor === '' || !supContact || supContact === '' || !serviceLocation || serviceLocation === '') {
        return submitServiceViewModelFactory.create({
            isSuccessful: false,
            errorMessage: 'Error: Form must be completely filled.',
        });
    }

    // Send info to server
    try {
        const hrsReq = Math.trunc(hours);
        const minReq = Math.round(60 * (hours - (Math.trunc(hours))));

        const imgUploadResp = await apiAdapter.uploadServicePhoto({
            photo: photo
        });

        const response = await apiAdapter.writeServiceHour({
            serviceHour: {
                description: description,
                type: type,
                location: serviceLocation,
                supervisor: {
                    name: supervisor,
                    contact: supContact
                },
                serviceDate: {
                    unixTime: date,
                },
                durationRequested: {
                    hours: hrsReq,
                    minutes: minReq
                },
                imgPath: imgUploadResp.data.fileID
            }
        });
        return response;
    } catch (error) {
        return submitServiceViewModelFactory.create({
            isSuccessful: false,
            errorMessage: error.message,
        });
    }
};

const getHours = async (username, orderBy) => {
    if (!username || username === '' || !orderBy || orderBy === '') {
        return servicePastViewModelFactory.create({
            isSuccessful: false,
            errorMessage: 'Error: No username present.',
        });
    }

    try {
        const response = await apiAdapter.readServiceHours(
            {
                useSession: true,
                reviewStatus: 0,
                pagination: {
                    pageNo: 0,
                    rowsPerPage: 50,
                    order: {
                        orderType: 2,
                        orderBy: 'serviceDate',
                    },
                },
            }
        );

        if (_StatusPB[response.header.status] === _StatusPB[_StatusPB.SUCCESS]) {
            return servicePastViewModelFactory.create({ isSuccessful: true, serviceHoursData: response.serviceHours });
        }
        return servicePastViewModelFactory.create({
            isSuccessful: false,
            errorMessage: "Response unsuccessful"
        });
    } catch (error) {
        return servicePastViewModelFactory.create({
            isSuccessful: false,
            errorMessage: "Response Errored"
        });
    }
}

const submitServicePageController = {
    sendHours,
    getHours,
};

export default submitServicePageController;