/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.core = (function () {

    /**
     * Namespace core.
     * @exports core
     * @namespace
     */
    var core = {};

    core.account = (function () {

        /**
         * Namespace account.
         * @memberof core
         * @namespace
         */
        var account = {};

        /**
         * Permissions enum.
         * @name core.account.Permissions
         * @enum {number}
         * @property {number} INVALID=0 INVALID value
         * @property {number} UNAUTH=1 UNAUTH value
         * @property {number} CLASS_SERVICE_REP=2 CLASS_SERVICE_REP value
         * @property {number} REGULAR_STUDENT=3 REGULAR_STUDENT value
         * @property {number} SERVICE_CHAIR=7 SERVICE_CHAIR value
         * @property {number} WEBMASTER=10 WEBMASTER value
         * @property {number} ADMIN=11 ADMIN value
         */
        account.Permissions = (function () {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INVALID"] = 0;
            values[valuesById[1] = "UNAUTH"] = 1;
            values[valuesById[2] = "CLASS_SERVICE_REP"] = 2;
            values[valuesById[3] = "REGULAR_STUDENT"] = 3;
            values[valuesById[4] = "ACTIVITIES_REP"] = 4;
            values[valuesById[5] = "ACTIVITIES_CHAIR"] = 5;
            values[valuesById[7] = "SERVICE_CHAIR"] = 7;
            values[valuesById[9] = "CO_CHAIR"] = 9;
            values[valuesById[10] = "WEBMASTER"] = 10;
            values[valuesById[11] = "ADMIN"] = 11;
            return values;
        })();

        /**
         * Status enum.
         * @name core.account.Status
         * @enum {number}
         * @property {number} INVALID=0 INVALID value
         * @property {number} INACTIVE=1 INACTIVE value
         * @property {number} ACTIVE=2 ACTIVE value
         * @property {number} GRADUATED=3 GRADUATED value
         */
        account.Status = (function () {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INVALID"] = 0;
            values[valuesById[1] = "INACTIVE"] = 1;
            values[valuesById[2] = "ACTIVE"] = 2;
            values[valuesById[3] = "GRADUATED"] = 3;
            return values;
        })();

        account.Session = (function () {

            /**
             * Properties of a Session.
             * @memberof core.account
             * @interface ISession
             * @property {string|null} [username] Session username
             * @property {core.account.Permissions|null} [permissions] Session permissions
             */

            /**
             * Constructs a new Session.
             * @memberof core.account
             * @classdesc Represents a Session.
             * @implements ISession
             * @constructor
             * @param {core.account.ISession=} [properties] Properties to set
             */
            function Session(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Session username.
             * @member {string} username
             * @memberof core.account.Session
             * @instance
             */
            Session.prototype.username = "";

            /**
             * Session permissions.
             * @member {core.account.Permissions} permissions
             * @memberof core.account.Session
             * @instance
             */
            Session.prototype.permissions = 0;

            /**
             * Creates a new Session instance using the specified properties.
             * @function create
             * @memberof core.account.Session
             * @static
             * @param {core.account.ISession=} [properties] Properties to set
             * @returns {core.account.Session} Session instance
             */
            Session.create = function create(properties) {
                return new Session(properties);
            };

            /**
             * Encodes the specified Session message. Does not implicitly {@link core.account.Session.verify|verify} messages.
             * @function encode
             * @memberof core.account.Session
             * @static
             * @param {core.account.ISession} message Session message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Session.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.username != null && Object.hasOwnProperty.call(message, "username"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.username);
                if (message.permissions != null && Object.hasOwnProperty.call(message, "permissions"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.permissions);
                return writer;
            };

            /**
             * Encodes the specified Session message, length delimited. Does not implicitly {@link core.account.Session.verify|verify} messages.
             * @function encodeDelimited
             * @memberof core.account.Session
             * @static
             * @param {core.account.ISession} message Session message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Session.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Session message from the specified reader or buffer.
             * @function decode
             * @memberof core.account.Session
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {core.account.Session} Session
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Session.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.core.account.Session();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                        case 1: {
                            message.username = reader.string();
                            break;
                        }
                        case 2: {
                            message.permissions = reader.int32();
                            break;
                        }
                        default:
                            reader.skipType(tag & 7);
                            break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Session message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof core.account.Session
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {core.account.Session} Session
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Session.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Session message.
             * @function verify
             * @memberof core.account.Session
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Session.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.username != null && message.hasOwnProperty("username"))
                    if (!$util.isString(message.username))
                        return "username: string expected";
                if (message.permissions != null && message.hasOwnProperty("permissions"))
                    switch (message.permissions) {
                        default:
                            return "permissions: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 7:
                        case 9:
                        case 10:
                        case 11:
                            break;
                    }
                return null;
            };

            /**
             * Creates a Session message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof core.account.Session
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {core.account.Session} Session
             */
            Session.fromObject = function fromObject(object) {
                if (object instanceof $root.core.account.Session)
                    return object;
                var message = new $root.core.account.Session();
                if (object.username != null)
                    message.username = String(object.username);
                switch (object.permissions) {
                    default:
                        if (typeof object.permissions === "number") {
                            message.permissions = object.permissions;
                            break;
                        }
                        break;
                    case "INVALID":
                    case 0:
                        message.permissions = 0;
                        break;
                    case "UNAUTH":
                    case 1:
                        message.permissions = 1;
                        break;
                    case "CLASS_SERVICE_REP":
                    case 2:
                        message.permissions = 2;
                        break;
                    case "REGULAR_STUDENT":
                    case 3:
                        message.permissions = 3;
                        break;
                    case "ACTIVITIES_REP":
                    case 4:
                        message.permissions = 4;
                        break;
                    case "ACTIVITIES_CHAIR":
                    case 5:
                        message.permissions = 5;
                        break;
                    case "SERVICE_CHAIR":
                    case 7:
                        message.permissions = 7;
                        break;
                    case "CO_CHAIR":
                    case 9:
                        message.permissions = 9;
                        break;
                    case "WEBMASTER":
                    case 10:
                        message.permissions = 10;
                        break;
                    case "ADMIN":
                    case 11:
                        message.permissions = 11;
                        break;
                }
                return message;
            };

            /**
             * Creates a plain object from a Session message. Also converts values to other types if specified.
             * @function toObject
             * @memberof core.account.Session
             * @static
             * @param {core.account.Session} message Session
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Session.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.username = "";
                    object.permissions = options.enums === String ? "INVALID" : 0;
                }
                if (message.username != null && message.hasOwnProperty("username"))
                    object.username = message.username;
                if (message.permissions != null && message.hasOwnProperty("permissions"))
                    object.permissions = options.enums === String ? $root.core.account.Permissions[message.permissions] === undefined ? message.permissions : $root.core.account.Permissions[message.permissions] : message.permissions;
                return object;
            };

            /**
             * Converts this Session to JSON.
             * @function toJSON
             * @memberof core.account.Session
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Session.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Session
             * @function getTypeUrl
             * @memberof core.account.Session
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Session.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/core.account.Session";
            };

            return Session;
        })();

        account.AdminUser = (function () {

            /**
             * Properties of an AdminUser.
             * @memberof core.account
             * @interface IAdminUser
             * @property {number|null} [id] AdminUser id
             * @property {string|null} [username] AdminUser username
             * @property {string|null} [name] AdminUser name
             * @property {core.account.Permissions|null} [permissions] AdminUser permissions
             * @property {core.time.IDateTime|null} [lastLogin] AdminUser lastLogin
             */

            /**
             * Constructs a new AdminUser.
             * @memberof core.account
             * @classdesc Represents an AdminUser.
             * @implements IAdminUser
             * @constructor
             * @param {core.account.IAdminUser=} [properties] Properties to set
             */
            function AdminUser(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AdminUser id.
             * @member {number} id
             * @memberof core.account.AdminUser
             * @instance
             */
            AdminUser.prototype.id = 0;

            /**
             * AdminUser username.
             * @member {string} username
             * @memberof core.account.AdminUser
             * @instance
             */
            AdminUser.prototype.username = "";

            /**
             * AdminUser name.
             * @member {string} name
             * @memberof core.account.AdminUser
             * @instance
             */
            AdminUser.prototype.name = "";

            /**
             * AdminUser permissions.
             * @member {core.account.Permissions} permissions
             * @memberof core.account.AdminUser
             * @instance
             */
            AdminUser.prototype.permissions = 0;

            /**
             * AdminUser lastLogin.
             * @member {core.time.IDateTime|null|undefined} lastLogin
             * @memberof core.account.AdminUser
             * @instance
             */
            AdminUser.prototype.lastLogin = null;

            /**
             * Creates a new AdminUser instance using the specified properties.
             * @function create
             * @memberof core.account.AdminUser
             * @static
             * @param {core.account.IAdminUser=} [properties] Properties to set
             * @returns {core.account.AdminUser} AdminUser instance
             */
            AdminUser.create = function create(properties) {
                return new AdminUser(properties);
            };

            /**
             * Encodes the specified AdminUser message. Does not implicitly {@link core.account.AdminUser.verify|verify} messages.
             * @function encode
             * @memberof core.account.AdminUser
             * @static
             * @param {core.account.IAdminUser} message AdminUser message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AdminUser.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
                if (message.username != null && Object.hasOwnProperty.call(message, "username"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.username);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                if (message.permissions != null && Object.hasOwnProperty.call(message, "permissions"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.permissions);
                if (message.lastLogin != null && Object.hasOwnProperty.call(message, "lastLogin"))
                    $root.core.time.DateTime.encode(message.lastLogin, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified AdminUser message, length delimited. Does not implicitly {@link core.account.AdminUser.verify|verify} messages.
             * @function encodeDelimited
             * @memberof core.account.AdminUser
             * @static
             * @param {core.account.IAdminUser} message AdminUser message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AdminUser.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AdminUser message from the specified reader or buffer.
             * @function decode
             * @memberof core.account.AdminUser
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {core.account.AdminUser} AdminUser
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AdminUser.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.core.account.AdminUser();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                        case 1: {
                            message.id = reader.int32();
                            break;
                        }
                        case 2: {
                            message.username = reader.string();
                            break;
                        }
                        case 3: {
                            message.name = reader.string();
                            break;
                        }
                        case 4: {
                            message.permissions = reader.int32();
                            break;
                        }
                        case 5: {
                            message.lastLogin = $root.core.time.DateTime.decode(reader, reader.uint32());
                            break;
                        }
                        default:
                            reader.skipType(tag & 7);
                            break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AdminUser message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof core.account.AdminUser
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {core.account.AdminUser} AdminUser
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AdminUser.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AdminUser message.
             * @function verify
             * @memberof core.account.AdminUser
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AdminUser.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                if (message.username != null && message.hasOwnProperty("username"))
                    if (!$util.isString(message.username))
                        return "username: string expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.permissions != null && message.hasOwnProperty("permissions"))
                    switch (message.permissions) {
                        default:
                            return "permissions: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 7:
                        case 9:
                        case 10:
                        case 11:
                            break;
                    }
                if (message.lastLogin != null && message.hasOwnProperty("lastLogin")) {
                    var error = $root.core.time.DateTime.verify(message.lastLogin);
                    if (error)
                        return "lastLogin." + error;
                }
                return null;
            };

            /**
             * Creates an AdminUser message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof core.account.AdminUser
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {core.account.AdminUser} AdminUser
             */
            AdminUser.fromObject = function fromObject(object) {
                if (object instanceof $root.core.account.AdminUser)
                    return object;
                var message = new $root.core.account.AdminUser();
                if (object.id != null)
                    message.id = object.id | 0;
                if (object.username != null)
                    message.username = String(object.username);
                if (object.name != null)
                    message.name = String(object.name);
                switch (object.permissions) {
                    default:
                        if (typeof object.permissions === "number") {
                            message.permissions = object.permissions;
                            break;
                        }
                        break;
                    case "INVALID":
                    case 0:
                        message.permissions = 0;
                        break;
                    case "UNAUTH":
                    case 1:
                        message.permissions = 1;
                        break;
                    case "CLASS_SERVICE_REP":
                    case 2:
                        message.permissions = 2;
                        break;
                    case "REGULAR_STUDENT":
                    case 3:
                        message.permissions = 3;
                        break;
                    case "ACTIVITIES_REP":
                    case 4:
                        message.permissions = 4;
                        break;
                    case "ACTIVITIES_CHAIR":
                    case 5:
                        message.permissions = 5;
                        break;
                    case "SERVICE_CHAIR":
                    case 7:
                        message.permissions = 7;
                        break;
                    case "CO_CHAIR":
                    case 9:
                        message.permissions = 9;
                        break;
                    case "WEBMASTER":
                    case 10:
                        message.permissions = 10;
                        break;
                    case "ADMIN":
                    case 11:
                        message.permissions = 11;
                        break;
                }
                if (object.lastLogin != null) {
                    if (typeof object.lastLogin !== "object")
                        throw TypeError(".core.account.AdminUser.lastLogin: object expected");
                    message.lastLogin = $root.core.time.DateTime.fromObject(object.lastLogin);
                }
                return message;
            };

            /**
             * Creates a plain object from an AdminUser message. Also converts values to other types if specified.
             * @function toObject
             * @memberof core.account.AdminUser
             * @static
             * @param {core.account.AdminUser} message AdminUser
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AdminUser.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.id = 0;
                    object.username = "";
                    object.name = "";
                    object.permissions = options.enums === String ? "INVALID" : 0;
                    object.lastLogin = null;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.username != null && message.hasOwnProperty("username"))
                    object.username = message.username;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.permissions != null && message.hasOwnProperty("permissions"))
                    object.permissions = options.enums === String ? $root.core.account.Permissions[message.permissions] === undefined ? message.permissions : $root.core.account.Permissions[message.permissions] : message.permissions;
                if (message.lastLogin != null && message.hasOwnProperty("lastLogin"))
                    object.lastLogin = $root.core.time.DateTime.toObject(message.lastLogin, options);
                return object;
            };

            /**
             * Converts this AdminUser to JSON.
             * @function toJSON
             * @memberof core.account.AdminUser
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AdminUser.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AdminUser
             * @function getTypeUrl
             * @memberof core.account.AdminUser
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AdminUser.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/core.account.AdminUser";
            };

            return AdminUser;
        })();

        account.StudentUser = (function () {

            /**
             * Properties of a StudentUser.
             * @memberof core.account
             * @interface IStudentUser
             * @property {number|null} [id] StudentUser id
             * @property {string|null} [username] StudentUser username
             * @property {string|null} [name] StudentUser name
             * @property {core.account.Permissions|null} [permissions] StudentUser permissions
             * @property {core.time.IDateTime|null} [lastLogin] StudentUser lastLogin
             * @property {number|null} [requiredHours] StudentUser requiredHours
             * @property {number|null} [graduationYear] StudentUser graduationYear
             */

            /**
             * Constructs a new StudentUser.
             * @memberof core.account
             * @classdesc Represents a StudentUser.
             * @implements IStudentUser
             * @constructor
             * @param {core.account.IStudentUser=} [properties] Properties to set
             */
            function StudentUser(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * StudentUser id.
             * @member {number} id
             * @memberof core.account.StudentUser
             * @instance
             */
            StudentUser.prototype.id = 0;

            /**
             * StudentUser username.
             * @member {string} username
             * @memberof core.account.StudentUser
             * @instance
             */
            StudentUser.prototype.username = "";

            /**
             * StudentUser name.
             * @member {string} name
             * @memberof core.account.StudentUser
             * @instance
             */
            StudentUser.prototype.name = "";

            /**
             * StudentUser permissions.
             * @member {core.account.Permissions} permissions
             * @memberof core.account.StudentUser
             * @instance
             */
            StudentUser.prototype.permissions = 0;

            /**
             * StudentUser lastLogin.
             * @member {core.time.IDateTime|null|undefined} lastLogin
             * @memberof core.account.StudentUser
             * @instance
             */
            StudentUser.prototype.lastLogin = null;

            /**
             * StudentUser requiredHours.
             * @member {number} requiredHours
             * @memberof core.account.StudentUser
             * @instance
             */
            StudentUser.prototype.requiredHours = 0;

            /**
             * StudentUser graduationYear.
             * @member {number} graduationYear
             * @memberof core.account.StudentUser
             * @instance
             */
            StudentUser.prototype.graduationYear = 0;

            /**
             * Creates a new StudentUser instance using the specified properties.
             * @function create
             * @memberof core.account.StudentUser
             * @static
             * @param {core.account.IStudentUser=} [properties] Properties to set
             * @returns {core.account.StudentUser} StudentUser instance
             */
            StudentUser.create = function create(properties) {
                return new StudentUser(properties);
            };

            /**
             * Encodes the specified StudentUser message. Does not implicitly {@link core.account.StudentUser.verify|verify} messages.
             * @function encode
             * @memberof core.account.StudentUser
             * @static
             * @param {core.account.IStudentUser} message StudentUser message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StudentUser.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
                if (message.username != null && Object.hasOwnProperty.call(message, "username"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.username);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                if (message.permissions != null && Object.hasOwnProperty.call(message, "permissions"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.permissions);
                if (message.lastLogin != null && Object.hasOwnProperty.call(message, "lastLogin"))
                    $root.core.time.DateTime.encode(message.lastLogin, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.requiredHours != null && Object.hasOwnProperty.call(message, "requiredHours"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.requiredHours);
                if (message.graduationYear != null && Object.hasOwnProperty.call(message, "graduationYear"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.graduationYear);
                return writer;
            };

            /**
             * Encodes the specified StudentUser message, length delimited. Does not implicitly {@link core.account.StudentUser.verify|verify} messages.
             * @function encodeDelimited
             * @memberof core.account.StudentUser
             * @static
             * @param {core.account.IStudentUser} message StudentUser message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StudentUser.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a StudentUser message from the specified reader or buffer.
             * @function decode
             * @memberof core.account.StudentUser
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {core.account.StudentUser} StudentUser
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StudentUser.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.core.account.StudentUser();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                        case 1: {
                            message.id = reader.int32();
                            break;
                        }
                        case 2: {
                            message.username = reader.string();
                            break;
                        }
                        case 3: {
                            message.name = reader.string();
                            break;
                        }
                        case 4: {
                            message.permissions = reader.int32();
                            break;
                        }
                        case 5: {
                            message.lastLogin = $root.core.time.DateTime.decode(reader, reader.uint32());
                            break;
                        }
                        case 6: {
                            message.requiredHours = reader.int32();
                            break;
                        }
                        case 7: {
                            message.graduationYear = reader.int32();
                            break;
                        }
                        default:
                            reader.skipType(tag & 7);
                            break;
                    }
                }
                return message;
            };

            /**
             * Decodes a StudentUser message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof core.account.StudentUser
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {core.account.StudentUser} StudentUser
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StudentUser.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a StudentUser message.
             * @function verify
             * @memberof core.account.StudentUser
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            StudentUser.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                if (message.username != null && message.hasOwnProperty("username"))
                    if (!$util.isString(message.username))
                        return "username: string expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.permissions != null && message.hasOwnProperty("permissions"))
                    switch (message.permissions) {
                        default:
                            return "permissions: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 7:
                        case 9:
                        case 10:
                        case 11:
                            break;
                    }
                if (message.lastLogin != null && message.hasOwnProperty("lastLogin")) {
                    var error = $root.core.time.DateTime.verify(message.lastLogin);
                    if (error)
                        return "lastLogin." + error;
                }
                if (message.requiredHours != null && message.hasOwnProperty("requiredHours"))
                    if (!$util.isInteger(message.requiredHours))
                        return "requiredHours: integer expected";
                if (message.graduationYear != null && message.hasOwnProperty("graduationYear"))
                    if (!$util.isInteger(message.graduationYear))
                        return "graduationYear: integer expected";
                return null;
            };

            /**
             * Creates a StudentUser message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof core.account.StudentUser
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {core.account.StudentUser} StudentUser
             */
            StudentUser.fromObject = function fromObject(object) {
                if (object instanceof $root.core.account.StudentUser)
                    return object;
                var message = new $root.core.account.StudentUser();
                if (object.id != null)
                    message.id = object.id | 0;
                if (object.username != null)
                    message.username = String(object.username);
                if (object.name != null)
                    message.name = String(object.name);
                switch (object.permissions) {
                    default:
                        if (typeof object.permissions === "number") {
                            message.permissions = object.permissions;
                            break;
                        }
                        break;
                    case "INVALID":
                    case 0:
                        message.permissions = 0;
                        break;
                    case "UNAUTH":
                    case 1:
                        message.permissions = 1;
                        break;
                    case "CLASS_SERVICE_REP":
                    case 2:
                        message.permissions = 2;
                        break;
                    case "REGULAR_STUDENT":
                    case 3:
                        message.permissions = 3;
                        break;
                    case "ACTIVITIES_REP":
                    case 4:
                        message.permissions = 4;
                        break;
                    case "ACTIVITIES_CHAIR":
                    case 5:
                        message.permissions = 5;
                        break;
                    case "SERVICE_CHAIR":
                    case 7:
                        message.permissions = 7;
                        break;
                    case "CO_CHAIR":
                    case 9:
                        message.permissions = 9;
                        break;
                    case "WEBMASTER":
                    case 10:
                        message.permissions = 10;
                        break;
                    case "ADMIN":
                    case 11:
                        message.permissions = 11;
                        break;
                }
                if (object.lastLogin != null) {
                    if (typeof object.lastLogin !== "object")
                        throw TypeError(".core.account.StudentUser.lastLogin: object expected");
                    message.lastLogin = $root.core.time.DateTime.fromObject(object.lastLogin);
                }
                if (object.requiredHours != null)
                    message.requiredHours = object.requiredHours | 0;
                if (object.graduationYear != null)
                    message.graduationYear = object.graduationYear | 0;
                return message;
            };

            /**
             * Creates a plain object from a StudentUser message. Also converts values to other types if specified.
             * @function toObject
             * @memberof core.account.StudentUser
             * @static
             * @param {core.account.StudentUser} message StudentUser
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StudentUser.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.id = 0;
                    object.username = "";
                    object.name = "";
                    object.permissions = options.enums === String ? "INVALID" : 0;
                    object.lastLogin = null;
                    object.requiredHours = 0;
                    object.graduationYear = 0;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.username != null && message.hasOwnProperty("username"))
                    object.username = message.username;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.permissions != null && message.hasOwnProperty("permissions"))
                    object.permissions = options.enums === String ? $root.core.account.Permissions[message.permissions] === undefined ? message.permissions : $root.core.account.Permissions[message.permissions] : message.permissions;
                if (message.lastLogin != null && message.hasOwnProperty("lastLogin"))
                    object.lastLogin = $root.core.time.DateTime.toObject(message.lastLogin, options);
                if (message.requiredHours != null && message.hasOwnProperty("requiredHours"))
                    object.requiredHours = message.requiredHours;
                if (message.graduationYear != null && message.hasOwnProperty("graduationYear"))
                    object.graduationYear = message.graduationYear;
                return object;
            };

            /**
             * Converts this StudentUser to JSON.
             * @function toJSON
             * @memberof core.account.StudentUser
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StudentUser.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for StudentUser
             * @function getTypeUrl
             * @memberof core.account.StudentUser
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StudentUser.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/core.account.StudentUser";
            };

            return StudentUser;
        })();

        account.UnauthUser = (function () {

            /**
             * Properties of an UnauthUser.
             * @memberof core.account
             * @interface IUnauthUser
             * @property {core.account.Permissions|null} [permissions] UnauthUser permissions
             */

            /**
             * Constructs a new UnauthUser.
             * @memberof core.account
             * @classdesc Represents an UnauthUser.
             * @implements IUnauthUser
             * @constructor
             * @param {core.account.IUnauthUser=} [properties] Properties to set
             */
            function UnauthUser(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UnauthUser permissions.
             * @member {core.account.Permissions} permissions
             * @memberof core.account.UnauthUser
             * @instance
             */
            UnauthUser.prototype.permissions = 0;

            /**
             * Creates a new UnauthUser instance using the specified properties.
             * @function create
             * @memberof core.account.UnauthUser
             * @static
             * @param {core.account.IUnauthUser=} [properties] Properties to set
             * @returns {core.account.UnauthUser} UnauthUser instance
             */
            UnauthUser.create = function create(properties) {
                return new UnauthUser(properties);
            };

            /**
             * Encodes the specified UnauthUser message. Does not implicitly {@link core.account.UnauthUser.verify|verify} messages.
             * @function encode
             * @memberof core.account.UnauthUser
             * @static
             * @param {core.account.IUnauthUser} message UnauthUser message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UnauthUser.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.permissions != null && Object.hasOwnProperty.call(message, "permissions"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.permissions);
                return writer;
            };

            /**
             * Encodes the specified UnauthUser message, length delimited. Does not implicitly {@link core.account.UnauthUser.verify|verify} messages.
             * @function encodeDelimited
             * @memberof core.account.UnauthUser
             * @static
             * @param {core.account.IUnauthUser} message UnauthUser message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UnauthUser.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an UnauthUser message from the specified reader or buffer.
             * @function decode
             * @memberof core.account.UnauthUser
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {core.account.UnauthUser} UnauthUser
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UnauthUser.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.core.account.UnauthUser();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                        case 1: {
                            message.permissions = reader.int32();
                            break;
                        }
                        default:
                            reader.skipType(tag & 7);
                            break;
                    }
                }
                return message;
            };

            /**
             * Decodes an UnauthUser message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof core.account.UnauthUser
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {core.account.UnauthUser} UnauthUser
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UnauthUser.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an UnauthUser message.
             * @function verify
             * @memberof core.account.UnauthUser
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UnauthUser.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.permissions != null && message.hasOwnProperty("permissions"))
                    switch (message.permissions) {
                        default:
                            return "permissions: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 7:
                        case 9:
                        case 10:
                        case 11:
                            break;
                    }
                return null;
            };

            /**
             * Creates an UnauthUser message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof core.account.UnauthUser
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {core.account.UnauthUser} UnauthUser
             */
            UnauthUser.fromObject = function fromObject(object) {
                if (object instanceof $root.core.account.UnauthUser)
                    return object;
                var message = new $root.core.account.UnauthUser();
                switch (object.permissions) {
                    default:
                        if (typeof object.permissions === "number") {
                            message.permissions = object.permissions;
                            break;
                        }
                        break;
                    case "INVALID":
                    case 0:
                        message.permissions = 0;
                        break;
                    case "UNAUTH":
                    case 1:
                        message.permissions = 1;
                        break;
                    case "CLASS_SERVICE_REP":
                    case 2:
                        message.permissions = 2;
                        break;
                    case "REGULAR_STUDENT":
                    case 3:
                        message.permissions = 3;
                        break;
                    case "ACTIVITIES_REP":
                    case 4:
                        message.permissions = 4;
                        break;
                    case "ACTIVITIES_REP":
                    case 5:
                        message.permissions = 5;
                        break;
                    case "SERVICE_CHAIR":
                    case 7:
                        message.permissions = 7;
                        break;
                    case "CO_CHAIR":
                    case 9:
                        message.permissions = 9;
                        break;
                    case "WEBMASTER":
                    case 10:
                        message.permissions = 10;
                        break;
                    case "ADMIN":
                    case 11:
                        message.permissions = 11;
                        break;
                }
                return message;
            };

            /**
             * Creates a plain object from an UnauthUser message. Also converts values to other types if specified.
             * @function toObject
             * @memberof core.account.UnauthUser
             * @static
             * @param {core.account.UnauthUser} message UnauthUser
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UnauthUser.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.permissions = options.enums === String ? "INVALID" : 0;
                if (message.permissions != null && message.hasOwnProperty("permissions"))
                    object.permissions = options.enums === String ? $root.core.account.Permissions[message.permissions] === undefined ? message.permissions : $root.core.account.Permissions[message.permissions] : message.permissions;
                return object;
            };

            /**
             * Converts this UnauthUser to JSON.
             * @function toJSON
             * @memberof core.account.UnauthUser
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UnauthUser.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UnauthUser
             * @function getTypeUrl
             * @memberof core.account.UnauthUser
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UnauthUser.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/core.account.UnauthUser";
            };

            return UnauthUser;
        })();

        account.User = (function () {

            /**
             * Properties of a User.
             * @memberof core.account
             * @interface IUser
             * @property {core.account.IStudentUser|null} [studentUser] User studentUser
             * @property {core.account.IAdminUser|null} [adminUser] User adminUser
             * @property {core.account.IUnauthUser|null} [unauthUser] User unauthUser
             */

            /**
             * Constructs a new User.
             * @memberof core.account
             * @classdesc Represents a User.
             * @implements IUser
             * @constructor
             * @param {core.account.IUser=} [properties] Properties to set
             */
            function User(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * User studentUser.
             * @member {core.account.IStudentUser|null|undefined} studentUser
             * @memberof core.account.User
             * @instance
             */
            User.prototype.studentUser = null;

            /**
             * User adminUser.
             * @member {core.account.IAdminUser|null|undefined} adminUser
             * @memberof core.account.User
             * @instance
             */
            User.prototype.adminUser = null;

            /**
             * User unauthUser.
             * @member {core.account.IUnauthUser|null|undefined} unauthUser
             * @memberof core.account.User
             * @instance
             */
            User.prototype.unauthUser = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * User userType.
             * @member {"studentUser"|"adminUser"|"unauthUser"|undefined} userType
             * @memberof core.account.User
             * @instance
             */
            Object.defineProperty(User.prototype, "userType", {
                get: $util.oneOfGetter($oneOfFields = ["studentUser", "adminUser", "unauthUser"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new User instance using the specified properties.
             * @function create
             * @memberof core.account.User
             * @static
             * @param {core.account.IUser=} [properties] Properties to set
             * @returns {core.account.User} User instance
             */
            User.create = function create(properties) {
                return new User(properties);
            };

            /**
             * Encodes the specified User message. Does not implicitly {@link core.account.User.verify|verify} messages.
             * @function encode
             * @memberof core.account.User
             * @static
             * @param {core.account.IUser} message User message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            User.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.studentUser != null && Object.hasOwnProperty.call(message, "studentUser"))
                    $root.core.account.StudentUser.encode(message.studentUser, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.adminUser != null && Object.hasOwnProperty.call(message, "adminUser"))
                    $root.core.account.AdminUser.encode(message.adminUser, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.unauthUser != null && Object.hasOwnProperty.call(message, "unauthUser"))
                    $root.core.account.UnauthUser.encode(message.unauthUser, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified User message, length delimited. Does not implicitly {@link core.account.User.verify|verify} messages.
             * @function encodeDelimited
             * @memberof core.account.User
             * @static
             * @param {core.account.IUser} message User message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            User.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a User message from the specified reader or buffer.
             * @function decode
             * @memberof core.account.User
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {core.account.User} User
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            User.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.core.account.User();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                        case 1: {
                            message.studentUser = $root.core.account.StudentUser.decode(reader, reader.uint32());
                            break;
                        }
                        case 2: {
                            message.adminUser = $root.core.account.AdminUser.decode(reader, reader.uint32());
                            break;
                        }
                        case 3: {
                            message.unauthUser = $root.core.account.UnauthUser.decode(reader, reader.uint32());
                            break;
                        }
                        default:
                            reader.skipType(tag & 7);
                            break;
                    }
                }
                return message;
            };

            /**
             * Decodes a User message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof core.account.User
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {core.account.User} User
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            User.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a User message.
             * @function verify
             * @memberof core.account.User
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            User.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.studentUser != null && message.hasOwnProperty("studentUser")) {
                    properties.userType = 1;
                    {
                        var error = $root.core.account.StudentUser.verify(message.studentUser);
                        if (error)
                            return "studentUser." + error;
                    }
                }
                if (message.adminUser != null && message.hasOwnProperty("adminUser")) {
                    if (properties.userType === 1)
                        return "userType: multiple values";
                    properties.userType = 1;
                    {
                        var error = $root.core.account.AdminUser.verify(message.adminUser);
                        if (error)
                            return "adminUser." + error;
                    }
                }
                if (message.unauthUser != null && message.hasOwnProperty("unauthUser")) {
                    if (properties.userType === 1)
                        return "userType: multiple values";
                    properties.userType = 1;
                    {
                        var error = $root.core.account.UnauthUser.verify(message.unauthUser);
                        if (error)
                            return "unauthUser." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a User message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof core.account.User
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {core.account.User} User
             */
            User.fromObject = function fromObject(object) {
                if (object instanceof $root.core.account.User)
                    return object;
                var message = new $root.core.account.User();
                if (object.studentUser != null) {
                    if (typeof object.studentUser !== "object")
                        throw TypeError(".core.account.User.studentUser: object expected");
                    message.studentUser = $root.core.account.StudentUser.fromObject(object.studentUser);
                }
                if (object.adminUser != null) {
                    if (typeof object.adminUser !== "object")
                        throw TypeError(".core.account.User.adminUser: object expected");
                    message.adminUser = $root.core.account.AdminUser.fromObject(object.adminUser);
                }
                if (object.unauthUser != null) {
                    if (typeof object.unauthUser !== "object")
                        throw TypeError(".core.account.User.unauthUser: object expected");
                    message.unauthUser = $root.core.account.UnauthUser.fromObject(object.unauthUser);
                }
                return message;
            };

            /**
             * Creates a plain object from a User message. Also converts values to other types if specified.
             * @function toObject
             * @memberof core.account.User
             * @static
             * @param {core.account.User} message User
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            User.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.studentUser != null && message.hasOwnProperty("studentUser")) {
                    object.studentUser = $root.core.account.StudentUser.toObject(message.studentUser, options);
                    if (options.oneofs)
                        object.userType = "studentUser";
                }
                if (message.adminUser != null && message.hasOwnProperty("adminUser")) {
                    object.adminUser = $root.core.account.AdminUser.toObject(message.adminUser, options);
                    if (options.oneofs)
                        object.userType = "adminUser";
                }
                if (message.unauthUser != null && message.hasOwnProperty("unauthUser")) {
                    object.unauthUser = $root.core.account.UnauthUser.toObject(message.unauthUser, options);
                    if (options.oneofs)
                        object.userType = "unauthUser";
                }
                return object;
            };

            /**
             * Converts this User to JSON.
             * @function toJSON
             * @memberof core.account.User
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            User.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for User
             * @function getTypeUrl
             * @memberof core.account.User
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            User.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/core.account.User";
            };

            return User;
        })();

        return account;
    })();

    core.time = (function () {

        /**
         * Namespace time.
         * @memberof core
         * @namespace
         */
        var time = {};

        time.DateTime = (function () {

            /**
             * Properties of a DateTime.
             * @memberof core.time
             * @interface IDateTime
             * @property {number|null} [unixTime] DateTime unixTime
             */

            /**
             * Constructs a new DateTime.
             * @memberof core.time
             * @classdesc Represents a DateTime.
             * @implements IDateTime
             * @constructor
             * @param {core.time.IDateTime=} [properties] Properties to set
             */
            function DateTime(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DateTime unixTime.
             * @member {number} unixTime
             * @memberof core.time.DateTime
             * @instance
             */
            DateTime.prototype.unixTime = 0;

            /**
             * Creates a new DateTime instance using the specified properties.
             * @function create
             * @memberof core.time.DateTime
             * @static
             * @param {core.time.IDateTime=} [properties] Properties to set
             * @returns {core.time.DateTime} DateTime instance
             */
            DateTime.create = function create(properties) {
                return new DateTime(properties);
            };

            /**
             * Encodes the specified DateTime message. Does not implicitly {@link core.time.DateTime.verify|verify} messages.
             * @function encode
             * @memberof core.time.DateTime
             * @static
             * @param {core.time.IDateTime} message DateTime message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DateTime.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.unixTime != null && Object.hasOwnProperty.call(message, "unixTime"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.unixTime);
                return writer;
            };

            /**
             * Encodes the specified DateTime message, length delimited. Does not implicitly {@link core.time.DateTime.verify|verify} messages.
             * @function encodeDelimited
             * @memberof core.time.DateTime
             * @static
             * @param {core.time.IDateTime} message DateTime message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DateTime.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DateTime message from the specified reader or buffer.
             * @function decode
             * @memberof core.time.DateTime
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {core.time.DateTime} DateTime
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DateTime.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.core.time.DateTime();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                        case 1: {
                            message.unixTime = reader.int32();
                            break;
                        }
                        default:
                            reader.skipType(tag & 7);
                            break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DateTime message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof core.time.DateTime
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {core.time.DateTime} DateTime
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DateTime.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DateTime message.
             * @function verify
             * @memberof core.time.DateTime
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DateTime.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.unixTime != null && message.hasOwnProperty("unixTime"))
                    if (!$util.isInteger(message.unixTime))
                        return "unixTime: integer expected";
                return null;
            };

            /**
             * Creates a DateTime message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof core.time.DateTime
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {core.time.DateTime} DateTime
             */
            DateTime.fromObject = function fromObject(object) {
                if (object instanceof $root.core.time.DateTime)
                    return object;
                var message = new $root.core.time.DateTime();
                // if (object.unixTime != null)
                //     message.unixTime = object.unixTime | 0;
                message.unixTime = object.unixTime ?? 0;
                return message;
            };

            /**
             * Creates a plain object from a DateTime message. Also converts values to other types if specified.
             * @function toObject
             * @memberof core.time.DateTime
             * @static
             * @param {core.time.DateTime} message DateTime
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DateTime.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.unixTime = 0;
                if (message.unixTime != null && message.hasOwnProperty("unixTime"))
                    object.unixTime = message.unixTime;
                return object;
            };

            /**
             * Converts this DateTime to JSON.
             * @function toJSON
             * @memberof core.time.DateTime
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DateTime.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DateTime
             * @function getTypeUrl
             * @memberof core.time.DateTime
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DateTime.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/core.time.DateTime";
            };

            return DateTime;
        })();

        time.Duration = (function () {

            /**
             * Properties of a Duration.
             * @memberof core.time
             * @interface IDuration
             * @property {number|null} [hours] Duration hours
             * @property {number|null} [minutes] Duration minutes
             */

            /**
             * Constructs a new Duration.
             * @memberof core.time
             * @classdesc Represents a Duration.
             * @implements IDuration
             * @constructor
             * @param {core.time.IDuration=} [properties] Properties to set
             */
            function Duration(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Duration hours.
             * @member {number} hours
             * @memberof core.time.Duration
             * @instance
             */
            Duration.prototype.hours = 0;

            /**
             * Duration minutes.
             * @member {number} minutes
             * @memberof core.time.Duration
             * @instance
             */
            Duration.prototype.minutes = 0;

            /**
             * Creates a new Duration instance using the specified properties.
             * @function create
             * @memberof core.time.Duration
             * @static
             * @param {core.time.IDuration=} [properties] Properties to set
             * @returns {core.time.Duration} Duration instance
             */
            Duration.create = function create(properties) {
                return new Duration(properties);
            };

            /**
             * Encodes the specified Duration message. Does not implicitly {@link core.time.Duration.verify|verify} messages.
             * @function encode
             * @memberof core.time.Duration
             * @static
             * @param {core.time.IDuration} message Duration message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Duration.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.hours != null && Object.hasOwnProperty.call(message, "hours"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.hours);
                if (message.minutes != null && Object.hasOwnProperty.call(message, "minutes"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.minutes);
                return writer;
            };

            /**
             * Encodes the specified Duration message, length delimited. Does not implicitly {@link core.time.Duration.verify|verify} messages.
             * @function encodeDelimited
             * @memberof core.time.Duration
             * @static
             * @param {core.time.IDuration} message Duration message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Duration.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Duration message from the specified reader or buffer.
             * @function decode
             * @memberof core.time.Duration
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {core.time.Duration} Duration
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Duration.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.core.time.Duration();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                        case 1: {
                            message.hours = reader.int32();
                            break;
                        }
                        case 2: {
                            message.minutes = reader.int32();
                            break;
                        }
                        default:
                            reader.skipType(tag & 7);
                            break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Duration message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof core.time.Duration
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {core.time.Duration} Duration
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Duration.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Duration message.
             * @function verify
             * @memberof core.time.Duration
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Duration.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.hours != null && message.hasOwnProperty("hours"))
                    if (!$util.isInteger(message.hours))
                        return "hours: integer expected";
                if (message.minutes != null && message.hasOwnProperty("minutes"))
                    if (!$util.isInteger(message.minutes))
                        return "minutes: integer expected";
                return null;
            };

            /**
             * Creates a Duration message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof core.time.Duration
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {core.time.Duration} Duration
             */
            Duration.fromObject = function fromObject(object) {
                if (object instanceof $root.core.time.Duration)
                    return object;
                var message = new $root.core.time.Duration();
                if (object.hours != null)
                    message.hours = object.hours | 0;
                if (object.minutes != null)
                    message.minutes = object.minutes | 0;
                return message;
            };

            /**
             * Creates a plain object from a Duration message. Also converts values to other types if specified.
             * @function toObject
             * @memberof core.time.Duration
             * @static
             * @param {core.time.Duration} message Duration
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Duration.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.hours = 0;
                    object.minutes = 0;
                }
                if (message.hours != null && message.hasOwnProperty("hours"))
                    object.hours = message.hours;
                if (message.minutes != null && message.hasOwnProperty("minutes"))
                    object.minutes = message.minutes;
                return object;
            };

            /**
             * Converts this Duration to JSON.
             * @function toJSON
             * @memberof core.time.Duration
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Duration.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Duration
             * @function getTypeUrl
             * @memberof core.time.Duration
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Duration.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/core.time.Duration";
            };

            return Duration;
        })();

        return time;
    })();

    core.header = (function () {

        /**
         * Namespace header.
         * @memberof core
         * @namespace
         */
        var header = {};

        header.RequestHeader = (function () {

            /**
             * Properties of a RequestHeader.
             * @memberof core.header
             * @interface IRequestHeader
             * @property {core.account.ISession|null} [session] RequestHeader session
             */

            /**
             * Constructs a new RequestHeader.
             * @memberof core.header
             * @classdesc Represents a RequestHeader.
             * @implements IRequestHeader
             * @constructor
             * @param {core.header.IRequestHeader=} [properties] Properties to set
             */
            function RequestHeader(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * RequestHeader session.
             * @member {core.account.ISession|null|undefined} session
             * @memberof core.header.RequestHeader
             * @instance
             */
            RequestHeader.prototype.session = null;

            /**
             * Creates a new RequestHeader instance using the specified properties.
             * @function create
             * @memberof core.header.RequestHeader
             * @static
             * @param {core.header.IRequestHeader=} [properties] Properties to set
             * @returns {core.header.RequestHeader} RequestHeader instance
             */
            RequestHeader.create = function create(properties) {
                return new RequestHeader(properties);
            };

            /**
             * Encodes the specified RequestHeader message. Does not implicitly {@link core.header.RequestHeader.verify|verify} messages.
             * @function encode
             * @memberof core.header.RequestHeader
             * @static
             * @param {core.header.IRequestHeader} message RequestHeader message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RequestHeader.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.session != null && Object.hasOwnProperty.call(message, "session"))
                    $root.core.account.Session.encode(message.session, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified RequestHeader message, length delimited. Does not implicitly {@link core.header.RequestHeader.verify|verify} messages.
             * @function encodeDelimited
             * @memberof core.header.RequestHeader
             * @static
             * @param {core.header.IRequestHeader} message RequestHeader message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RequestHeader.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a RequestHeader message from the specified reader or buffer.
             * @function decode
             * @memberof core.header.RequestHeader
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {core.header.RequestHeader} RequestHeader
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RequestHeader.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.core.header.RequestHeader();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                        case 1: {
                            message.session = $root.core.account.Session.decode(reader, reader.uint32());
                            break;
                        }
                        default:
                            reader.skipType(tag & 7);
                            break;
                    }
                }
                return message;
            };

            /**
             * Decodes a RequestHeader message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof core.header.RequestHeader
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {core.header.RequestHeader} RequestHeader
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RequestHeader.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a RequestHeader message.
             * @function verify
             * @memberof core.header.RequestHeader
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RequestHeader.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.session != null && message.hasOwnProperty("session")) {
                    var error = $root.core.account.Session.verify(message.session);
                    if (error)
                        return "session." + error;
                }
                return null;
            };

            /**
             * Creates a RequestHeader message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof core.header.RequestHeader
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {core.header.RequestHeader} RequestHeader
             */
            RequestHeader.fromObject = function fromObject(object) {
                if (object instanceof $root.core.header.RequestHeader)
                    return object;
                var message = new $root.core.header.RequestHeader();
                if (object.session != null) {
                    if (typeof object.session !== "object")
                        throw TypeError(".core.header.RequestHeader.session: object expected");
                    message.session = $root.core.account.Session.fromObject(object.session);
                }
                return message;
            };

            /**
             * Creates a plain object from a RequestHeader message. Also converts values to other types if specified.
             * @function toObject
             * @memberof core.header.RequestHeader
             * @static
             * @param {core.header.RequestHeader} message RequestHeader
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RequestHeader.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.session = null;
                if (message.session != null && message.hasOwnProperty("session"))
                    object.session = $root.core.account.Session.toObject(message.session, options);
                return object;
            };

            /**
             * Converts this RequestHeader to JSON.
             * @function toJSON
             * @memberof core.header.RequestHeader
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RequestHeader.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for RequestHeader
             * @function getTypeUrl
             * @memberof core.header.RequestHeader
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RequestHeader.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/core.header.RequestHeader";
            };

            return RequestHeader;
        })();

        header.ResponseHeader = (function () {

            /**
             * Properties of a ResponseHeader.
             * @memberof core.header
             * @interface IResponseHeader
             * @property {core.account.Status|null} [status] ResponseHeader status
             * @property {string|null} [message] ResponseHeader message
             */

            /**
             * Constructs a new ResponseHeader.
             * @memberof core.header
             * @classdesc Represents a ResponseHeader.
             * @implements IResponseHeader
             * @constructor
             * @param {core.header.IResponseHeader=} [properties] Properties to set
             */
            function ResponseHeader(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ResponseHeader status.
             * @member {core.account.Status} status
             * @memberof core.header.ResponseHeader
             * @instance
             */
            ResponseHeader.prototype.status = 0;

            /**
             * ResponseHeader message.
             * @member {string} message
             * @memberof core.header.ResponseHeader
             * @instance
             */
            ResponseHeader.prototype.message = "";

            /**
             * Creates a new ResponseHeader instance using the specified properties.
             * @function create
             * @memberof core.header.ResponseHeader
             * @static
             * @param {core.header.IResponseHeader=} [properties] Properties to set
             * @returns {core.header.ResponseHeader} ResponseHeader instance
             */
            ResponseHeader.create = function create(properties) {
                return new ResponseHeader(properties);
            };

            /**
             * Encodes the specified ResponseHeader message. Does not implicitly {@link core.header.ResponseHeader.verify|verify} messages.
             * @function encode
             * @memberof core.header.ResponseHeader
             * @static
             * @param {core.header.IResponseHeader} message ResponseHeader message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ResponseHeader.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
                if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.message);
                return writer;
            };

            /**
             * Encodes the specified ResponseHeader message, length delimited. Does not implicitly {@link core.header.ResponseHeader.verify|verify} messages.
             * @function encodeDelimited
             * @memberof core.header.ResponseHeader
             * @static
             * @param {core.header.IResponseHeader} message ResponseHeader message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ResponseHeader.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ResponseHeader message from the specified reader or buffer.
             * @function decode
             * @memberof core.header.ResponseHeader
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {core.header.ResponseHeader} ResponseHeader
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ResponseHeader.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.core.header.ResponseHeader();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                        case 1: {
                            message.status = reader.int32();
                            break;
                        }
                        case 2: {
                            message.message = reader.string();
                            break;
                        }
                        default:
                            reader.skipType(tag & 7);
                            break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ResponseHeader message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof core.header.ResponseHeader
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {core.header.ResponseHeader} ResponseHeader
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ResponseHeader.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ResponseHeader message.
             * @function verify
             * @memberof core.header.ResponseHeader
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ResponseHeader.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.status != null && message.hasOwnProperty("status"))
                    switch (message.status) {
                        default:
                            return "status: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                    }
                if (message.message != null && message.hasOwnProperty("message"))
                    if (!$util.isString(message.message))
                        return "message: string expected";
                return null;
            };

            /**
             * Creates a ResponseHeader message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof core.header.ResponseHeader
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {core.header.ResponseHeader} ResponseHeader
             */
            ResponseHeader.fromObject = function fromObject(object) {
                if (object instanceof $root.core.header.ResponseHeader)
                    return object;
                var message = new $root.core.header.ResponseHeader();
                switch (object.status) {
                    default:
                        if (typeof object.status === "number") {
                            message.status = object.status;
                            break;
                        }
                        break;
                    case "INVALID":
                    case 0:
                        message.status = 0;
                        break;
                    case "INACTIVE":
                    case 1:
                        message.status = 1;
                        break;
                    case "ACTIVE":
                    case 2:
                        message.status = 2;
                        break;
                    case "GRADUATED":
                    case 3:
                        message.status = 3;
                        break;
                }
                if (object.message != null)
                    message.message = String(object.message);
                return message;
            };

            /**
             * Creates a plain object from a ResponseHeader message. Also converts values to other types if specified.
             * @function toObject
             * @memberof core.header.ResponseHeader
             * @static
             * @param {core.header.ResponseHeader} message ResponseHeader
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ResponseHeader.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.status = options.enums === String ? "INVALID" : 0;
                    object.message = "";
                }
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = options.enums === String ? $root.core.account.Status[message.status] === undefined ? message.status : $root.core.account.Status[message.status] : message.status;
                if (message.message != null && message.hasOwnProperty("message"))
                    object.message = message.message;
                return object;
            };

            /**
             * Converts this ResponseHeader to JSON.
             * @function toJSON
             * @memberof core.header.ResponseHeader
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ResponseHeader.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ResponseHeader
             * @function getTypeUrl
             * @memberof core.header.ResponseHeader
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ResponseHeader.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/core.header.ResponseHeader";
            };

            return ResponseHeader;
        })();

        return header;
    })();

    core.status = (function () {

        /**
         * Namespace status.
         * @memberof core
         * @namespace
         */
        var status = {};

        /**
         * Status enum.
         * @name core.status.Status
         * @enum {number}
         * @property {number} UNKNOWN=0 UNKNOWN value
         * @property {number} SUCCESS=1 SUCCESS value
         * @property {number} CLIENT_ERROR=2 CLIENT_ERROR value
         * @property {number} SERVER_ERROR=3 SERVER_ERROR value
         * @property {number} DATA_NOT_FOUND=4 DATA_NOT_FOUND value
         */
        status.Status = (function () {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNKNOWN"] = 0;
            values[valuesById[1] = "SUCCESS"] = 1;
            values[valuesById[2] = "CLIENT_ERROR"] = 2;
            values[valuesById[3] = "SERVER_ERROR"] = 3;
            values[valuesById[4] = "DATA_NOT_FOUND"] = 4;
            return values;
        })();

        return status;
    })();

    core.order = (function () {

        /**
         * Namespace order.
         * @memberof core
         * @namespace
         */
        var order = {};

        /**
         * OrderType enum.
         * @name core.order.OrderType
         * @enum {number}
         * @property {number} INVALID=0 INVALID value
         * @property {number} ASC=1 ASC value
         * @property {number} DESC=2 DESC value
         */
        order.OrderType = (function () {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "INVALID"] = 0;
            values[valuesById[1] = "ASC"] = 1;
            values[valuesById[2] = "DESC"] = 2;
            return values;
        })();

        order.Order = (function () {

            /**
             * Properties of an Order.
             * @memberof core.order
             * @interface IOrder
             * @property {core.order.OrderType|null} [orderType] Order orderType
             * @property {string|null} [orderBy] Order orderBy
             */

            /**
             * Constructs a new Order.
             * @memberof core.order
             * @classdesc Represents an Order.
             * @implements IOrder
             * @constructor
             * @param {core.order.IOrder=} [properties] Properties to set
             */
            function Order(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Order orderType.
             * @member {core.order.OrderType} orderType
             * @memberof core.order.Order
             * @instance
             */
            Order.prototype.orderType = 0;

            /**
             * Order orderBy.
             * @member {string} orderBy
             * @memberof core.order.Order
             * @instance
             */
            Order.prototype.orderBy = "";

            /**
             * Creates a new Order instance using the specified properties.
             * @function create
             * @memberof core.order.Order
             * @static
             * @param {core.order.IOrder=} [properties] Properties to set
             * @returns {core.order.Order} Order instance
             */
            Order.create = function create(properties) {
                return new Order(properties);
            };

            /**
             * Encodes the specified Order message. Does not implicitly {@link core.order.Order.verify|verify} messages.
             * @function encode
             * @memberof core.order.Order
             * @static
             * @param {core.order.IOrder} message Order message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Order.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.orderType != null && Object.hasOwnProperty.call(message, "orderType"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.orderType);
                if (message.orderBy != null && Object.hasOwnProperty.call(message, "orderBy"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.orderBy);
                return writer;
            };

            /**
             * Encodes the specified Order message, length delimited. Does not implicitly {@link core.order.Order.verify|verify} messages.
             * @function encodeDelimited
             * @memberof core.order.Order
             * @static
             * @param {core.order.IOrder} message Order message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Order.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an Order message from the specified reader or buffer.
             * @function decode
             * @memberof core.order.Order
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {core.order.Order} Order
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Order.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.core.order.Order();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                        case 1: {
                            message.orderType = reader.int32();
                            break;
                        }
                        case 2: {
                            message.orderBy = reader.string();
                            break;
                        }
                        default:
                            reader.skipType(tag & 7);
                            break;
                    }
                }
                return message;
            };

            /**
             * Decodes an Order message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof core.order.Order
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {core.order.Order} Order
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Order.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an Order message.
             * @function verify
             * @memberof core.order.Order
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Order.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.orderType != null && message.hasOwnProperty("orderType"))
                    switch (message.orderType) {
                        default:
                            return "orderType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                    }
                if (message.orderBy != null && message.hasOwnProperty("orderBy"))
                    if (!$util.isString(message.orderBy))
                        return "orderBy: string expected";
                return null;
            };

            /**
             * Creates an Order message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof core.order.Order
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {core.order.Order} Order
             */
            Order.fromObject = function fromObject(object) {
                if (object instanceof $root.core.order.Order)
                    return object;
                var message = new $root.core.order.Order();
                switch (object.orderType) {
                    default:
                        if (typeof object.orderType === "number") {
                            message.orderType = object.orderType;
                            break;
                        }
                        break;
                    case "INVALID":
                    case 0:
                        message.orderType = 0;
                        break;
                    case "ASC":
                    case 1:
                        message.orderType = 1;
                        break;
                    case "DESC":
                    case 2:
                        message.orderType = 2;
                        break;
                }
                if (object.orderBy != null)
                    message.orderBy = String(object.orderBy);
                return message;
            };

            /**
             * Creates a plain object from an Order message. Also converts values to other types if specified.
             * @function toObject
             * @memberof core.order.Order
             * @static
             * @param {core.order.Order} message Order
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Order.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.orderType = options.enums === String ? "INVALID" : 0;
                    object.orderBy = "";
                }
                if (message.orderType != null && message.hasOwnProperty("orderType"))
                    object.orderType = options.enums === String ? $root.core.order.OrderType[message.orderType] === undefined ? message.orderType : $root.core.order.OrderType[message.orderType] : message.orderType;
                if (message.orderBy != null && message.hasOwnProperty("orderBy"))
                    object.orderBy = message.orderBy;
                return object;
            };

            /**
             * Converts this Order to JSON.
             * @function toJSON
             * @memberof core.order.Order
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Order.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Order
             * @function getTypeUrl
             * @memberof core.order.Order
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Order.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/core.order.Order";
            };

            return Order;
        })();

        return order;
    })();

    core.pagination = (function () {

        /**
         * Namespace pagination.
         * @memberof core
         * @namespace
         */
        var pagination = {};

        pagination.PaginationRequest = (function () {

            /**
             * Properties of a PaginationRequest.
             * @memberof core.pagination
             * @interface IPaginationRequest
             * @property {number|null} [pageNo] PaginationRequest pageNo
             * @property {number|null} [rowsPerPage] PaginationRequest rowsPerPage
             * @property {core.order.IOrder|null} [order] PaginationRequest order
             */

            /**
             * Constructs a new PaginationRequest.
             * @memberof core.pagination
             * @classdesc Represents a PaginationRequest.
             * @implements IPaginationRequest
             * @constructor
             * @param {core.pagination.IPaginationRequest=} [properties] Properties to set
             */
            function PaginationRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PaginationRequest pageNo.
             * @member {number} pageNo
             * @memberof core.pagination.PaginationRequest
             * @instance
             */
            PaginationRequest.prototype.pageNo = 0;

            /**
             * PaginationRequest rowsPerPage.
             * @member {number} rowsPerPage
             * @memberof core.pagination.PaginationRequest
             * @instance
             */
            PaginationRequest.prototype.rowsPerPage = 0;

            /**
             * PaginationRequest order.
             * @member {core.order.IOrder|null|undefined} order
             * @memberof core.pagination.PaginationRequest
             * @instance
             */
            PaginationRequest.prototype.order = null;

            /**
             * Creates a new PaginationRequest instance using the specified properties.
             * @function create
             * @memberof core.pagination.PaginationRequest
             * @static
             * @param {core.pagination.IPaginationRequest=} [properties] Properties to set
             * @returns {core.pagination.PaginationRequest} PaginationRequest instance
             */
            PaginationRequest.create = function create(properties) {
                return new PaginationRequest(properties);
            };

            /**
             * Encodes the specified PaginationRequest message. Does not implicitly {@link core.pagination.PaginationRequest.verify|verify} messages.
             * @function encode
             * @memberof core.pagination.PaginationRequest
             * @static
             * @param {core.pagination.IPaginationRequest} message PaginationRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PaginationRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.pageNo != null && Object.hasOwnProperty.call(message, "pageNo"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.pageNo);
                if (message.rowsPerPage != null && Object.hasOwnProperty.call(message, "rowsPerPage"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.rowsPerPage);
                if (message.order != null && Object.hasOwnProperty.call(message, "order"))
                    $root.core.order.Order.encode(message.order, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified PaginationRequest message, length delimited. Does not implicitly {@link core.pagination.PaginationRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof core.pagination.PaginationRequest
             * @static
             * @param {core.pagination.IPaginationRequest} message PaginationRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PaginationRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PaginationRequest message from the specified reader or buffer.
             * @function decode
             * @memberof core.pagination.PaginationRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {core.pagination.PaginationRequest} PaginationRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PaginationRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.core.pagination.PaginationRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                        case 1: {
                            message.pageNo = reader.int32();
                            break;
                        }
                        case 2: {
                            message.rowsPerPage = reader.int32();
                            break;
                        }
                        case 3: {
                            message.order = $root.core.order.Order.decode(reader, reader.uint32());
                            break;
                        }
                        default:
                            reader.skipType(tag & 7);
                            break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PaginationRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof core.pagination.PaginationRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {core.pagination.PaginationRequest} PaginationRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PaginationRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PaginationRequest message.
             * @function verify
             * @memberof core.pagination.PaginationRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PaginationRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.pageNo != null && message.hasOwnProperty("pageNo"))
                    if (!$util.isInteger(message.pageNo))
                        return "pageNo: integer expected";
                if (message.rowsPerPage != null && message.hasOwnProperty("rowsPerPage"))
                    if (!$util.isInteger(message.rowsPerPage))
                        return "rowsPerPage: integer expected";
                if (message.order != null && message.hasOwnProperty("order")) {
                    var error = $root.core.order.Order.verify(message.order);
                    if (error)
                        return "order." + error;
                }
                return null;
            };

            /**
             * Creates a PaginationRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof core.pagination.PaginationRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {core.pagination.PaginationRequest} PaginationRequest
             */
            PaginationRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.core.pagination.PaginationRequest)
                    return object;
                var message = new $root.core.pagination.PaginationRequest();
                if (object.pageNo != null)
                    message.pageNo = object.pageNo | 0;
                if (object.rowsPerPage != null)
                    message.rowsPerPage = object.rowsPerPage | 0;
                if (object.order != null) {
                    if (typeof object.order !== "object")
                        throw TypeError(".core.pagination.PaginationRequest.order: object expected");
                    message.order = $root.core.order.Order.fromObject(object.order);
                }
                return message;
            };

            /**
             * Creates a plain object from a PaginationRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof core.pagination.PaginationRequest
             * @static
             * @param {core.pagination.PaginationRequest} message PaginationRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PaginationRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.pageNo = 0;
                    object.rowsPerPage = 0;
                    object.order = null;
                }
                if (message.pageNo != null && message.hasOwnProperty("pageNo"))
                    object.pageNo = message.pageNo;
                if (message.rowsPerPage != null && message.hasOwnProperty("rowsPerPage"))
                    object.rowsPerPage = message.rowsPerPage;
                if (message.order != null && message.hasOwnProperty("order"))
                    object.order = $root.core.order.Order.toObject(message.order, options);
                return object;
            };

            /**
             * Converts this PaginationRequest to JSON.
             * @function toJSON
             * @memberof core.pagination.PaginationRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PaginationRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PaginationRequest
             * @function getTypeUrl
             * @memberof core.pagination.PaginationRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PaginationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/core.pagination.PaginationRequest";
            };

            return PaginationRequest;
        })();

        pagination.PaginationResponse = (function () {

            /**
             * Properties of a PaginationResponse.
             * @memberof core.pagination
             * @interface IPaginationResponse
             * @property {number|null} [pageNo] PaginationResponse pageNo
             * @property {number|null} [rowsPerPage] PaginationResponse rowsPerPage
             * @property {number|null} [pageCount] PaginationResponse pageCount
             * @property {number|null} [totalCount] PaginationResponse totalCount
             */

            /**
             * Constructs a new PaginationResponse.
             * @memberof core.pagination
             * @classdesc Represents a PaginationResponse.
             * @implements IPaginationResponse
             * @constructor
             * @param {core.pagination.IPaginationResponse=} [properties] Properties to set
             */
            function PaginationResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PaginationResponse pageNo.
             * @member {number} pageNo
             * @memberof core.pagination.PaginationResponse
             * @instance
             */
            PaginationResponse.prototype.pageNo = 0;

            /**
             * PaginationResponse rowsPerPage.
             * @member {number} rowsPerPage
             * @memberof core.pagination.PaginationResponse
             * @instance
             */
            PaginationResponse.prototype.rowsPerPage = 0;

            /**
             * PaginationResponse pageCount.
             * @member {number} pageCount
             * @memberof core.pagination.PaginationResponse
             * @instance
             */
            PaginationResponse.prototype.pageCount = 0;

            /**
             * PaginationResponse totalCount.
             * @member {number} totalCount
             * @memberof core.pagination.PaginationResponse
             * @instance
             */
            PaginationResponse.prototype.totalCount = 0;

            /**
             * Creates a new PaginationResponse instance using the specified properties.
             * @function create
             * @memberof core.pagination.PaginationResponse
             * @static
             * @param {core.pagination.IPaginationResponse=} [properties] Properties to set
             * @returns {core.pagination.PaginationResponse} PaginationResponse instance
             */
            PaginationResponse.create = function create(properties) {
                return new PaginationResponse(properties);
            };

            /**
             * Encodes the specified PaginationResponse message. Does not implicitly {@link core.pagination.PaginationResponse.verify|verify} messages.
             * @function encode
             * @memberof core.pagination.PaginationResponse
             * @static
             * @param {core.pagination.IPaginationResponse} message PaginationResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PaginationResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.pageNo != null && Object.hasOwnProperty.call(message, "pageNo"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.pageNo);
                if (message.rowsPerPage != null && Object.hasOwnProperty.call(message, "rowsPerPage"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.rowsPerPage);
                if (message.pageCount != null && Object.hasOwnProperty.call(message, "pageCount"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageCount);
                if (message.totalCount != null && Object.hasOwnProperty.call(message, "totalCount"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.totalCount);
                return writer;
            };

            /**
             * Encodes the specified PaginationResponse message, length delimited. Does not implicitly {@link core.pagination.PaginationResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof core.pagination.PaginationResponse
             * @static
             * @param {core.pagination.IPaginationResponse} message PaginationResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PaginationResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PaginationResponse message from the specified reader or buffer.
             * @function decode
             * @memberof core.pagination.PaginationResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {core.pagination.PaginationResponse} PaginationResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PaginationResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.core.pagination.PaginationResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                        case 1: {
                            message.pageNo = reader.int32();
                            break;
                        }
                        case 2: {
                            message.rowsPerPage = reader.int32();
                            break;
                        }
                        case 3: {
                            message.pageCount = reader.int32();
                            break;
                        }
                        case 4: {
                            message.totalCount = reader.int32();
                            break;
                        }
                        default:
                            reader.skipType(tag & 7);
                            break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PaginationResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof core.pagination.PaginationResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {core.pagination.PaginationResponse} PaginationResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PaginationResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PaginationResponse message.
             * @function verify
             * @memberof core.pagination.PaginationResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PaginationResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.pageNo != null && message.hasOwnProperty("pageNo"))
                    if (!$util.isInteger(message.pageNo))
                        return "pageNo: integer expected";
                if (message.rowsPerPage != null && message.hasOwnProperty("rowsPerPage"))
                    if (!$util.isInteger(message.rowsPerPage))
                        return "rowsPerPage: integer expected";
                if (message.pageCount != null && message.hasOwnProperty("pageCount"))
                    if (!$util.isInteger(message.pageCount))
                        return "pageCount: integer expected";
                if (message.totalCount != null && message.hasOwnProperty("totalCount"))
                    if (!$util.isInteger(message.totalCount))
                        return "totalCount: integer expected";
                return null;
            };

            /**
             * Creates a PaginationResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof core.pagination.PaginationResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {core.pagination.PaginationResponse} PaginationResponse
             */
            PaginationResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.core.pagination.PaginationResponse)
                    return object;
                var message = new $root.core.pagination.PaginationResponse();
                if (object.pageNo != null)
                    message.pageNo = object.pageNo | 0;
                if (object.rowsPerPage != null)
                    message.rowsPerPage = object.rowsPerPage | 0;
                if (object.pageCount != null)
                    message.pageCount = object.pageCount | 0;
                if (object.totalCount != null)
                    message.totalCount = object.totalCount | 0;
                return message;
            };

            /**
             * Creates a plain object from a PaginationResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof core.pagination.PaginationResponse
             * @static
             * @param {core.pagination.PaginationResponse} message PaginationResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PaginationResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.pageNo = 0;
                    object.rowsPerPage = 0;
                    object.pageCount = 0;
                    object.totalCount = 0;
                }
                if (message.pageNo != null && message.hasOwnProperty("pageNo"))
                    object.pageNo = message.pageNo;
                if (message.rowsPerPage != null && message.hasOwnProperty("rowsPerPage"))
                    object.rowsPerPage = message.rowsPerPage;
                if (message.pageCount != null && message.hasOwnProperty("pageCount"))
                    object.pageCount = message.pageCount;
                if (message.totalCount != null && message.hasOwnProperty("totalCount"))
                    object.totalCount = message.totalCount;
                return object;
            };

            /**
             * Converts this PaginationResponse to JSON.
             * @function toJSON
             * @memberof core.pagination.PaginationResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PaginationResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PaginationResponse
             * @function getTypeUrl
             * @memberof core.pagination.PaginationResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PaginationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/core.pagination.PaginationResponse";
            };

            return PaginationResponse;
        })();

        return pagination;
    })();

    return core;
})();

$root.http = (function () {

    /**
     * Namespace http.
     * @exports http
     * @namespace
     */
    var http = {};

    http.login = (function () {

        /**
         * Namespace login.
         * @memberof http
         * @namespace
         */
        var login = {};

        login.LoginRequest = (function () {

            /**
             * Properties of a LoginRequest.
             * @memberof http.login
             * @interface ILoginRequest
             * @property {core.header.IRequestHeader|null} [header] LoginRequest header
             * @property {string|null} [username] LoginRequest username
             * @property {string|null} [password] LoginRequest password
             */

            /**
             * Constructs a new LoginRequest.
             * @memberof http.login
             * @classdesc Represents a LoginRequest.
             * @implements ILoginRequest
             * @constructor
             * @param {http.login.ILoginRequest=} [properties] Properties to set
             */
            function LoginRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * LoginRequest header.
             * @member {core.header.IRequestHeader|null|undefined} header
             * @memberof http.login.LoginRequest
             * @instance
             */
            LoginRequest.prototype.header = null;

            /**
             * LoginRequest username.
             * @member {string} username
             * @memberof http.login.LoginRequest
             * @instance
             */
            LoginRequest.prototype.username = "";

            /**
             * LoginRequest password.
             * @member {string} password
             * @memberof http.login.LoginRequest
             * @instance
             */
            LoginRequest.prototype.password = "";

            /**
             * Creates a new LoginRequest instance using the specified properties.
             * @function create
             * @memberof http.login.LoginRequest
             * @static
             * @param {http.login.ILoginRequest=} [properties] Properties to set
             * @returns {http.login.LoginRequest} LoginRequest instance
             */
            LoginRequest.create = function create(properties) {
                return new LoginRequest(properties);
            };

            /**
             * Encodes the specified LoginRequest message. Does not implicitly {@link http.login.LoginRequest.verify|verify} messages.
             * @function encode
             * @memberof http.login.LoginRequest
             * @static
             * @param {http.login.ILoginRequest} message LoginRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LoginRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.header != null && Object.hasOwnProperty.call(message, "header"))
                    $root.core.header.RequestHeader.encode(message.header, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.username != null && Object.hasOwnProperty.call(message, "username"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.username);
                if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.password);
                return writer;
            };

            /**
             * Encodes the specified LoginRequest message, length delimited. Does not implicitly {@link http.login.LoginRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof http.login.LoginRequest
             * @static
             * @param {http.login.ILoginRequest} message LoginRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LoginRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a LoginRequest message from the specified reader or buffer.
             * @function decode
             * @memberof http.login.LoginRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {http.login.LoginRequest} LoginRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LoginRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.http.login.LoginRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                        case 1: {
                            message.header = $root.core.header.RequestHeader.decode(reader, reader.uint32());
                            break;
                        }
                        case 2: {
                            message.username = reader.string();
                            break;
                        }
                        case 3: {
                            message.password = reader.string();
                            break;
                        }
                        default:
                            reader.skipType(tag & 7);
                            break;
                    }
                }
                return message;
            };

            /**
             * Decodes a LoginRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof http.login.LoginRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {http.login.LoginRequest} LoginRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LoginRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a LoginRequest message.
             * @function verify
             * @memberof http.login.LoginRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            LoginRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.header != null && message.hasOwnProperty("header")) {
                    var error = $root.core.header.RequestHeader.verify(message.header);
                    if (error)
                        return "header." + error;
                }
                if (message.username != null && message.hasOwnProperty("username"))
                    if (!$util.isString(message.username))
                        return "username: string expected";
                if (message.password != null && message.hasOwnProperty("password"))
                    if (!$util.isString(message.password))
                        return "password: string expected";
                return null;
            };

            /**
             * Creates a LoginRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof http.login.LoginRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {http.login.LoginRequest} LoginRequest
             */
            LoginRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.http.login.LoginRequest)
                    return object;
                var message = new $root.http.login.LoginRequest();
                if (object.header != null) {
                    if (typeof object.header !== "object")
                        throw TypeError(".http.login.LoginRequest.header: object expected");
                    message.header = $root.core.header.RequestHeader.fromObject(object.header);
                }
                if (object.username != null)
                    message.username = String(object.username);
                if (object.password != null)
                    message.password = String(object.password);
                return message;
            };

            /**
             * Creates a plain object from a LoginRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof http.login.LoginRequest
             * @static
             * @param {http.login.LoginRequest} message LoginRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LoginRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.header = null;
                    object.username = "";
                    object.password = "";
                }
                if (message.header != null && message.hasOwnProperty("header"))
                    object.header = $root.core.header.RequestHeader.toObject(message.header, options);
                if (message.username != null && message.hasOwnProperty("username"))
                    object.username = message.username;
                if (message.password != null && message.hasOwnProperty("password"))
                    object.password = message.password;
                return object;
            };

            /**
             * Converts this LoginRequest to JSON.
             * @function toJSON
             * @memberof http.login.LoginRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LoginRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for LoginRequest
             * @function getTypeUrl
             * @memberof http.login.LoginRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            LoginRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/http.login.LoginRequest";
            };

            return LoginRequest;
        })();

        login.LoginResponse = (function () {

            /**
             * Properties of a LoginResponse.
             * @memberof http.login
             * @interface ILoginResponse
             * @property {core.header.IResponseHeader|null} [header] LoginResponse header
             * @property {core.account.ISession|null} [session] LoginResponse session
             */

            /**
             * Constructs a new LoginResponse.
             * @memberof http.login
             * @classdesc Represents a LoginResponse.
             * @implements ILoginResponse
             * @constructor
             * @param {http.login.ILoginResponse=} [properties] Properties to set
             */
            function LoginResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * LoginResponse header.
             * @member {core.header.IResponseHeader|null|undefined} header
             * @memberof http.login.LoginResponse
             * @instance
             */
            LoginResponse.prototype.header = null;

            /**
             * LoginResponse session.
             * @member {core.account.ISession|null|undefined} session
             * @memberof http.login.LoginResponse
             * @instance
             */
            LoginResponse.prototype.session = null;

            /**
             * Creates a new LoginResponse instance using the specified properties.
             * @function create
             * @memberof http.login.LoginResponse
             * @static
             * @param {http.login.ILoginResponse=} [properties] Properties to set
             * @returns {http.login.LoginResponse} LoginResponse instance
             */
            LoginResponse.create = function create(properties) {
                return new LoginResponse(properties);
            };

            /**
             * Encodes the specified LoginResponse message. Does not implicitly {@link http.login.LoginResponse.verify|verify} messages.
             * @function encode
             * @memberof http.login.LoginResponse
             * @static
             * @param {http.login.ILoginResponse} message LoginResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LoginResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.header != null && Object.hasOwnProperty.call(message, "header"))
                    $root.core.header.ResponseHeader.encode(message.header, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.session != null && Object.hasOwnProperty.call(message, "session"))
                    $root.core.account.Session.encode(message.session, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified LoginResponse message, length delimited. Does not implicitly {@link http.login.LoginResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof http.login.LoginResponse
             * @static
             * @param {http.login.ILoginResponse} message LoginResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LoginResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a LoginResponse message from the specified reader or buffer.
             * @function decode
             * @memberof http.login.LoginResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {http.login.LoginResponse} LoginResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LoginResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.http.login.LoginResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                        case 1: {
                            message.header = $root.core.header.ResponseHeader.decode(reader, reader.uint32());
                            break;
                        }
                        case 2: {
                            message.session = $root.core.account.Session.decode(reader, reader.uint32());
                            break;
                        }
                        default:
                            reader.skipType(tag & 7);
                            break;
                    }
                }
                return message;
            };

            /**
             * Decodes a LoginResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof http.login.LoginResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {http.login.LoginResponse} LoginResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LoginResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a LoginResponse message.
             * @function verify
             * @memberof http.login.LoginResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            LoginResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.header != null && message.hasOwnProperty("header")) {
                    var error = $root.core.header.ResponseHeader.verify(message.header);
                    if (error)
                        return "header." + error;
                }
                if (message.session != null && message.hasOwnProperty("session")) {
                    var error = $root.core.account.Session.verify(message.session);
                    if (error)
                        return "session." + error;
                }
                return null;
            };

            /**
             * Creates a LoginResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof http.login.LoginResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {http.login.LoginResponse} LoginResponse
             */
            LoginResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.http.login.LoginResponse)
                    return object;
                var message = new $root.http.login.LoginResponse();
                if (object.header != null) {
                    if (typeof object.header !== "object")
                        throw TypeError(".http.login.LoginResponse.header: object expected");
                    message.header = $root.core.header.ResponseHeader.fromObject(object.header);
                }
                if (object.session != null) {
                    if (typeof object.session !== "object")
                        throw TypeError(".http.login.LoginResponse.session: object expected");
                    message.session = $root.core.account.Session.fromObject(object.session);
                }
                return message;
            };

            /**
             * Creates a plain object from a LoginResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof http.login.LoginResponse
             * @static
             * @param {http.login.LoginResponse} message LoginResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LoginResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.header = null;
                    object.session = null;
                }
                if (message.header != null && message.hasOwnProperty("header"))
                    object.header = $root.core.header.ResponseHeader.toObject(message.header, options);
                if (message.session != null && message.hasOwnProperty("session"))
                    object.session = $root.core.account.Session.toObject(message.session, options);
                return object;
            };

            /**
             * Converts this LoginResponse to JSON.
             * @function toJSON
             * @memberof http.login.LoginResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LoginResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for LoginResponse
             * @function getTypeUrl
             * @memberof http.login.LoginResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            LoginResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/http.login.LoginResponse";
            };

            return LoginResponse;
        })();

        return login;
    })();

    http.logout = (function () {

        /**
         * Namespace logout.
         * @memberof http
         * @namespace
         */
        var logout = {};

        logout.LogoutRequest = (function () {

            /**
             * Properties of a LogoutRequest.
             * @memberof http.logout
             * @interface ILogoutRequest
             * @property {core.header.IRequestHeader|null} [header] LogoutRequest header
             */

            /**
             * Constructs a new LogoutRequest.
             * @memberof http.logout
             * @classdesc Represents a LogoutRequest.
             * @implements ILogoutRequest
             * @constructor
             * @param {http.logout.ILogoutRequest=} [properties] Properties to set
             */
            function LogoutRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * LogoutRequest header.
             * @member {core.header.IRequestHeader|null|undefined} header
             * @memberof http.logout.LogoutRequest
             * @instance
             */
            LogoutRequest.prototype.header = null;

            /**
             * Creates a new LogoutRequest instance using the specified properties.
             * @function create
             * @memberof http.logout.LogoutRequest
             * @static
             * @param {http.logout.ILogoutRequest=} [properties] Properties to set
             * @returns {http.logout.LogoutRequest} LogoutRequest instance
             */
            LogoutRequest.create = function create(properties) {
                return new LogoutRequest(properties);
            };

            /**
             * Encodes the specified LogoutRequest message. Does not implicitly {@link http.logout.LogoutRequest.verify|verify} messages.
             * @function encode
             * @memberof http.logout.LogoutRequest
             * @static
             * @param {http.logout.ILogoutRequest} message LogoutRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LogoutRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.header != null && Object.hasOwnProperty.call(message, "header"))
                    $root.core.header.RequestHeader.encode(message.header, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified LogoutRequest message, length delimited. Does not implicitly {@link http.logout.LogoutRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof http.logout.LogoutRequest
             * @static
             * @param {http.logout.ILogoutRequest} message LogoutRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LogoutRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a LogoutRequest message from the specified reader or buffer.
             * @function decode
             * @memberof http.logout.LogoutRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {http.logout.LogoutRequest} LogoutRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LogoutRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.http.logout.LogoutRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                        case 1: {
                            message.header = $root.core.header.RequestHeader.decode(reader, reader.uint32());
                            break;
                        }
                        default:
                            reader.skipType(tag & 7);
                            break;
                    }
                }
                return message;
            };

            /**
             * Decodes a LogoutRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof http.logout.LogoutRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {http.logout.LogoutRequest} LogoutRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LogoutRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a LogoutRequest message.
             * @function verify
             * @memberof http.logout.LogoutRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            LogoutRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.header != null && message.hasOwnProperty("header")) {
                    var error = $root.core.header.RequestHeader.verify(message.header);
                    if (error)
                        return "header." + error;
                }
                return null;
            };

            /**
             * Creates a LogoutRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof http.logout.LogoutRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {http.logout.LogoutRequest} LogoutRequest
             */
            LogoutRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.http.logout.LogoutRequest)
                    return object;
                var message = new $root.http.logout.LogoutRequest();
                if (object.header != null) {
                    if (typeof object.header !== "object")
                        throw TypeError(".http.logout.LogoutRequest.header: object expected");
                    message.header = $root.core.header.RequestHeader.fromObject(object.header);
                }
                return message;
            };

            /**
             * Creates a plain object from a LogoutRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof http.logout.LogoutRequest
             * @static
             * @param {http.logout.LogoutRequest} message LogoutRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LogoutRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.header = null;
                if (message.header != null && message.hasOwnProperty("header"))
                    object.header = $root.core.header.RequestHeader.toObject(message.header, options);
                return object;
            };

            /**
             * Converts this LogoutRequest to JSON.
             * @function toJSON
             * @memberof http.logout.LogoutRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LogoutRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for LogoutRequest
             * @function getTypeUrl
             * @memberof http.logout.LogoutRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            LogoutRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/http.logout.LogoutRequest";
            };

            return LogoutRequest;
        })();

        logout.LogoutResponse = (function () {

            /**
             * Properties of a LogoutResponse.
             * @memberof http.logout
             * @interface ILogoutResponse
             * @property {core.header.IResponseHeader|null} [header] LogoutResponse header
             */

            /**
             * Constructs a new LogoutResponse.
             * @memberof http.logout
             * @classdesc Represents a LogoutResponse.
             * @implements ILogoutResponse
             * @constructor
             * @param {http.logout.ILogoutResponse=} [properties] Properties to set
             */
            function LogoutResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * LogoutResponse header.
             * @member {core.header.IResponseHeader|null|undefined} header
             * @memberof http.logout.LogoutResponse
             * @instance
             */
            LogoutResponse.prototype.header = null;

            /**
             * Creates a new LogoutResponse instance using the specified properties.
             * @function create
             * @memberof http.logout.LogoutResponse
             * @static
             * @param {http.logout.ILogoutResponse=} [properties] Properties to set
             * @returns {http.logout.LogoutResponse} LogoutResponse instance
             */
            LogoutResponse.create = function create(properties) {
                return new LogoutResponse(properties);
            };

            /**
             * Encodes the specified LogoutResponse message. Does not implicitly {@link http.logout.LogoutResponse.verify|verify} messages.
             * @function encode
             * @memberof http.logout.LogoutResponse
             * @static
             * @param {http.logout.ILogoutResponse} message LogoutResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LogoutResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.header != null && Object.hasOwnProperty.call(message, "header"))
                    $root.core.header.ResponseHeader.encode(message.header, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified LogoutResponse message, length delimited. Does not implicitly {@link http.logout.LogoutResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof http.logout.LogoutResponse
             * @static
             * @param {http.logout.ILogoutResponse} message LogoutResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LogoutResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a LogoutResponse message from the specified reader or buffer.
             * @function decode
             * @memberof http.logout.LogoutResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {http.logout.LogoutResponse} LogoutResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LogoutResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.http.logout.LogoutResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                        case 1: {
                            message.header = $root.core.header.ResponseHeader.decode(reader, reader.uint32());
                            break;
                        }
                        default:
                            reader.skipType(tag & 7);
                            break;
                    }
                }
                return message;
            };

            /**
             * Decodes a LogoutResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof http.logout.LogoutResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {http.logout.LogoutResponse} LogoutResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LogoutResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a LogoutResponse message.
             * @function verify
             * @memberof http.logout.LogoutResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            LogoutResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.header != null && message.hasOwnProperty("header")) {
                    var error = $root.core.header.ResponseHeader.verify(message.header);
                    if (error)
                        return "header." + error;
                }
                return null;
            };

            /**
             * Creates a LogoutResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof http.logout.LogoutResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {http.logout.LogoutResponse} LogoutResponse
             */
            LogoutResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.http.logout.LogoutResponse)
                    return object;
                var message = new $root.http.logout.LogoutResponse();
                if (object.header != null) {
                    if (typeof object.header !== "object")
                        throw TypeError(".http.logout.LogoutResponse.header: object expected");
                    message.header = $root.core.header.ResponseHeader.fromObject(object.header);
                }
                return message;
            };

            /**
             * Creates a plain object from a LogoutResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof http.logout.LogoutResponse
             * @static
             * @param {http.logout.LogoutResponse} message LogoutResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LogoutResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.header = null;
                if (message.header != null && message.hasOwnProperty("header"))
                    object.header = $root.core.header.ResponseHeader.toObject(message.header, options);
                return object;
            };

            /**
             * Converts this LogoutResponse to JSON.
             * @function toJSON
             * @memberof http.logout.LogoutResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LogoutResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for LogoutResponse
             * @function getTypeUrl
             * @memberof http.logout.LogoutResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            LogoutResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/http.logout.LogoutResponse";
            };

            return LogoutResponse;
        })();

        return logout;
    })();

    http.read_documents = (function () {

        /**
         * Namespace read_documents.
         * @memberof http
         * @namespace
         */
        var read_documents = {};

        read_documents.ReadDocumentsRequest = (function () {

            /**
             * Properties of a ReadDocumentsRequest.
             * @memberof http.read_documents
             * @interface IReadDocumentsRequest
             * @property {core.header.IRequestHeader|null} [header] ReadDocumentsRequest header
             * @property {Array.<string>|null} [categoryIds] ReadDocumentsRequest categoryIds
             * @property {string|null} [searchText] ReadDocumentsRequest searchText
             * @property {core.pagination.IPaginationRequest|null} [pagination] ReadDocumentsRequest pagination
             */

            /**
             * Constructs a new ReadDocumentsRequest.
             * @memberof http.read_documents
             * @classdesc Represents a ReadDocumentsRequest.
             * @implements IReadDocumentsRequest
             * @constructor
             * @param {http.read_documents.IReadDocumentsRequest=} [properties] Properties to set
             */
            function ReadDocumentsRequest(properties) {
                this.categoryIds = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ReadDocumentsRequest header.
             * @member {core.header.IRequestHeader|null|undefined} header
             * @memberof http.read_documents.ReadDocumentsRequest
             * @instance
             */
            ReadDocumentsRequest.prototype.header = null;

            /**
             * ReadDocumentsRequest categoryIds.
             * @member {Array.<string>} categoryIds
             * @memberof http.read_documents.ReadDocumentsRequest
             * @instance
             */
            ReadDocumentsRequest.prototype.categoryIds = $util.emptyArray;

            /**
             * ReadDocumentsRequest searchText.
             * @member {string} searchText
             * @memberof http.read_documents.ReadDocumentsRequest
             * @instance
             */
            ReadDocumentsRequest.prototype.searchText = "";

            /**
             * ReadDocumentsRequest pagination.
             * @member {core.pagination.IPaginationRequest|null|undefined} pagination
             * @memberof http.read_documents.ReadDocumentsRequest
             * @instance
             */
            ReadDocumentsRequest.prototype.pagination = null;

            /**
             * Creates a new ReadDocumentsRequest instance using the specified properties.
             * @function create
             * @memberof http.read_documents.ReadDocumentsRequest
             * @static
             * @param {http.read_documents.IReadDocumentsRequest=} [properties] Properties to set
             * @returns {http.read_documents.ReadDocumentsRequest} ReadDocumentsRequest instance
             */
            ReadDocumentsRequest.create = function create(properties) {
                return new ReadDocumentsRequest(properties);
            };

            /**
             * Encodes the specified ReadDocumentsRequest message. Does not implicitly {@link http.read_documents.ReadDocumentsRequest.verify|verify} messages.
             * @function encode
             * @memberof http.read_documents.ReadDocumentsRequest
             * @static
             * @param {http.read_documents.IReadDocumentsRequest} message ReadDocumentsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReadDocumentsRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.header != null && Object.hasOwnProperty.call(message, "header"))
                    $root.core.header.RequestHeader.encode(message.header, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.categoryIds != null && message.categoryIds.length)
                    for (var i = 0; i < message.categoryIds.length; ++i)
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.categoryIds[i]);
                if (message.searchText != null && Object.hasOwnProperty.call(message, "searchText"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.searchText);
                if (message.pagination != null && Object.hasOwnProperty.call(message, "pagination"))
                    $root.core.pagination.PaginationRequest.encode(message.pagination, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified ReadDocumentsRequest message, length delimited. Does not implicitly {@link http.read_documents.ReadDocumentsRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof http.read_documents.ReadDocumentsRequest
             * @static
             * @param {http.read_documents.IReadDocumentsRequest} message ReadDocumentsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReadDocumentsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ReadDocumentsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof http.read_documents.ReadDocumentsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {http.read_documents.ReadDocumentsRequest} ReadDocumentsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReadDocumentsRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.http.read_documents.ReadDocumentsRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                        case 1: {
                            message.header = $root.core.header.RequestHeader.decode(reader, reader.uint32());
                            break;
                        }
                        case 2: {
                            if (!(message.categoryIds && message.categoryIds.length))
                                message.categoryIds = [];
                            message.categoryIds.push(reader.string());
                            break;
                        }
                        case 3: {
                            message.searchText = reader.string();
                            break;
                        }
                        case 4: {
                            message.pagination = $root.core.pagination.PaginationRequest.decode(reader, reader.uint32());
                            break;
                        }
                        default:
                            reader.skipType(tag & 7);
                            break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ReadDocumentsRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof http.read_documents.ReadDocumentsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {http.read_documents.ReadDocumentsRequest} ReadDocumentsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReadDocumentsRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ReadDocumentsRequest message.
             * @function verify
             * @memberof http.read_documents.ReadDocumentsRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ReadDocumentsRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.header != null && message.hasOwnProperty("header")) {
                    var error = $root.core.header.RequestHeader.verify(message.header);
                    if (error)
                        return "header." + error;
                }
                if (message.categoryIds != null && message.hasOwnProperty("categoryIds")) {
                    if (!Array.isArray(message.categoryIds))
                        return "categoryIds: array expected";
                    for (var i = 0; i < message.categoryIds.length; ++i)
                        if (!$util.isString(message.categoryIds[i]))
                            return "categoryIds: string[] expected";
                }
                if (message.searchText != null && message.hasOwnProperty("searchText"))
                    if (!$util.isString(message.searchText))
                        return "searchText: string expected";
                if (message.pagination != null && message.hasOwnProperty("pagination")) {
                    var error = $root.core.pagination.PaginationRequest.verify(message.pagination);
                    if (error)
                        return "pagination." + error;
                }
                return null;
            };

            /**
             * Creates a ReadDocumentsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof http.read_documents.ReadDocumentsRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {http.read_documents.ReadDocumentsRequest} ReadDocumentsRequest
             */
            ReadDocumentsRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.http.read_documents.ReadDocumentsRequest)
                    return object;
                var message = new $root.http.read_documents.ReadDocumentsRequest();
                if (object.header != null) {
                    if (typeof object.header !== "object")
                        throw TypeError(".http.read_documents.ReadDocumentsRequest.header: object expected");
                    message.header = $root.core.header.RequestHeader.fromObject(object.header);
                }
                if (object.categoryIds) {
                    if (!Array.isArray(object.categoryIds))
                        throw TypeError(".http.read_documents.ReadDocumentsRequest.categoryIds: array expected");
                    message.categoryIds = [];
                    for (var i = 0; i < object.categoryIds.length; ++i)
                        message.categoryIds[i] = String(object.categoryIds[i]);
                }
                if (object.searchText != null)
                    message.searchText = String(object.searchText);
                if (object.pagination != null) {
                    if (typeof object.pagination !== "object")
                        throw TypeError(".http.read_documents.ReadDocumentsRequest.pagination: object expected");
                    message.pagination = $root.core.pagination.PaginationRequest.fromObject(object.pagination);
                }
                return message;
            };

            /**
             * Creates a plain object from a ReadDocumentsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof http.read_documents.ReadDocumentsRequest
             * @static
             * @param {http.read_documents.ReadDocumentsRequest} message ReadDocumentsRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ReadDocumentsRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.categoryIds = [];
                if (options.defaults) {
                    object.header = null;
                    object.searchText = "";
                    object.pagination = null;
                }
                if (message.header != null && message.hasOwnProperty("header"))
                    object.header = $root.core.header.RequestHeader.toObject(message.header, options);
                if (message.categoryIds && message.categoryIds.length) {
                    object.categoryIds = [];
                    for (var j = 0; j < message.categoryIds.length; ++j)
                        object.categoryIds[j] = message.categoryIds[j];
                }
                if (message.searchText != null && message.hasOwnProperty("searchText"))
                    object.searchText = message.searchText;
                if (message.pagination != null && message.hasOwnProperty("pagination"))
                    object.pagination = $root.core.pagination.PaginationRequest.toObject(message.pagination, options);
                return object;
            };

            /**
             * Converts this ReadDocumentsRequest to JSON.
             * @function toJSON
             * @memberof http.read_documents.ReadDocumentsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ReadDocumentsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ReadDocumentsRequest
             * @function getTypeUrl
             * @memberof http.read_documents.ReadDocumentsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ReadDocumentsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/http.read_documents.ReadDocumentsRequest";
            };

            return ReadDocumentsRequest;
        })();

        read_documents.ReadDocumentsResponse = (function () {

            /**
             * Properties of a ReadDocumentsResponse.
             * @memberof http.read_documents
             * @interface IReadDocumentsResponse
             * @property {core.header.IResponseHeader|null} [header] ReadDocumentsResponse header
             * @property {Array.<model.document.IDocument>|null} [documents] ReadDocumentsResponse documents
             * @property {core.pagination.IPaginationResponse|null} [pagination] ReadDocumentsResponse pagination
             */

            /**
             * Constructs a new ReadDocumentsResponse.
             * @memberof http.read_documents
             * @classdesc Represents a ReadDocumentsResponse.
             * @implements IReadDocumentsResponse
             * @constructor
             * @param {http.read_documents.IReadDocumentsResponse=} [properties] Properties to set
             */
            function ReadDocumentsResponse(properties) {
                this.documents = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ReadDocumentsResponse header.
             * @member {core.header.IResponseHeader|null|undefined} header
             * @memberof http.read_documents.ReadDocumentsResponse
             * @instance
             */
            ReadDocumentsResponse.prototype.header = null;

            /**
             * ReadDocumentsResponse documents.
             * @member {Array.<model.document.IDocument>} documents
             * @memberof http.read_documents.ReadDocumentsResponse
             * @instance
             */
            ReadDocumentsResponse.prototype.documents = $util.emptyArray;

            /**
             * ReadDocumentsResponse pagination.
             * @member {core.pagination.IPaginationResponse|null|undefined} pagination
             * @memberof http.read_documents.ReadDocumentsResponse
             * @instance
             */
            ReadDocumentsResponse.prototype.pagination = null;

            /**
             * Creates a new ReadDocumentsResponse instance using the specified properties.
             * @function create
             * @memberof http.read_documents.ReadDocumentsResponse
             * @static
             * @param {http.read_documents.IReadDocumentsResponse=} [properties] Properties to set
             * @returns {http.read_documents.ReadDocumentsResponse} ReadDocumentsResponse instance
             */
            ReadDocumentsResponse.create = function create(properties) {
                return new ReadDocumentsResponse(properties);
            };

            /**
             * Encodes the specified ReadDocumentsResponse message. Does not implicitly {@link http.read_documents.ReadDocumentsResponse.verify|verify} messages.
             * @function encode
             * @memberof http.read_documents.ReadDocumentsResponse
             * @static
             * @param {http.read_documents.IReadDocumentsResponse} message ReadDocumentsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReadDocumentsResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.header != null && Object.hasOwnProperty.call(message, "header"))
                    $root.core.header.ResponseHeader.encode(message.header, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.documents != null && message.documents.length)
                    for (var i = 0; i < message.documents.length; ++i)
                        $root.model.document.Document.encode(message.documents[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.pagination != null && Object.hasOwnProperty.call(message, "pagination"))
                    $root.core.pagination.PaginationResponse.encode(message.pagination, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified ReadDocumentsResponse message, length delimited. Does not implicitly {@link http.read_documents.ReadDocumentsResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof http.read_documents.ReadDocumentsResponse
             * @static
             * @param {http.read_documents.IReadDocumentsResponse} message ReadDocumentsResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReadDocumentsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ReadDocumentsResponse message from the specified reader or buffer.
             * @function decode
             * @memberof http.read_documents.ReadDocumentsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {http.read_documents.ReadDocumentsResponse} ReadDocumentsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReadDocumentsResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.http.read_documents.ReadDocumentsResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                        case 1: {
                            message.header = $root.core.header.ResponseHeader.decode(reader, reader.uint32());
                            break;
                        }
                        case 2: {
                            if (!(message.documents && message.documents.length))
                                message.documents = [];
                            message.documents.push($root.model.document.Document.decode(reader, reader.uint32()));
                            break;
                        }
                        case 3: {
                            message.pagination = $root.core.pagination.PaginationResponse.decode(reader, reader.uint32());
                            break;
                        }
                        default:
                            reader.skipType(tag & 7);
                            break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ReadDocumentsResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof http.read_documents.ReadDocumentsResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {http.read_documents.ReadDocumentsResponse} ReadDocumentsResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReadDocumentsResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ReadDocumentsResponse message.
             * @function verify
             * @memberof http.read_documents.ReadDocumentsResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ReadDocumentsResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.header != null && message.hasOwnProperty("header")) {
                    var error = $root.core.header.ResponseHeader.verify(message.header);
                    if (error)
                        return "header." + error;
                }
                if (message.documents != null && message.hasOwnProperty("documents")) {
                    if (!Array.isArray(message.documents))
                        return "documents: array expected";
                    for (var i = 0; i < message.documents.length; ++i) {
                        var error = $root.model.document.Document.verify(message.documents[i]);
                        if (error)
                            return "documents." + error;
                    }
                }
                if (message.pagination != null && message.hasOwnProperty("pagination")) {
                    var error = $root.core.pagination.PaginationResponse.verify(message.pagination);
                    if (error)
                        return "pagination." + error;
                }
                return null;
            };

            /**
             * Creates a ReadDocumentsResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof http.read_documents.ReadDocumentsResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {http.read_documents.ReadDocumentsResponse} ReadDocumentsResponse
             */
            ReadDocumentsResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.http.read_documents.ReadDocumentsResponse)
                    return object;
                var message = new $root.http.read_documents.ReadDocumentsResponse();
                if (object.header != null) {
                    if (typeof object.header !== "object")
                        throw TypeError(".http.read_documents.ReadDocumentsResponse.header: object expected");
                    message.header = $root.core.header.ResponseHeader.fromObject(object.header);
                }
                if (object.documents) {
                    if (!Array.isArray(object.documents))
                        throw TypeError(".http.read_documents.ReadDocumentsResponse.documents: array expected");
                    message.documents = [];
                    for (var i = 0; i < object.documents.length; ++i) {
                        if (typeof object.documents[i] !== "object")
                            throw TypeError(".http.read_documents.ReadDocumentsResponse.documents: object expected");
                        message.documents[i] = $root.model.document.Document.fromObject(object.documents[i]);
                    }
                }
                if (object.pagination != null) {
                    if (typeof object.pagination !== "object")
                        throw TypeError(".http.read_documents.ReadDocumentsResponse.pagination: object expected");
                    message.pagination = $root.core.pagination.PaginationResponse.fromObject(object.pagination);
                }
                return message;
            };

            /**
             * Creates a plain object from a ReadDocumentsResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof http.read_documents.ReadDocumentsResponse
             * @static
             * @param {http.read_documents.ReadDocumentsResponse} message ReadDocumentsResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ReadDocumentsResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.documents = [];
                if (options.defaults) {
                    object.header = null;
                    object.pagination = null;
                }
                if (message.header != null && message.hasOwnProperty("header"))
                    object.header = $root.core.header.ResponseHeader.toObject(message.header, options);
                if (message.documents && message.documents.length) {
                    object.documents = [];
                    for (var j = 0; j < message.documents.length; ++j)
                        object.documents[j] = $root.model.document.Document.toObject(message.documents[j], options);
                }
                if (message.pagination != null && message.hasOwnProperty("pagination"))
                    object.pagination = $root.core.pagination.PaginationResponse.toObject(message.pagination, options);
                return object;
            };

            /**
             * Converts this ReadDocumentsResponse to JSON.
             * @function toJSON
             * @memberof http.read_documents.ReadDocumentsResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ReadDocumentsResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ReadDocumentsResponse
             * @function getTypeUrl
             * @memberof http.read_documents.ReadDocumentsResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ReadDocumentsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/http.read_documents.ReadDocumentsResponse";
            };

            return ReadDocumentsResponse;
        })();

        return read_documents;
    })();

    http.read_document_categories = (function () {

        /**
         * Namespace read_document_categories.
         * @memberof http
         * @namespace
         */
        var read_document_categories = {};

        read_document_categories.ReadDocumentCategoriesRequest = (function () {

            /**
             * Properties of a ReadDocumentCategoriesRequest.
             * @memberof http.read_document_categories
             * @interface IReadDocumentCategoriesRequest
             * @property {core.header.IRequestHeader|null} [header] ReadDocumentCategoriesRequest header
             */

            /**
             * Constructs a new ReadDocumentCategoriesRequest.
             * @memberof http.read_document_categories
             * @classdesc Represents a ReadDocumentCategoriesRequest.
             * @implements IReadDocumentCategoriesRequest
             * @constructor
             * @param {http.read_document_categories.IReadDocumentCategoriesRequest=} [properties] Properties to set
             */
            function ReadDocumentCategoriesRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ReadDocumentCategoriesRequest header.
             * @member {core.header.IRequestHeader|null|undefined} header
             * @memberof http.read_document_categories.ReadDocumentCategoriesRequest
             * @instance
             */
            ReadDocumentCategoriesRequest.prototype.header = null;

            /**
             * Creates a new ReadDocumentCategoriesRequest instance using the specified properties.
             * @function create
             * @memberof http.read_document_categories.ReadDocumentCategoriesRequest
             * @static
             * @param {http.read_document_categories.IReadDocumentCategoriesRequest=} [properties] Properties to set
             * @returns {http.read_document_categories.ReadDocumentCategoriesRequest} ReadDocumentCategoriesRequest instance
             */
            ReadDocumentCategoriesRequest.create = function create(properties) {
                return new ReadDocumentCategoriesRequest(properties);
            };

            /**
             * Encodes the specified ReadDocumentCategoriesRequest message. Does not implicitly {@link http.read_document_categories.ReadDocumentCategoriesRequest.verify|verify} messages.
             * @function encode
             * @memberof http.read_document_categories.ReadDocumentCategoriesRequest
             * @static
             * @param {http.read_document_categories.IReadDocumentCategoriesRequest} message ReadDocumentCategoriesRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReadDocumentCategoriesRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.header != null && Object.hasOwnProperty.call(message, "header"))
                    $root.core.header.RequestHeader.encode(message.header, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified ReadDocumentCategoriesRequest message, length delimited. Does not implicitly {@link http.read_document_categories.ReadDocumentCategoriesRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof http.read_document_categories.ReadDocumentCategoriesRequest
             * @static
             * @param {http.read_document_categories.IReadDocumentCategoriesRequest} message ReadDocumentCategoriesRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReadDocumentCategoriesRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ReadDocumentCategoriesRequest message from the specified reader or buffer.
             * @function decode
             * @memberof http.read_document_categories.ReadDocumentCategoriesRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {http.read_document_categories.ReadDocumentCategoriesRequest} ReadDocumentCategoriesRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReadDocumentCategoriesRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.http.read_document_categories.ReadDocumentCategoriesRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                        case 1: {
                            message.header = $root.core.header.RequestHeader.decode(reader, reader.uint32());
                            break;
                        }
                        default:
                            reader.skipType(tag & 7);
                            break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ReadDocumentCategoriesRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof http.read_document_categories.ReadDocumentCategoriesRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {http.read_document_categories.ReadDocumentCategoriesRequest} ReadDocumentCategoriesRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReadDocumentCategoriesRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ReadDocumentCategoriesRequest message.
             * @function verify
             * @memberof http.read_document_categories.ReadDocumentCategoriesRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ReadDocumentCategoriesRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.header != null && message.hasOwnProperty("header")) {
                    var error = $root.core.header.RequestHeader.verify(message.header);
                    if (error)
                        return "header." + error;
                }
                return null;
            };

            /**
             * Creates a ReadDocumentCategoriesRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof http.read_document_categories.ReadDocumentCategoriesRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {http.read_document_categories.ReadDocumentCategoriesRequest} ReadDocumentCategoriesRequest
             */
            ReadDocumentCategoriesRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.http.read_document_categories.ReadDocumentCategoriesRequest)
                    return object;
                var message = new $root.http.read_document_categories.ReadDocumentCategoriesRequest();
                if (object.header != null) {
                    if (typeof object.header !== "object")
                        throw TypeError(".http.read_document_categories.ReadDocumentCategoriesRequest.header: object expected");
                    message.header = $root.core.header.RequestHeader.fromObject(object.header);
                }
                return message;
            };

            /**
             * Creates a plain object from a ReadDocumentCategoriesRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof http.read_document_categories.ReadDocumentCategoriesRequest
             * @static
             * @param {http.read_document_categories.ReadDocumentCategoriesRequest} message ReadDocumentCategoriesRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ReadDocumentCategoriesRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.header = null;
                if (message.header != null && message.hasOwnProperty("header"))
                    object.header = $root.core.header.RequestHeader.toObject(message.header, options);
                return object;
            };

            /**
             * Converts this ReadDocumentCategoriesRequest to JSON.
             * @function toJSON
             * @memberof http.read_document_categories.ReadDocumentCategoriesRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ReadDocumentCategoriesRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ReadDocumentCategoriesRequest
             * @function getTypeUrl
             * @memberof http.read_document_categories.ReadDocumentCategoriesRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ReadDocumentCategoriesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/http.read_document_categories.ReadDocumentCategoriesRequest";
            };

            return ReadDocumentCategoriesRequest;
        })();

        read_document_categories.ReadDocumentCategoriesResponse = (function () {

            /**
             * Properties of a ReadDocumentCategoriesResponse.
             * @memberof http.read_document_categories
             * @interface IReadDocumentCategoriesResponse
             * @property {core.header.IResponseHeader|null} [header] ReadDocumentCategoriesResponse header
             * @property {Array.<model.document.IDocumentCategory>|null} [categories] ReadDocumentCategoriesResponse categories
             */

            /**
             * Constructs a new ReadDocumentCategoriesResponse.
             * @memberof http.read_document_categories
             * @classdesc Represents a ReadDocumentCategoriesResponse.
             * @implements IReadDocumentCategoriesResponse
             * @constructor
             * @param {http.read_document_categories.IReadDocumentCategoriesResponse=} [properties] Properties to set
             */
            function ReadDocumentCategoriesResponse(properties) {
                this.categories = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ReadDocumentCategoriesResponse header.
             * @member {core.header.IResponseHeader|null|undefined} header
             * @memberof http.read_document_categories.ReadDocumentCategoriesResponse
             * @instance
             */
            ReadDocumentCategoriesResponse.prototype.header = null;

            /**
             * ReadDocumentCategoriesResponse categories.
             * @member {Array.<model.document.IDocumentCategory>} categories
             * @memberof http.read_document_categories.ReadDocumentCategoriesResponse
             * @instance
             */
            ReadDocumentCategoriesResponse.prototype.categories = $util.emptyArray;

            /**
             * Creates a new ReadDocumentCategoriesResponse instance using the specified properties.
             * @function create
             * @memberof http.read_document_categories.ReadDocumentCategoriesResponse
             * @static
             * @param {http.read_document_categories.IReadDocumentCategoriesResponse=} [properties] Properties to set
             * @returns {http.read_document_categories.ReadDocumentCategoriesResponse} ReadDocumentCategoriesResponse instance
             */
            ReadDocumentCategoriesResponse.create = function create(properties) {
                return new ReadDocumentCategoriesResponse(properties);
            };

            /**
             * Encodes the specified ReadDocumentCategoriesResponse message. Does not implicitly {@link http.read_document_categories.ReadDocumentCategoriesResponse.verify|verify} messages.
             * @function encode
             * @memberof http.read_document_categories.ReadDocumentCategoriesResponse
             * @static
             * @param {http.read_document_categories.IReadDocumentCategoriesResponse} message ReadDocumentCategoriesResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReadDocumentCategoriesResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.header != null && Object.hasOwnProperty.call(message, "header"))
                    $root.core.header.ResponseHeader.encode(message.header, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.categories != null && message.categories.length)
                    for (var i = 0; i < message.categories.length; ++i)
                        $root.model.document.DocumentCategory.encode(message.categories[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified ReadDocumentCategoriesResponse message, length delimited. Does not implicitly {@link http.read_document_categories.ReadDocumentCategoriesResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof http.read_document_categories.ReadDocumentCategoriesResponse
             * @static
             * @param {http.read_document_categories.IReadDocumentCategoriesResponse} message ReadDocumentCategoriesResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReadDocumentCategoriesResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ReadDocumentCategoriesResponse message from the specified reader or buffer.
             * @function decode
             * @memberof http.read_document_categories.ReadDocumentCategoriesResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {http.read_document_categories.ReadDocumentCategoriesResponse} ReadDocumentCategoriesResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReadDocumentCategoriesResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.http.read_document_categories.ReadDocumentCategoriesResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                        case 1: {
                            message.header = $root.core.header.ResponseHeader.decode(reader, reader.uint32());
                            break;
                        }
                        case 2: {
                            if (!(message.categories && message.categories.length))
                                message.categories = [];
                            message.categories.push($root.model.document.DocumentCategory.decode(reader, reader.uint32()));
                            break;
                        }
                        default:
                            reader.skipType(tag & 7);
                            break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ReadDocumentCategoriesResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof http.read_document_categories.ReadDocumentCategoriesResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {http.read_document_categories.ReadDocumentCategoriesResponse} ReadDocumentCategoriesResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReadDocumentCategoriesResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ReadDocumentCategoriesResponse message.
             * @function verify
             * @memberof http.read_document_categories.ReadDocumentCategoriesResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ReadDocumentCategoriesResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.header != null && message.hasOwnProperty("header")) {
                    var error = $root.core.header.ResponseHeader.verify(message.header);
                    if (error)
                        return "header." + error;
                }
                if (message.categories != null && message.hasOwnProperty("categories")) {
                    if (!Array.isArray(message.categories))
                        return "categories: array expected";
                    for (var i = 0; i < message.categories.length; ++i) {
                        var error = $root.model.document.DocumentCategory.verify(message.categories[i]);
                        if (error)
                            return "categories." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a ReadDocumentCategoriesResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof http.read_document_categories.ReadDocumentCategoriesResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {http.read_document_categories.ReadDocumentCategoriesResponse} ReadDocumentCategoriesResponse
             */
            ReadDocumentCategoriesResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.http.read_document_categories.ReadDocumentCategoriesResponse)
                    return object;
                var message = new $root.http.read_document_categories.ReadDocumentCategoriesResponse();
                if (object.header != null) {
                    if (typeof object.header !== "object")
                        throw TypeError(".http.read_document_categories.ReadDocumentCategoriesResponse.header: object expected");
                    message.header = $root.core.header.ResponseHeader.fromObject(object.header);
                }
                if (object.categories) {
                    if (!Array.isArray(object.categories))
                        throw TypeError(".http.read_document_categories.ReadDocumentCategoriesResponse.categories: array expected");
                    message.categories = [];
                    for (var i = 0; i < object.categories.length; ++i) {
                        if (typeof object.categories[i] !== "object")
                            throw TypeError(".http.read_document_categories.ReadDocumentCategoriesResponse.categories: object expected");
                        message.categories[i] = $root.model.document.DocumentCategory.fromObject(object.categories[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a ReadDocumentCategoriesResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof http.read_document_categories.ReadDocumentCategoriesResponse
             * @static
             * @param {http.read_document_categories.ReadDocumentCategoriesResponse} message ReadDocumentCategoriesResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ReadDocumentCategoriesResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.categories = [];
                if (options.defaults)
                    object.header = null;
                if (message.header != null && message.hasOwnProperty("header"))
                    object.header = $root.core.header.ResponseHeader.toObject(message.header, options);
                if (message.categories && message.categories.length) {
                    object.categories = [];
                    for (var j = 0; j < message.categories.length; ++j)
                        object.categories[j] = $root.model.document.DocumentCategory.toObject(message.categories[j], options);
                }
                return object;
            };

            /**
             * Converts this ReadDocumentCategoriesResponse to JSON.
             * @function toJSON
             * @memberof http.read_document_categories.ReadDocumentCategoriesResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ReadDocumentCategoriesResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ReadDocumentCategoriesResponse
             * @function getTypeUrl
             * @memberof http.read_document_categories.ReadDocumentCategoriesResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ReadDocumentCategoriesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/http.read_document_categories.ReadDocumentCategoriesResponse";
            };

            return ReadDocumentCategoriesResponse;
        })();

        return read_document_categories;
    })();

    http.read_service_hour = (function () {

        /**
         * Namespace read_service_hour.
         * @memberof http
         * @namespace
         */
        var read_service_hour = {};

        read_service_hour.ReadServiceHourRequest = (function () {

            /**
             * Properties of a ReadServiceHourRequest.
             * @memberof http.read_service_hour
             * @interface IReadServiceHourRequest
             * @property {core.header.IRequestHeader|null} [header] ReadServiceHourRequest header
             * @property {number|null} [id] ReadServiceHourRequest id
             */

            /**
             * Constructs a new ReadServiceHourRequest.
             * @memberof http.read_service_hour
             * @classdesc Represents a ReadServiceHourRequest.
             * @implements IReadServiceHourRequest
             * @constructor
             * @param {http.read_service_hour.IReadServiceHourRequest=} [properties] Properties to set
             */
            function ReadServiceHourRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ReadServiceHourRequest header.
             * @member {core.header.IRequestHeader|null|undefined} header
             * @memberof http.read_service_hour.ReadServiceHourRequest
             * @instance
             */
            ReadServiceHourRequest.prototype.header = null;

            /**
             * ReadServiceHourRequest id.
             * @member {number} id
             * @memberof http.read_service_hour.ReadServiceHourRequest
             * @instance
             */
            ReadServiceHourRequest.prototype.id = 0;

            /**
             * Creates a new ReadServiceHourRequest instance using the specified properties.
             * @function create
             * @memberof http.read_service_hour.ReadServiceHourRequest
             * @static
             * @param {http.read_service_hour.IReadServiceHourRequest=} [properties] Properties to set
             * @returns {http.read_service_hour.ReadServiceHourRequest} ReadServiceHourRequest instance
             */
            ReadServiceHourRequest.create = function create(properties) {
                return new ReadServiceHourRequest(properties);
            };

            /**
             * Encodes the specified ReadServiceHourRequest message. Does not implicitly {@link http.read_service_hour.ReadServiceHourRequest.verify|verify} messages.
             * @function encode
             * @memberof http.read_service_hour.ReadServiceHourRequest
             * @static
             * @param {http.read_service_hour.IReadServiceHourRequest} message ReadServiceHourRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReadServiceHourRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.header != null && Object.hasOwnProperty.call(message, "header"))
                    $root.core.header.RequestHeader.encode(message.header, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.id);
                return writer;
            };

            /**
             * Encodes the specified ReadServiceHourRequest message, length delimited. Does not implicitly {@link http.read_service_hour.ReadServiceHourRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof http.read_service_hour.ReadServiceHourRequest
             * @static
             * @param {http.read_service_hour.IReadServiceHourRequest} message ReadServiceHourRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReadServiceHourRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ReadServiceHourRequest message from the specified reader or buffer.
             * @function decode
             * @memberof http.read_service_hour.ReadServiceHourRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {http.read_service_hour.ReadServiceHourRequest} ReadServiceHourRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReadServiceHourRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.http.read_service_hour.ReadServiceHourRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                        case 1: {
                            message.header = $root.core.header.RequestHeader.decode(reader, reader.uint32());
                            break;
                        }
                        case 2: {
                            message.id = reader.int32();
                            break;
                        }
                        default:
                            reader.skipType(tag & 7);
                            break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ReadServiceHourRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof http.read_service_hour.ReadServiceHourRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {http.read_service_hour.ReadServiceHourRequest} ReadServiceHourRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReadServiceHourRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ReadServiceHourRequest message.
             * @function verify
             * @memberof http.read_service_hour.ReadServiceHourRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ReadServiceHourRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.header != null && message.hasOwnProperty("header")) {
                    var error = $root.core.header.RequestHeader.verify(message.header);
                    if (error)
                        return "header." + error;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                return null;
            };

            /**
             * Creates a ReadServiceHourRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof http.read_service_hour.ReadServiceHourRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {http.read_service_hour.ReadServiceHourRequest} ReadServiceHourRequest
             */
            ReadServiceHourRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.http.read_service_hour.ReadServiceHourRequest)
                    return object;
                var message = new $root.http.read_service_hour.ReadServiceHourRequest();
                if (object.header != null) {
                    if (typeof object.header !== "object")
                        throw TypeError(".http.read_service_hour.ReadServiceHourRequest.header: object expected");
                    message.header = $root.core.header.RequestHeader.fromObject(object.header);
                }
                if (object.id != null)
                    message.id = object.id | 0;
                return message;
            };

            /**
             * Creates a plain object from a ReadServiceHourRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof http.read_service_hour.ReadServiceHourRequest
             * @static
             * @param {http.read_service_hour.ReadServiceHourRequest} message ReadServiceHourRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ReadServiceHourRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.header = null;
                    object.id = 0;
                }
                if (message.header != null && message.hasOwnProperty("header"))
                    object.header = $root.core.header.RequestHeader.toObject(message.header, options);
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                return object;
            };

            /**
             * Converts this ReadServiceHourRequest to JSON.
             * @function toJSON
             * @memberof http.read_service_hour.ReadServiceHourRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ReadServiceHourRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ReadServiceHourRequest
             * @function getTypeUrl
             * @memberof http.read_service_hour.ReadServiceHourRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ReadServiceHourRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/http.read_service_hour.ReadServiceHourRequest";
            };

            return ReadServiceHourRequest;
        })();

        read_service_hour.ReadServiceHourResponse = (function () {

            /**
             * Properties of a ReadServiceHourResponse.
             * @memberof http.read_service_hour
             * @interface IReadServiceHourResponse
             * @property {core.header.IResponseHeader|null} [header] ReadServiceHourResponse header
             * @property {model.service_hour.IServiceHour|null} [serviceHour] ReadServiceHourResponse serviceHour
             */

            /**
             * Constructs a new ReadServiceHourResponse.
             * @memberof http.read_service_hour
             * @classdesc Represents a ReadServiceHourResponse.
             * @implements IReadServiceHourResponse
             * @constructor
             * @param {http.read_service_hour.IReadServiceHourResponse=} [properties] Properties to set
             */
            function ReadServiceHourResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ReadServiceHourResponse header.
             * @member {core.header.IResponseHeader|null|undefined} header
             * @memberof http.read_service_hour.ReadServiceHourResponse
             * @instance
             */
            ReadServiceHourResponse.prototype.header = null;

            /**
             * ReadServiceHourResponse serviceHour.
             * @member {model.service_hour.IServiceHour|null|undefined} serviceHour
             * @memberof http.read_service_hour.ReadServiceHourResponse
             * @instance
             */
            ReadServiceHourResponse.prototype.serviceHour = null;

            /**
             * Creates a new ReadServiceHourResponse instance using the specified properties.
             * @function create
             * @memberof http.read_service_hour.ReadServiceHourResponse
             * @static
             * @param {http.read_service_hour.IReadServiceHourResponse=} [properties] Properties to set
             * @returns {http.read_service_hour.ReadServiceHourResponse} ReadServiceHourResponse instance
             */
            ReadServiceHourResponse.create = function create(properties) {
                return new ReadServiceHourResponse(properties);
            };

            /**
             * Encodes the specified ReadServiceHourResponse message. Does not implicitly {@link http.read_service_hour.ReadServiceHourResponse.verify|verify} messages.
             * @function encode
             * @memberof http.read_service_hour.ReadServiceHourResponse
             * @static
             * @param {http.read_service_hour.IReadServiceHourResponse} message ReadServiceHourResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReadServiceHourResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.header != null && Object.hasOwnProperty.call(message, "header"))
                    $root.core.header.ResponseHeader.encode(message.header, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.serviceHour != null && Object.hasOwnProperty.call(message, "serviceHour"))
                    $root.model.service_hour.ServiceHour.encode(message.serviceHour, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified ReadServiceHourResponse message, length delimited. Does not implicitly {@link http.read_service_hour.ReadServiceHourResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof http.read_service_hour.ReadServiceHourResponse
             * @static
             * @param {http.read_service_hour.IReadServiceHourResponse} message ReadServiceHourResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReadServiceHourResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ReadServiceHourResponse message from the specified reader or buffer.
             * @function decode
             * @memberof http.read_service_hour.ReadServiceHourResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {http.read_service_hour.ReadServiceHourResponse} ReadServiceHourResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReadServiceHourResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.http.read_service_hour.ReadServiceHourResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                        case 1: {
                            message.header = $root.core.header.ResponseHeader.decode(reader, reader.uint32());
                            break;
                        }
                        case 2: {
                            message.serviceHour = $root.model.service_hour.ServiceHour.decode(reader, reader.uint32());
                            break;
                        }
                        default:
                            reader.skipType(tag & 7);
                            break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ReadServiceHourResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof http.read_service_hour.ReadServiceHourResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {http.read_service_hour.ReadServiceHourResponse} ReadServiceHourResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReadServiceHourResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ReadServiceHourResponse message.
             * @function verify
             * @memberof http.read_service_hour.ReadServiceHourResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ReadServiceHourResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.header != null && message.hasOwnProperty("header")) {
                    var error = $root.core.header.ResponseHeader.verify(message.header);
                    if (error)
                        return "header." + error;
                }
                if (message.serviceHour != null && message.hasOwnProperty("serviceHour")) {
                    var error = $root.model.service_hour.ServiceHour.verify(message.serviceHour);
                    if (error)
                        return "serviceHour." + error;
                }
                return null;
            };

            /**
             * Creates a ReadServiceHourResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof http.read_service_hour.ReadServiceHourResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {http.read_service_hour.ReadServiceHourResponse} ReadServiceHourResponse
             */
            ReadServiceHourResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.http.read_service_hour.ReadServiceHourResponse)
                    return object;
                var message = new $root.http.read_service_hour.ReadServiceHourResponse();
                if (object.header != null) {
                    if (typeof object.header !== "object")
                        throw TypeError(".http.read_service_hour.ReadServiceHourResponse.header: object expected");
                    message.header = $root.core.header.ResponseHeader.fromObject(object.header);
                }
                if (object.serviceHour != null) {
                    if (typeof object.serviceHour !== "object")
                        throw TypeError(".http.read_service_hour.ReadServiceHourResponse.serviceHour: object expected");
                    message.serviceHour = $root.model.service_hour.ServiceHour.fromObject(object.serviceHour);
                }
                return message;
            };

            /**
             * Creates a plain object from a ReadServiceHourResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof http.read_service_hour.ReadServiceHourResponse
             * @static
             * @param {http.read_service_hour.ReadServiceHourResponse} message ReadServiceHourResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ReadServiceHourResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.header = null;
                    object.serviceHour = null;
                }
                if (message.header != null && message.hasOwnProperty("header"))
                    object.header = $root.core.header.ResponseHeader.toObject(message.header, options);
                if (message.serviceHour != null && message.hasOwnProperty("serviceHour"))
                    object.serviceHour = $root.model.service_hour.ServiceHour.toObject(message.serviceHour, options);
                return object;
            };

            /**
             * Converts this ReadServiceHourResponse to JSON.
             * @function toJSON
             * @memberof http.read_service_hour.ReadServiceHourResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ReadServiceHourResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ReadServiceHourResponse
             * @function getTypeUrl
             * @memberof http.read_service_hour.ReadServiceHourResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ReadServiceHourResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/http.read_service_hour.ReadServiceHourResponse";
            };

            return ReadServiceHourResponse;
        })();

        return read_service_hour;
    })();

    http.read_service_hours = (function () {

        /**
         * Namespace read_service_hours.
         * @memberof http
         * @namespace
         */
        var read_service_hours = {};

        read_service_hours.ReadServiceHoursRequest = (function () {

            /**
             * Properties of a ReadServiceHoursRequest.
             * @memberof http.read_service_hours
             * @interface IReadServiceHoursRequest
             * @property {core.header.IRequestHeader|null} [header] ReadServiceHoursRequest header
             * @property {boolean|null} [useSession] ReadServiceHoursRequest useSession
             * @property {string|null} [username] ReadServiceHoursRequest username
             * @property {string|null} [searchText] ReadServiceHoursRequest searchText
             * @property {model.service_review.ServiceReviewStatus|null} [reviewStatus] ReadServiceHoursRequest reviewStatus
             * @property {core.pagination.IPaginationRequest|null} [pagination] ReadServiceHoursRequest pagination
             */

            /**
             * Constructs a new ReadServiceHoursRequest.
             * @memberof http.read_service_hours
             * @classdesc Represents a ReadServiceHoursRequest.
             * @implements IReadServiceHoursRequest
             * @constructor
             * @param {http.read_service_hours.IReadServiceHoursRequest=} [properties] Properties to set
             */
            function ReadServiceHoursRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ReadServiceHoursRequest header.
             * @member {core.header.IRequestHeader|null|undefined} header
             * @memberof http.read_service_hours.ReadServiceHoursRequest
             * @instance
             */
            ReadServiceHoursRequest.prototype.header = null;

            /**
             * ReadServiceHoursRequest useSession.
             * @member {boolean} useSession
             * @memberof http.read_service_hours.ReadServiceHoursRequest
             * @instance
             */
            ReadServiceHoursRequest.prototype.useSession = false;

            /**
             * ReadServiceHoursRequest username.
             * @member {string} username
             * @memberof http.read_service_hours.ReadServiceHoursRequest
             * @instance
             */
            ReadServiceHoursRequest.prototype.username = "";

            /**
             * ReadServiceHoursRequest searchText.
             * @member {string} searchText
             * @memberof http.read_service_hours.ReadServiceHoursRequest
             * @instance
             */
            ReadServiceHoursRequest.prototype.searchText = "";

            /**
             * ReadServiceHoursRequest reviewStatus.
             * @member {model.service_review.ServiceReviewStatus} reviewStatus
             * @memberof http.read_service_hours.ReadServiceHoursRequest
             * @instance
             */
            ReadServiceHoursRequest.prototype.reviewStatus = 0;

            /**
             * ReadServiceHoursRequest pagination.
             * @member {core.pagination.IPaginationRequest|null|undefined} pagination
             * @memberof http.read_service_hours.ReadServiceHoursRequest
             * @instance
             */
            ReadServiceHoursRequest.prototype.pagination = null;

            /**
             * Creates a new ReadServiceHoursRequest instance using the specified properties.
             * @function create
             * @memberof http.read_service_hours.ReadServiceHoursRequest
             * @static
             * @param {http.read_service_hours.IReadServiceHoursRequest=} [properties] Properties to set
             * @returns {http.read_service_hours.ReadServiceHoursRequest} ReadServiceHoursRequest instance
             */
            ReadServiceHoursRequest.create = function create(properties) {
                return new ReadServiceHoursRequest(properties);
            };

            /**
             * Encodes the specified ReadServiceHoursRequest message. Does not implicitly {@link http.read_service_hours.ReadServiceHoursRequest.verify|verify} messages.
             * @function encode
             * @memberof http.read_service_hours.ReadServiceHoursRequest
             * @static
             * @param {http.read_service_hours.IReadServiceHoursRequest} message ReadServiceHoursRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReadServiceHoursRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.header != null && Object.hasOwnProperty.call(message, "header"))
                    $root.core.header.RequestHeader.encode(message.header, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.useSession != null && Object.hasOwnProperty.call(message, "useSession"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.useSession);
                if (message.username != null && Object.hasOwnProperty.call(message, "username"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.username);
                if (message.searchText != null && Object.hasOwnProperty.call(message, "searchText"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.searchText);
                if (message.reviewStatus != null && Object.hasOwnProperty.call(message, "reviewStatus"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.reviewStatus);
                if (message.pagination != null && Object.hasOwnProperty.call(message, "pagination"))
                    $root.core.pagination.PaginationRequest.encode(message.pagination, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified ReadServiceHoursRequest message, length delimited. Does not implicitly {@link http.read_service_hours.ReadServiceHoursRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof http.read_service_hours.ReadServiceHoursRequest
             * @static
             * @param {http.read_service_hours.IReadServiceHoursRequest} message ReadServiceHoursRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReadServiceHoursRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ReadServiceHoursRequest message from the specified reader or buffer.
             * @function decode
             * @memberof http.read_service_hours.ReadServiceHoursRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {http.read_service_hours.ReadServiceHoursRequest} ReadServiceHoursRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReadServiceHoursRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.http.read_service_hours.ReadServiceHoursRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                        case 1: {
                            message.header = $root.core.header.RequestHeader.decode(reader, reader.uint32());
                            break;
                        }
                        case 2: {
                            message.useSession = reader.bool();
                            break;
                        }
                        case 3: {
                            message.username = reader.string();
                            break;
                        }
                        case 4: {
                            message.searchText = reader.string();
                            break;
                        }
                        case 5: {
                            message.reviewStatus = reader.int32();
                            break;
                        }
                        case 6: {
                            message.pagination = $root.core.pagination.PaginationRequest.decode(reader, reader.uint32());
                            break;
                        }
                        default:
                            reader.skipType(tag & 7);
                            break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ReadServiceHoursRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof http.read_service_hours.ReadServiceHoursRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {http.read_service_hours.ReadServiceHoursRequest} ReadServiceHoursRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReadServiceHoursRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ReadServiceHoursRequest message.
             * @function verify
             * @memberof http.read_service_hours.ReadServiceHoursRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ReadServiceHoursRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.header != null && message.hasOwnProperty("header")) {
                    var error = $root.core.header.RequestHeader.verify(message.header);
                    if (error)
                        return "header." + error;
                }
                if (message.useSession != null && message.hasOwnProperty("useSession"))
                    if (typeof message.useSession !== "boolean")
                        return "useSession: boolean expected";
                if (message.username != null && message.hasOwnProperty("username"))
                    if (!$util.isString(message.username))
                        return "username: string expected";
                if (message.searchText != null && message.hasOwnProperty("searchText"))
                    if (!$util.isString(message.searchText))
                        return "searchText: string expected";
                if (message.reviewStatus != null && message.hasOwnProperty("reviewStatus"))
                    switch (message.reviewStatus) {
                        default:
                            return "reviewStatus: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                    }
                if (message.pagination != null && message.hasOwnProperty("pagination")) {
                    var error = $root.core.pagination.PaginationRequest.verify(message.pagination);
                    if (error)
                        return "pagination." + error;
                }
                return null;
            };

            /**
             * Creates a ReadServiceHoursRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof http.read_service_hours.ReadServiceHoursRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {http.read_service_hours.ReadServiceHoursRequest} ReadServiceHoursRequest
             */
            ReadServiceHoursRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.http.read_service_hours.ReadServiceHoursRequest)
                    return object;
                var message = new $root.http.read_service_hours.ReadServiceHoursRequest();
                if (object.header != null) {
                    if (typeof object.header !== "object")
                        throw TypeError(".http.read_service_hours.ReadServiceHoursRequest.header: object expected");
                    message.header = $root.core.header.RequestHeader.fromObject(object.header);
                }
                if (object.useSession != null)
                    message.useSession = Boolean(object.useSession);
                if (object.username != null)
                    message.username = String(object.username);
                if (object.searchText != null)
                    message.searchText = String(object.searchText);
                switch (object.reviewStatus) {
                    default:
                        if (typeof object.reviewStatus === "number") {
                            message.reviewStatus = object.reviewStatus;
                            break;
                        }
                        break;
                    case "UNKNOWN_STATUS":
                    case 0:
                        message.reviewStatus = 0;
                        break;
                    case "PENDING":
                    case 1:
                        message.reviewStatus = 1;
                        break;
                    case "APPROVED":
                    case 2:
                        message.reviewStatus = 2;
                        break;
                    case "REJECTED":
                    case 3:
                        message.reviewStatus = 3;
                        break;
                }
                if (object.pagination != null) {
                    if (typeof object.pagination !== "object")
                        throw TypeError(".http.read_service_hours.ReadServiceHoursRequest.pagination: object expected");
                    message.pagination = $root.core.pagination.PaginationRequest.fromObject(object.pagination);
                }
                return message;
            };

            /**
             * Creates a plain object from a ReadServiceHoursRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof http.read_service_hours.ReadServiceHoursRequest
             * @static
             * @param {http.read_service_hours.ReadServiceHoursRequest} message ReadServiceHoursRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ReadServiceHoursRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.header = null;
                    object.useSession = false;
                    object.username = "";
                    object.searchText = "";
                    object.reviewStatus = options.enums === String ? "UNKNOWN_STATUS" : 0;
                    object.pagination = null;
                }
                if (message.header != null && message.hasOwnProperty("header"))
                    object.header = $root.core.header.RequestHeader.toObject(message.header, options);
                if (message.useSession != null && message.hasOwnProperty("useSession"))
                    object.useSession = message.useSession;
                if (message.username != null && message.hasOwnProperty("username"))
                    object.username = message.username;
                if (message.searchText != null && message.hasOwnProperty("searchText"))
                    object.searchText = message.searchText;
                if (message.reviewStatus != null && message.hasOwnProperty("reviewStatus"))
                    object.reviewStatus = options.enums === String ? $root.model.service_review.ServiceReviewStatus[message.reviewStatus] === undefined ? message.reviewStatus : $root.model.service_review.ServiceReviewStatus[message.reviewStatus] : message.reviewStatus;
                if (message.pagination != null && message.hasOwnProperty("pagination"))
                    object.pagination = $root.core.pagination.PaginationRequest.toObject(message.pagination, options);
                return object;
            };

            /**
             * Converts this ReadServiceHoursRequest to JSON.
             * @function toJSON
             * @memberof http.read_service_hours.ReadServiceHoursRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ReadServiceHoursRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ReadServiceHoursRequest
             * @function getTypeUrl
             * @memberof http.read_service_hours.ReadServiceHoursRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ReadServiceHoursRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/http.read_service_hours.ReadServiceHoursRequest";
            };

            return ReadServiceHoursRequest;
        })();

        read_service_hours.ReadServiceHoursResponse = (function () {

            /**
             * Properties of a ReadServiceHoursResponse.
             * @memberof http.read_service_hours
             * @interface IReadServiceHoursResponse
             * @property {core.header.IResponseHeader|null} [header] ReadServiceHoursResponse header
             * @property {Array.<model.service_hour.IServiceHour>|null} [serviceHours] ReadServiceHoursResponse serviceHours
             * @property {core.pagination.IPaginationResponse|null} [pagination] ReadServiceHoursResponse pagination
             */

            /**
             * Constructs a new ReadServiceHoursResponse.
             * @memberof http.read_service_hours
             * @classdesc Represents a ReadServiceHoursResponse.
             * @implements IReadServiceHoursResponse
             * @constructor
             * @param {http.read_service_hours.IReadServiceHoursResponse=} [properties] Properties to set
             */
            function ReadServiceHoursResponse(properties) {
                this.serviceHours = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ReadServiceHoursResponse header.
             * @member {core.header.IResponseHeader|null|undefined} header
             * @memberof http.read_service_hours.ReadServiceHoursResponse
             * @instance
             */
            ReadServiceHoursResponse.prototype.header = null;

            /**
             * ReadServiceHoursResponse serviceHours.
             * @member {Array.<model.service_hour.IServiceHour>} serviceHours
             * @memberof http.read_service_hours.ReadServiceHoursResponse
             * @instance
             */
            ReadServiceHoursResponse.prototype.serviceHours = $util.emptyArray;

            /**
             * ReadServiceHoursResponse pagination.
             * @member {core.pagination.IPaginationResponse|null|undefined} pagination
             * @memberof http.read_service_hours.ReadServiceHoursResponse
             * @instance
             */
            ReadServiceHoursResponse.prototype.pagination = null;

            /**
             * Creates a new ReadServiceHoursResponse instance using the specified properties.
             * @function create
             * @memberof http.read_service_hours.ReadServiceHoursResponse
             * @static
             * @param {http.read_service_hours.IReadServiceHoursResponse=} [properties] Properties to set
             * @returns {http.read_service_hours.ReadServiceHoursResponse} ReadServiceHoursResponse instance
             */
            ReadServiceHoursResponse.create = function create(properties) {
                return new ReadServiceHoursResponse(properties);
            };

            /**
             * Encodes the specified ReadServiceHoursResponse message. Does not implicitly {@link http.read_service_hours.ReadServiceHoursResponse.verify|verify} messages.
             * @function encode
             * @memberof http.read_service_hours.ReadServiceHoursResponse
             * @static
             * @param {http.read_service_hours.IReadServiceHoursResponse} message ReadServiceHoursResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReadServiceHoursResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.header != null && Object.hasOwnProperty.call(message, "header"))
                    $root.core.header.ResponseHeader.encode(message.header, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.serviceHours != null && message.serviceHours.length)
                    for (var i = 0; i < message.serviceHours.length; ++i)
                        $root.model.service_hour.ServiceHour.encode(message.serviceHours[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.pagination != null && Object.hasOwnProperty.call(message, "pagination"))
                    $root.core.pagination.PaginationResponse.encode(message.pagination, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified ReadServiceHoursResponse message, length delimited. Does not implicitly {@link http.read_service_hours.ReadServiceHoursResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof http.read_service_hours.ReadServiceHoursResponse
             * @static
             * @param {http.read_service_hours.IReadServiceHoursResponse} message ReadServiceHoursResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReadServiceHoursResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ReadServiceHoursResponse message from the specified reader or buffer.
             * @function decode
             * @memberof http.read_service_hours.ReadServiceHoursResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {http.read_service_hours.ReadServiceHoursResponse} ReadServiceHoursResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReadServiceHoursResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.http.read_service_hours.ReadServiceHoursResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                        case 1: {
                            message.header = $root.core.header.ResponseHeader.decode(reader, reader.uint32());
                            break;
                        }
                        case 2: {
                            if (!(message.serviceHours && message.serviceHours.length))
                                message.serviceHours = [];
                            message.serviceHours.push($root.model.service_hour.ServiceHour.decode(reader, reader.uint32()));
                            break;
                        }
                        case 3: {
                            message.pagination = $root.core.pagination.PaginationResponse.decode(reader, reader.uint32());
                            break;
                        }
                        default:
                            reader.skipType(tag & 7);
                            break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ReadServiceHoursResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof http.read_service_hours.ReadServiceHoursResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {http.read_service_hours.ReadServiceHoursResponse} ReadServiceHoursResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReadServiceHoursResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ReadServiceHoursResponse message.
             * @function verify
             * @memberof http.read_service_hours.ReadServiceHoursResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ReadServiceHoursResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.header != null && message.hasOwnProperty("header")) {
                    var error = $root.core.header.ResponseHeader.verify(message.header);
                    if (error)
                        return "header." + error;
                }
                if (message.serviceHours != null && message.hasOwnProperty("serviceHours")) {
                    if (!Array.isArray(message.serviceHours))
                        return "serviceHours: array expected";
                    for (var i = 0; i < message.serviceHours.length; ++i) {
                        var error = $root.model.service_hour.ServiceHour.verify(message.serviceHours[i]);
                        if (error)
                            return "serviceHours." + error;
                    }
                }
                if (message.pagination != null && message.hasOwnProperty("pagination")) {
                    var error = $root.core.pagination.PaginationResponse.verify(message.pagination);
                    if (error)
                        return "pagination." + error;
                }
                return null;
            };

            /**
             * Creates a ReadServiceHoursResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof http.read_service_hours.ReadServiceHoursResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {http.read_service_hours.ReadServiceHoursResponse} ReadServiceHoursResponse
             */
            ReadServiceHoursResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.http.read_service_hours.ReadServiceHoursResponse)
                    return object;
                var message = new $root.http.read_service_hours.ReadServiceHoursResponse();
                if (object.header != null) {
                    if (typeof object.header !== "object")
                        throw TypeError(".http.read_service_hours.ReadServiceHoursResponse.header: object expected");
                    message.header = $root.core.header.ResponseHeader.fromObject(object.header);
                }
                if (object.serviceHours) {
                    if (!Array.isArray(object.serviceHours))
                        throw TypeError(".http.read_service_hours.ReadServiceHoursResponse.serviceHours: array expected");
                    message.serviceHours = [];
                    for (var i = 0; i < object.serviceHours.length; ++i) {
                        if (typeof object.serviceHours[i] !== "object")
                            throw TypeError(".http.read_service_hours.ReadServiceHoursResponse.serviceHours: object expected");
                        message.serviceHours[i] = $root.model.service_hour.ServiceHour.fromObject(object.serviceHours[i]);
                    }
                }
                if (object.pagination != null) {
                    if (typeof object.pagination !== "object")
                        throw TypeError(".http.read_service_hours.ReadServiceHoursResponse.pagination: object expected");
                    message.pagination = $root.core.pagination.PaginationResponse.fromObject(object.pagination);
                }
                return message;
            };

            /**
             * Creates a plain object from a ReadServiceHoursResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof http.read_service_hours.ReadServiceHoursResponse
             * @static
             * @param {http.read_service_hours.ReadServiceHoursResponse} message ReadServiceHoursResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ReadServiceHoursResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.serviceHours = [];
                if (options.defaults) {
                    object.header = null;
                    object.pagination = null;
                }
                if (message.header != null && message.hasOwnProperty("header"))
                    object.header = $root.core.header.ResponseHeader.toObject(message.header, options);
                if (message.serviceHours && message.serviceHours.length) {
                    object.serviceHours = [];
                    for (var j = 0; j < message.serviceHours.length; ++j)
                        object.serviceHours[j] = $root.model.service_hour.ServiceHour.toObject(message.serviceHours[j], options);
                }
                if (message.pagination != null && message.hasOwnProperty("pagination"))
                    object.pagination = $root.core.pagination.PaginationResponse.toObject(message.pagination, options);
                return object;
            };

            /**
             * Converts this ReadServiceHoursResponse to JSON.
             * @function toJSON
             * @memberof http.read_service_hours.ReadServiceHoursResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ReadServiceHoursResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ReadServiceHoursResponse
             * @function getTypeUrl
             * @memberof http.read_service_hours.ReadServiceHoursResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ReadServiceHoursResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/http.read_service_hours.ReadServiceHoursResponse";
            };

            return ReadServiceHoursResponse;
        })();

        return read_service_hours;
    })();

    http.read_user = (function () {

        /**
         * Namespace read_user.
         * @memberof http
         * @namespace
         */
        var read_user = {};

        read_user.ReadUserRequest = (function () {

            /**
             * Properties of a ReadUserRequest.
             * @memberof http.read_user
             * @interface IReadUserRequest
             * @property {core.header.IRequestHeader|null} [header] ReadUserRequest header
             */

            /**
             * Constructs a new ReadUserRequest.
             * @memberof http.read_user
             * @classdesc Represents a ReadUserRequest.
             * @implements IReadUserRequest
             * @constructor
             * @param {http.read_user.IReadUserRequest=} [properties] Properties to set
             */
            function ReadUserRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ReadUserRequest header.
             * @member {core.header.IRequestHeader|null|undefined} header
             * @memberof http.read_user.ReadUserRequest
             * @instance
             */
            ReadUserRequest.prototype.header = null;

            /**
             * Creates a new ReadUserRequest instance using the specified properties.
             * @function create
             * @memberof http.read_user.ReadUserRequest
             * @static
             * @param {http.read_user.IReadUserRequest=} [properties] Properties to set
             * @returns {http.read_user.ReadUserRequest} ReadUserRequest instance
             */
            ReadUserRequest.create = function create(properties) {
                return new ReadUserRequest(properties);
            };

            /**
             * Encodes the specified ReadUserRequest message. Does not implicitly {@link http.read_user.ReadUserRequest.verify|verify} messages.
             * @function encode
             * @memberof http.read_user.ReadUserRequest
             * @static
             * @param {http.read_user.IReadUserRequest} message ReadUserRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReadUserRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.header != null && Object.hasOwnProperty.call(message, "header"))
                    $root.core.header.RequestHeader.encode(message.header, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified ReadUserRequest message, length delimited. Does not implicitly {@link http.read_user.ReadUserRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof http.read_user.ReadUserRequest
             * @static
             * @param {http.read_user.IReadUserRequest} message ReadUserRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReadUserRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ReadUserRequest message from the specified reader or buffer.
             * @function decode
             * @memberof http.read_user.ReadUserRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {http.read_user.ReadUserRequest} ReadUserRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReadUserRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.http.read_user.ReadUserRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                        case 1: {
                            message.header = $root.core.header.RequestHeader.decode(reader, reader.uint32());
                            break;
                        }
                        default:
                            reader.skipType(tag & 7);
                            break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ReadUserRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof http.read_user.ReadUserRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {http.read_user.ReadUserRequest} ReadUserRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReadUserRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ReadUserRequest message.
             * @function verify
             * @memberof http.read_user.ReadUserRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ReadUserRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.header != null && message.hasOwnProperty("header")) {
                    var error = $root.core.header.RequestHeader.verify(message.header);
                    if (error)
                        return "header." + error;
                }
                return null;
            };

            /**
             * Creates a ReadUserRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof http.read_user.ReadUserRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {http.read_user.ReadUserRequest} ReadUserRequest
             */
            ReadUserRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.http.read_user.ReadUserRequest)
                    return object;
                var message = new $root.http.read_user.ReadUserRequest();
                if (object.header != null) {
                    if (typeof object.header !== "object")
                        throw TypeError(".http.read_user.ReadUserRequest.header: object expected");
                    message.header = $root.core.header.RequestHeader.fromObject(object.header);
                }
                return message;
            };

            /**
             * Creates a plain object from a ReadUserRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof http.read_user.ReadUserRequest
             * @static
             * @param {http.read_user.ReadUserRequest} message ReadUserRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ReadUserRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.header = null;
                if (message.header != null && message.hasOwnProperty("header"))
                    object.header = $root.core.header.RequestHeader.toObject(message.header, options);
                return object;
            };

            /**
             * Converts this ReadUserRequest to JSON.
             * @function toJSON
             * @memberof http.read_user.ReadUserRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ReadUserRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ReadUserRequest
             * @function getTypeUrl
             * @memberof http.read_user.ReadUserRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ReadUserRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/http.read_user.ReadUserRequest";
            };

            return ReadUserRequest;
        })();

        read_user.ReadUserResponse = (function () {

            /**
             * Properties of a ReadUserResponse.
             * @memberof http.read_user
             * @interface IReadUserResponse
             * @property {core.header.IResponseHeader|null} [header] ReadUserResponse header
             * @property {core.account.IUser|null} [user] ReadUserResponse user
             */

            /**
             * Constructs a new ReadUserResponse.
             * @memberof http.read_user
             * @classdesc Represents a ReadUserResponse.
             * @implements IReadUserResponse
             * @constructor
             * @param {http.read_user.IReadUserResponse=} [properties] Properties to set
             */
            function ReadUserResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ReadUserResponse header.
             * @member {core.header.IResponseHeader|null|undefined} header
             * @memberof http.read_user.ReadUserResponse
             * @instance
             */
            ReadUserResponse.prototype.header = null;

            /**
             * ReadUserResponse user.
             * @member {core.account.IUser|null|undefined} user
             * @memberof http.read_user.ReadUserResponse
             * @instance
             */
            ReadUserResponse.prototype.user = null;

            /**
             * Creates a new ReadUserResponse instance using the specified properties.
             * @function create
             * @memberof http.read_user.ReadUserResponse
             * @static
             * @param {http.read_user.IReadUserResponse=} [properties] Properties to set
             * @returns {http.read_user.ReadUserResponse} ReadUserResponse instance
             */
            ReadUserResponse.create = function create(properties) {
                return new ReadUserResponse(properties);
            };

            /**
             * Encodes the specified ReadUserResponse message. Does not implicitly {@link http.read_user.ReadUserResponse.verify|verify} messages.
             * @function encode
             * @memberof http.read_user.ReadUserResponse
             * @static
             * @param {http.read_user.IReadUserResponse} message ReadUserResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReadUserResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.header != null && Object.hasOwnProperty.call(message, "header"))
                    $root.core.header.ResponseHeader.encode(message.header, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                    $root.core.account.User.encode(message.user, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified ReadUserResponse message, length delimited. Does not implicitly {@link http.read_user.ReadUserResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof http.read_user.ReadUserResponse
             * @static
             * @param {http.read_user.IReadUserResponse} message ReadUserResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReadUserResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ReadUserResponse message from the specified reader or buffer.
             * @function decode
             * @memberof http.read_user.ReadUserResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {http.read_user.ReadUserResponse} ReadUserResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReadUserResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.http.read_user.ReadUserResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                        case 1: {
                            message.header = $root.core.header.ResponseHeader.decode(reader, reader.uint32());
                            break;
                        }
                        case 2: {
                            message.user = $root.core.account.User.decode(reader, reader.uint32());
                            break;
                        }
                        default:
                            reader.skipType(tag & 7);
                            break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ReadUserResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof http.read_user.ReadUserResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {http.read_user.ReadUserResponse} ReadUserResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReadUserResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ReadUserResponse message.
             * @function verify
             * @memberof http.read_user.ReadUserResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ReadUserResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.header != null && message.hasOwnProperty("header")) {
                    var error = $root.core.header.ResponseHeader.verify(message.header);
                    if (error)
                        return "header." + error;
                }
                if (message.user != null && message.hasOwnProperty("user")) {
                    var error = $root.core.account.User.verify(message.user);
                    if (error)
                        return "user." + error;
                }
                return null;
            };

            /**
             * Creates a ReadUserResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof http.read_user.ReadUserResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {http.read_user.ReadUserResponse} ReadUserResponse
             */
            ReadUserResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.http.read_user.ReadUserResponse)
                    return object;
                var message = new $root.http.read_user.ReadUserResponse();
                if (object.header != null) {
                    if (typeof object.header !== "object")
                        throw TypeError(".http.read_user.ReadUserResponse.header: object expected");
                    message.header = $root.core.header.ResponseHeader.fromObject(object.header);
                }
                if (object.user != null) {
                    if (typeof object.user !== "object")
                        throw TypeError(".http.read_user.ReadUserResponse.user: object expected");
                    message.user = $root.core.account.User.fromObject(object.user);
                }
                return message;
            };

            /**
             * Creates a plain object from a ReadUserResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof http.read_user.ReadUserResponse
             * @static
             * @param {http.read_user.ReadUserResponse} message ReadUserResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ReadUserResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.header = null;
                    object.user = null;
                }
                if (message.header != null && message.hasOwnProperty("header"))
                    object.header = $root.core.header.ResponseHeader.toObject(message.header, options);
                if (message.user != null && message.hasOwnProperty("user"))
                    object.user = $root.core.account.User.toObject(message.user, options);
                return object;
            };

            /**
             * Converts this ReadUserResponse to JSON.
             * @function toJSON
             * @memberof http.read_user.ReadUserResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ReadUserResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ReadUserResponse
             * @function getTypeUrl
             * @memberof http.read_user.ReadUserResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ReadUserResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/http.read_user.ReadUserResponse";
            };

            return ReadUserResponse;
        })();

        return read_user;
    })();

    http.write_service_hour = (function () {

        /**
         * Namespace write_service_hour.
         * @memberof http
         * @namespace
         */
        var write_service_hour = {};

        write_service_hour.WriteServiceHourRequest = (function () {

            /**
             * Properties of a WriteServiceHourRequest.
             * @memberof http.write_service_hour
             * @interface IWriteServiceHourRequest
             * @property {core.header.IRequestHeader|null} [header] WriteServiceHourRequest header
             * @property {model.service_hour.IServiceHour|null} [serviceHour] WriteServiceHourRequest serviceHour
             */

            /**
             * Constructs a new WriteServiceHourRequest.
             * @memberof http.write_service_hour
             * @classdesc Represents a WriteServiceHourRequest.
             * @implements IWriteServiceHourRequest
             * @constructor
             * @param {http.write_service_hour.IWriteServiceHourRequest=} [properties] Properties to set
             */
            function WriteServiceHourRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WriteServiceHourRequest header.
             * @member {core.header.IRequestHeader|null|undefined} header
             * @memberof http.write_service_hour.WriteServiceHourRequest
             * @instance
             */
            WriteServiceHourRequest.prototype.header = null;

            /**
             * WriteServiceHourRequest serviceHour.
             * @member {model.service_hour.IServiceHour|null|undefined} serviceHour
             * @memberof http.write_service_hour.WriteServiceHourRequest
             * @instance
             */
            WriteServiceHourRequest.prototype.serviceHour = null;

            /**
             * Creates a new WriteServiceHourRequest instance using the specified properties.
             * @function create
             * @memberof http.write_service_hour.WriteServiceHourRequest
             * @static
             * @param {http.write_service_hour.IWriteServiceHourRequest=} [properties] Properties to set
             * @returns {http.write_service_hour.WriteServiceHourRequest} WriteServiceHourRequest instance
             */
            WriteServiceHourRequest.create = function create(properties) {
                return new WriteServiceHourRequest(properties);
            };

            /**
             * Encodes the specified WriteServiceHourRequest message. Does not implicitly {@link http.write_service_hour.WriteServiceHourRequest.verify|verify} messages.
             * @function encode
             * @memberof http.write_service_hour.WriteServiceHourRequest
             * @static
             * @param {http.write_service_hour.IWriteServiceHourRequest} message WriteServiceHourRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WriteServiceHourRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.header != null && Object.hasOwnProperty.call(message, "header"))
                    $root.core.header.RequestHeader.encode(message.header, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.serviceHour != null && Object.hasOwnProperty.call(message, "serviceHour"))
                    $root.model.service_hour.ServiceHour.encode(message.serviceHour, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified WriteServiceHourRequest message, length delimited. Does not implicitly {@link http.write_service_hour.WriteServiceHourRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof http.write_service_hour.WriteServiceHourRequest
             * @static
             * @param {http.write_service_hour.IWriteServiceHourRequest} message WriteServiceHourRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WriteServiceHourRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a WriteServiceHourRequest message from the specified reader or buffer.
             * @function decode
             * @memberof http.write_service_hour.WriteServiceHourRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {http.write_service_hour.WriteServiceHourRequest} WriteServiceHourRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WriteServiceHourRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.http.write_service_hour.WriteServiceHourRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                        case 1: {
                            message.header = $root.core.header.RequestHeader.decode(reader, reader.uint32());
                            break;
                        }
                        case 2: {
                            message.serviceHour = $root.model.service_hour.ServiceHour.decode(reader, reader.uint32());
                            break;
                        }
                        default:
                            reader.skipType(tag & 7);
                            break;
                    }
                }
                return message;
            };

            /**
             * Decodes a WriteServiceHourRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof http.write_service_hour.WriteServiceHourRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {http.write_service_hour.WriteServiceHourRequest} WriteServiceHourRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WriteServiceHourRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a WriteServiceHourRequest message.
             * @function verify
             * @memberof http.write_service_hour.WriteServiceHourRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WriteServiceHourRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.header != null && message.hasOwnProperty("header")) {
                    var error = $root.core.header.RequestHeader.verify(message.header);
                    if (error)
                        return "header." + error;
                }
                if (message.serviceHour != null && message.hasOwnProperty("serviceHour")) {
                    var error = $root.model.service_hour.ServiceHour.verify(message.serviceHour);
                    if (error)
                        return "serviceHour." + error;
                }
                return null;
            };

            /**
             * Creates a WriteServiceHourRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof http.write_service_hour.WriteServiceHourRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {http.write_service_hour.WriteServiceHourRequest} WriteServiceHourRequest
             */
            WriteServiceHourRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.http.write_service_hour.WriteServiceHourRequest)
                    return object;
                var message = new $root.http.write_service_hour.WriteServiceHourRequest();
                if (object.header != null) {
                    if (typeof object.header !== "object")
                        throw TypeError(".http.write_service_hour.WriteServiceHourRequest.header: object expected");
                    message.header = $root.core.header.RequestHeader.fromObject(object.header);
                }
                if (object.serviceHour != null) {
                    if (typeof object.serviceHour !== "object")
                        throw TypeError(".http.write_service_hour.WriteServiceHourRequest.serviceHour: object expected");
                    message.serviceHour = $root.model.service_hour.ServiceHour.fromObject(object.serviceHour);
                }
                // if (message.serviceHour.serviceDate)
                return message;
            };

            /**
             * Creates a plain object from a WriteServiceHourRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof http.write_service_hour.WriteServiceHourRequest
             * @static
             * @param {http.write_service_hour.WriteServiceHourRequest} message WriteServiceHourRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WriteServiceHourRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.header = null;
                    object.serviceHour = null;
                }
                if (message.header != null && message.hasOwnProperty("header"))
                    object.header = $root.core.header.RequestHeader.toObject(message.header, options);
                if (message.serviceHour != null && message.hasOwnProperty("serviceHour"))
                    object.serviceHour = $root.model.service_hour.ServiceHour.toObject(message.serviceHour, options);
                return object;
            };

            /**
             * Converts this WriteServiceHourRequest to JSON.
             * @function toJSON
             * @memberof http.write_service_hour.WriteServiceHourRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WriteServiceHourRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for WriteServiceHourRequest
             * @function getTypeUrl
             * @memberof http.write_service_hour.WriteServiceHourRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            WriteServiceHourRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/http.write_service_hour.WriteServiceHourRequest";
            };

            return WriteServiceHourRequest;
        })();

        write_service_hour.WriteServiceHourResponse = (function () {

            /**
             * Properties of a WriteServiceHourResponse.
             * @memberof http.write_service_hour
             * @interface IWriteServiceHourResponse
             * @property {core.header.IResponseHeader|null} [header] WriteServiceHourResponse header
             */

            /**
             * Constructs a new WriteServiceHourResponse.
             * @memberof http.write_service_hour
             * @classdesc Represents a WriteServiceHourResponse.
             * @implements IWriteServiceHourResponse
             * @constructor
             * @param {http.write_service_hour.IWriteServiceHourResponse=} [properties] Properties to set
             */
            function WriteServiceHourResponse(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WriteServiceHourResponse header.
             * @member {core.header.IResponseHeader|null|undefined} header
             * @memberof http.write_service_hour.WriteServiceHourResponse
             * @instance
             */
            WriteServiceHourResponse.prototype.header = null;

            /**
             * Creates a new WriteServiceHourResponse instance using the specified properties.
             * @function create
             * @memberof http.write_service_hour.WriteServiceHourResponse
             * @static
             * @param {http.write_service_hour.IWriteServiceHourResponse=} [properties] Properties to set
             * @returns {http.write_service_hour.WriteServiceHourResponse} WriteServiceHourResponse instance
             */
            WriteServiceHourResponse.create = function create(properties) {
                return new WriteServiceHourResponse(properties);
            };

            /**
             * Encodes the specified WriteServiceHourResponse message. Does not implicitly {@link http.write_service_hour.WriteServiceHourResponse.verify|verify} messages.
             * @function encode
             * @memberof http.write_service_hour.WriteServiceHourResponse
             * @static
             * @param {http.write_service_hour.IWriteServiceHourResponse} message WriteServiceHourResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WriteServiceHourResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.header != null && Object.hasOwnProperty.call(message, "header"))
                    $root.core.header.ResponseHeader.encode(message.header, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified WriteServiceHourResponse message, length delimited. Does not implicitly {@link http.write_service_hour.WriteServiceHourResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof http.write_service_hour.WriteServiceHourResponse
             * @static
             * @param {http.write_service_hour.IWriteServiceHourResponse} message WriteServiceHourResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WriteServiceHourResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a WriteServiceHourResponse message from the specified reader or buffer.
             * @function decode
             * @memberof http.write_service_hour.WriteServiceHourResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {http.write_service_hour.WriteServiceHourResponse} WriteServiceHourResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WriteServiceHourResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.http.write_service_hour.WriteServiceHourResponse();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                        case 1: {
                            message.header = $root.core.header.ResponseHeader.decode(reader, reader.uint32());
                            break;
                        }
                        default:
                            reader.skipType(tag & 7);
                            break;
                    }
                }
                return message;
            };

            /**
             * Decodes a WriteServiceHourResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof http.write_service_hour.WriteServiceHourResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {http.write_service_hour.WriteServiceHourResponse} WriteServiceHourResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WriteServiceHourResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a WriteServiceHourResponse message.
             * @function verify
             * @memberof http.write_service_hour.WriteServiceHourResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WriteServiceHourResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.header != null && message.hasOwnProperty("header")) {
                    var error = $root.core.header.ResponseHeader.verify(message.header);
                    if (error)
                        return "header." + error;
                }
                return null;
            };

            /**
             * Creates a WriteServiceHourResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof http.write_service_hour.WriteServiceHourResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {http.write_service_hour.WriteServiceHourResponse} WriteServiceHourResponse
             */
            WriteServiceHourResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.http.write_service_hour.WriteServiceHourResponse)
                    return object;
                var message = new $root.http.write_service_hour.WriteServiceHourResponse();
                if (object.header != null) {
                    if (typeof object.header !== "object")
                        throw TypeError(".http.write_service_hour.WriteServiceHourResponse.header: object expected");
                    message.header = $root.core.header.ResponseHeader.fromObject(object.header);
                }
                return message;
            };

            /**
             * Creates a plain object from a WriteServiceHourResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof http.write_service_hour.WriteServiceHourResponse
             * @static
             * @param {http.write_service_hour.WriteServiceHourResponse} message WriteServiceHourResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WriteServiceHourResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.header = null;
                if (message.header != null && message.hasOwnProperty("header"))
                    object.header = $root.core.header.ResponseHeader.toObject(message.header, options);
                return object;
            };

            /**
             * Converts this WriteServiceHourResponse to JSON.
             * @function toJSON
             * @memberof http.write_service_hour.WriteServiceHourResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WriteServiceHourResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for WriteServiceHourResponse
             * @function getTypeUrl
             * @memberof http.write_service_hour.WriteServiceHourResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            WriteServiceHourResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/http.write_service_hour.WriteServiceHourResponse";
            };

            return WriteServiceHourResponse;
        })();

        return write_service_hour;
    })();

    return http;
})();

$root.model = (function () {

    /**
     * Namespace model.
     * @exports model
     * @namespace
     */
    var model = {};

    model.document = (function () {

        /**
         * Namespace document.
         * @memberof model
         * @namespace
         */
        var document = {};

        document.DocumentCategory = (function () {

            /**
             * Properties of a DocumentCategory.
             * @memberof model.document
             * @interface IDocumentCategory
             * @property {string|null} [id] DocumentCategory id
             * @property {string|null} [name] DocumentCategory name
             */

            /**
             * Constructs a new DocumentCategory.
             * @memberof model.document
             * @classdesc Represents a DocumentCategory.
             * @implements IDocumentCategory
             * @constructor
             * @param {model.document.IDocumentCategory=} [properties] Properties to set
             */
            function DocumentCategory(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DocumentCategory id.
             * @member {string} id
             * @memberof model.document.DocumentCategory
             * @instance
             */
            DocumentCategory.prototype.id = "";

            /**
             * DocumentCategory name.
             * @member {string} name
             * @memberof model.document.DocumentCategory
             * @instance
             */
            DocumentCategory.prototype.name = "";

            /**
             * Creates a new DocumentCategory instance using the specified properties.
             * @function create
             * @memberof model.document.DocumentCategory
             * @static
             * @param {model.document.IDocumentCategory=} [properties] Properties to set
             * @returns {model.document.DocumentCategory} DocumentCategory instance
             */
            DocumentCategory.create = function create(properties) {
                return new DocumentCategory(properties);
            };

            /**
             * Encodes the specified DocumentCategory message. Does not implicitly {@link model.document.DocumentCategory.verify|verify} messages.
             * @function encode
             * @memberof model.document.DocumentCategory
             * @static
             * @param {model.document.IDocumentCategory} message DocumentCategory message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DocumentCategory.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                return writer;
            };

            /**
             * Encodes the specified DocumentCategory message, length delimited. Does not implicitly {@link model.document.DocumentCategory.verify|verify} messages.
             * @function encodeDelimited
             * @memberof model.document.DocumentCategory
             * @static
             * @param {model.document.IDocumentCategory} message DocumentCategory message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DocumentCategory.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DocumentCategory message from the specified reader or buffer.
             * @function decode
             * @memberof model.document.DocumentCategory
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {model.document.DocumentCategory} DocumentCategory
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DocumentCategory.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.model.document.DocumentCategory();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                        case 1: {
                            message.id = reader.string();
                            break;
                        }
                        case 2: {
                            message.name = reader.string();
                            break;
                        }
                        default:
                            reader.skipType(tag & 7);
                            break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DocumentCategory message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof model.document.DocumentCategory
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {model.document.DocumentCategory} DocumentCategory
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DocumentCategory.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DocumentCategory message.
             * @function verify
             * @memberof model.document.DocumentCategory
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DocumentCategory.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                return null;
            };

            /**
             * Creates a DocumentCategory message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof model.document.DocumentCategory
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {model.document.DocumentCategory} DocumentCategory
             */
            DocumentCategory.fromObject = function fromObject(object) {
                if (object instanceof $root.model.document.DocumentCategory)
                    return object;
                var message = new $root.model.document.DocumentCategory();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.name != null)
                    message.name = String(object.name);
                return message;
            };

            /**
             * Creates a plain object from a DocumentCategory message. Also converts values to other types if specified.
             * @function toObject
             * @memberof model.document.DocumentCategory
             * @static
             * @param {model.document.DocumentCategory} message DocumentCategory
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DocumentCategory.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.id = "";
                    object.name = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                return object;
            };

            /**
             * Converts this DocumentCategory to JSON.
             * @function toJSON
             * @memberof model.document.DocumentCategory
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DocumentCategory.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DocumentCategory
             * @function getTypeUrl
             * @memberof model.document.DocumentCategory
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DocumentCategory.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/model.document.DocumentCategory";
            };

            return DocumentCategory;
        })();

        document.Document = (function () {

            /**
             * Properties of a Document.
             * @memberof model.document
             * @interface IDocument
             * @property {number|null} [id] Document id
             * @property {string|null} [name] Document name
             * @property {string|null} [path] Document path
             * @property {string|null} [description] Document description
             * @property {model.document.IDocumentCategory|null} [category] Document category
             * @property {core.time.IDateTime|null} [uploadDate] Document uploadDate
             */

            /**
             * Constructs a new Document.
             * @memberof model.document
             * @classdesc Represents a Document.
             * @implements IDocument
             * @constructor
             * @param {model.document.IDocument=} [properties] Properties to set
             */
            function Document(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Document id.
             * @member {number} id
             * @memberof model.document.Document
             * @instance
             */
            Document.prototype.id = 0;

            /**
             * Document name.
             * @member {string} name
             * @memberof model.document.Document
             * @instance
             */
            Document.prototype.name = "";

            /**
             * Document path.
             * @member {string} path
             * @memberof model.document.Document
             * @instance
             */
            Document.prototype.path = "";

            /**
             * Document description.
             * @member {string} description
             * @memberof model.document.Document
             * @instance
             */
            Document.prototype.description = "";

            /**
             * Document category.
             * @member {model.document.IDocumentCategory|null|undefined} category
             * @memberof model.document.Document
             * @instance
             */
            Document.prototype.category = null;

            /**
             * Document uploadDate.
             * @member {core.time.IDateTime|null|undefined} uploadDate
             * @memberof model.document.Document
             * @instance
             */
            Document.prototype.uploadDate = null;

            /**
             * Creates a new Document instance using the specified properties.
             * @function create
             * @memberof model.document.Document
             * @static
             * @param {model.document.IDocument=} [properties] Properties to set
             * @returns {model.document.Document} Document instance
             */
            Document.create = function create(properties) {
                return new Document(properties);
            };

            /**
             * Encodes the specified Document message. Does not implicitly {@link model.document.Document.verify|verify} messages.
             * @function encode
             * @memberof model.document.Document
             * @static
             * @param {model.document.IDocument} message Document message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Document.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.path);
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.description);
                if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                    $root.model.document.DocumentCategory.encode(message.category, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.uploadDate != null && Object.hasOwnProperty.call(message, "uploadDate"))
                    $root.core.time.DateTime.encode(message.uploadDate, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified Document message, length delimited. Does not implicitly {@link model.document.Document.verify|verify} messages.
             * @function encodeDelimited
             * @memberof model.document.Document
             * @static
             * @param {model.document.IDocument} message Document message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Document.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Document message from the specified reader or buffer.
             * @function decode
             * @memberof model.document.Document
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {model.document.Document} Document
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Document.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.model.document.Document();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                        case 1: {
                            message.id = reader.int32();
                            break;
                        }
                        case 2: {
                            message.name = reader.string();
                            break;
                        }
                        case 3: {
                            message.path = reader.string();
                            break;
                        }
                        case 4: {
                            message.description = reader.string();
                            break;
                        }
                        case 5: {
                            message.category = $root.model.document.DocumentCategory.decode(reader, reader.uint32());
                            break;
                        }
                        case 6: {
                            message.uploadDate = $root.core.time.DateTime.decode(reader, reader.uint32());
                            break;
                        }
                        default:
                            reader.skipType(tag & 7);
                            break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Document message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof model.document.Document
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {model.document.Document} Document
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Document.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Document message.
             * @function verify
             * @memberof model.document.Document
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Document.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.path != null && message.hasOwnProperty("path"))
                    if (!$util.isString(message.path))
                        return "path: string expected";
                if (message.description != null && message.hasOwnProperty("description"))
                    if (!$util.isString(message.description))
                        return "description: string expected";
                if (message.category != null && message.hasOwnProperty("category")) {
                    var error = $root.model.document.DocumentCategory.verify(message.category);
                    if (error)
                        return "category." + error;
                }
                if (message.uploadDate != null && message.hasOwnProperty("uploadDate")) {
                    var error = $root.core.time.DateTime.verify(message.uploadDate);
                    if (error)
                        return "uploadDate." + error;
                }
                return null;
            };

            /**
             * Creates a Document message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof model.document.Document
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {model.document.Document} Document
             */
            Document.fromObject = function fromObject(object) {
                if (object instanceof $root.model.document.Document)
                    return object;
                var message = new $root.model.document.Document();
                if (object.id != null)
                    message.id = object.id | 0;
                if (object.name != null)
                    message.name = String(object.name);
                if (object.path != null)
                    message.path = String(object.path);
                if (object.description != null)
                    message.description = String(object.description);
                if (object.category != null) {
                    if (typeof object.category !== "object")
                        throw TypeError(".model.document.Document.category: object expected");
                    message.category = $root.model.document.DocumentCategory.fromObject(object.category);
                }
                if (object.uploadDate != null) {
                    if (typeof object.uploadDate !== "object")
                        throw TypeError(".model.document.Document.uploadDate: object expected");
                    message.uploadDate = $root.core.time.DateTime.fromObject(object.uploadDate);
                }
                return message;
            };

            /**
             * Creates a plain object from a Document message. Also converts values to other types if specified.
             * @function toObject
             * @memberof model.document.Document
             * @static
             * @param {model.document.Document} message Document
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Document.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.id = 0;
                    object.name = "";
                    object.path = "";
                    object.description = "";
                    object.category = null;
                    object.uploadDate = null;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.path != null && message.hasOwnProperty("path"))
                    object.path = message.path;
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                if (message.category != null && message.hasOwnProperty("category"))
                    object.category = $root.model.document.DocumentCategory.toObject(message.category, options);
                if (message.uploadDate != null && message.hasOwnProperty("uploadDate"))
                    object.uploadDate = $root.core.time.DateTime.toObject(message.uploadDate, options);
                return object;
            };

            /**
             * Converts this Document to JSON.
             * @function toJSON
             * @memberof model.document.Document
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Document.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Document
             * @function getTypeUrl
             * @memberof model.document.Document
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Document.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/model.document.Document";
            };

            return Document;
        })();

        return document;
    })();

    model.service_hour = (function () {

        /**
         * Namespace service_hour.
         * @memberof model
         * @namespace
         */
        var service_hour = {};

        /**
         * ServiceType enum.
         * @name model.service_hour.ServiceType
         * @enum {number}
         * @property {number} UNKNOWN_TYPE=0 UNKNOWN_TYPE value
         * @property {number} HONORS_APPROVED=1 HONORS_APPROVED value
         * @property {number} SERVICE_TO_PROGRAM=2 SERVICE_TO_PROGRAM value
         * @property {number} OTHER_ST_LAWRENCE=3 OTHER_ST_LAWRENCE value
         * @property {number} OTHER_EXTERNAL=4 OTHER_EXTERNAL value
         */
        service_hour.ServiceType = (function () {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNKNOWN_TYPE"] = 0;
            values[valuesById[1] = "HONORS_APPROVED"] = 1;
            values[valuesById[2] = "SERVICE_TO_PROGRAM"] = 2;
            values[valuesById[3] = "OTHER_ST_LAWRENCE"] = 3;
            values[valuesById[4] = "OTHER_EXTERNAL"] = 4;
            values[valuesById[5] = "HP_200"] = 5;
            values[valuesById[6] = "CLARKSON_INTERNAL"] = 6;
            values[valuesById[7] = "EXTERNAL_SERVICE"] = 7;
            return values;
        })();

        service_hour.ServiceSupervisor = (function () {

            /**
             * Properties of a ServiceSupervisor.
             * @memberof model.service_hour
             * @interface IServiceSupervisor
             * @property {string|null} [name] ServiceSupervisor name
             * @property {string|null} [contact] ServiceSupervisor contact
             */

            /**
             * Constructs a new ServiceSupervisor.
             * @memberof model.service_hour
             * @classdesc Represents a ServiceSupervisor.
             * @implements IServiceSupervisor
             * @constructor
             * @param {model.service_hour.IServiceSupervisor=} [properties] Properties to set
             */
            function ServiceSupervisor(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ServiceSupervisor name.
             * @member {string} name
             * @memberof model.service_hour.ServiceSupervisor
             * @instance
             */
            ServiceSupervisor.prototype.name = "";

            /**
             * ServiceSupervisor contact.
             * @member {string} contact
             * @memberof model.service_hour.ServiceSupervisor
             * @instance
             */
            ServiceSupervisor.prototype.contact = "";

            /**
             * Creates a new ServiceSupervisor instance using the specified properties.
             * @function create
             * @memberof model.service_hour.ServiceSupervisor
             * @static
             * @param {model.service_hour.IServiceSupervisor=} [properties] Properties to set
             * @returns {model.service_hour.ServiceSupervisor} ServiceSupervisor instance
             */
            ServiceSupervisor.create = function create(properties) {
                return new ServiceSupervisor(properties);
            };

            /**
             * Encodes the specified ServiceSupervisor message. Does not implicitly {@link model.service_hour.ServiceSupervisor.verify|verify} messages.
             * @function encode
             * @memberof model.service_hour.ServiceSupervisor
             * @static
             * @param {model.service_hour.IServiceSupervisor} message ServiceSupervisor message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceSupervisor.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.contact != null && Object.hasOwnProperty.call(message, "contact"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.contact);
                return writer;
            };

            /**
             * Encodes the specified ServiceSupervisor message, length delimited. Does not implicitly {@link model.service_hour.ServiceSupervisor.verify|verify} messages.
             * @function encodeDelimited
             * @memberof model.service_hour.ServiceSupervisor
             * @static
             * @param {model.service_hour.IServiceSupervisor} message ServiceSupervisor message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceSupervisor.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ServiceSupervisor message from the specified reader or buffer.
             * @function decode
             * @memberof model.service_hour.ServiceSupervisor
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {model.service_hour.ServiceSupervisor} ServiceSupervisor
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceSupervisor.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.model.service_hour.ServiceSupervisor();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                        case 1: {
                            message.name = reader.string();
                            break;
                        }
                        case 2: {
                            message.contact = reader.string();
                            break;
                        }
                        default:
                            reader.skipType(tag & 7);
                            break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ServiceSupervisor message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof model.service_hour.ServiceSupervisor
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {model.service_hour.ServiceSupervisor} ServiceSupervisor
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceSupervisor.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ServiceSupervisor message.
             * @function verify
             * @memberof model.service_hour.ServiceSupervisor
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ServiceSupervisor.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.contact != null && message.hasOwnProperty("contact"))
                    if (!$util.isString(message.contact))
                        return "contact: string expected";
                return null;
            };

            /**
             * Creates a ServiceSupervisor message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof model.service_hour.ServiceSupervisor
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {model.service_hour.ServiceSupervisor} ServiceSupervisor
             */
            ServiceSupervisor.fromObject = function fromObject(object) {
                if (object instanceof $root.model.service_hour.ServiceSupervisor)
                    return object;
                var message = new $root.model.service_hour.ServiceSupervisor();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.contact != null)
                    message.contact = String(object.contact);
                return message;
            };

            /**
             * Creates a plain object from a ServiceSupervisor message. Also converts values to other types if specified.
             * @function toObject
             * @memberof model.service_hour.ServiceSupervisor
             * @static
             * @param {model.service_hour.ServiceSupervisor} message ServiceSupervisor
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServiceSupervisor.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.name = "";
                    object.contact = "";
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.contact != null && message.hasOwnProperty("contact"))
                    object.contact = message.contact;
                return object;
            };

            /**
             * Converts this ServiceSupervisor to JSON.
             * @function toJSON
             * @memberof model.service_hour.ServiceSupervisor
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServiceSupervisor.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ServiceSupervisor
             * @function getTypeUrl
             * @memberof model.service_hour.ServiceSupervisor
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ServiceSupervisor.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/model.service_hour.ServiceSupervisor";
            };

            return ServiceSupervisor;
        })();

        service_hour.ServiceHour = (function () {

            /**
             * Properties of a ServiceHour.
             * @memberof model.service_hour
             * @interface IServiceHour
             * @property {number|null} [id] ServiceHour id
             * @property {core.account.IStudentUser|null} [user] ServiceHour user
             * @property {string|null} [description] ServiceHour description
             * @property {model.service_hour.ServiceType|null} [type] ServiceHour type
             * @property {string|null} [location] ServiceHour location
             * @property {model.service_hour.IServiceSupervisor|null} [supervisor] ServiceHour supervisor
             * @property {core.time.IDateTime|null} [submissionTimestamp] ServiceHour submissionTimestamp
             * @property {core.time.IDateTime|null} [serviceDate] ServiceHour serviceDate
             * @property {core.time.IDuration|null} [durationRequested] ServiceHour durationRequested
             * @property {core.time.IDuration|null} [durationApproved] ServiceHour durationApproved
             * @property {string|null} [imgPath] ServiceHour imgPath
             * @property {string|null} [approver] ServiceHour approver
             * @property {string|null} [reason] ServiceHour reason
             */

            /**
             * Constructs a new ServiceHour.
             * @memberof model.service_hour
             * @classdesc Represents a ServiceHour.
             * @implements IServiceHour
             * @constructor
             * @param {model.service_hour.IServiceHour=} [properties] Properties to set
             */
            function ServiceHour(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ServiceHour id.
             * @member {number} id
             * @memberof model.service_hour.ServiceHour
             * @instance
             */
            ServiceHour.prototype.id = 0;

            /**
             * ServiceHour user.
             * @member {core.account.IStudentUser|null|undefined} user
             * @memberof model.service_hour.ServiceHour
             * @instance
             */
            ServiceHour.prototype.user = null;

            /**
             * ServiceHour description.
             * @member {string} description
             * @memberof model.service_hour.ServiceHour
             * @instance
             */
            ServiceHour.prototype.description = "";

            /**
             * ServiceHour type.
             * @member {model.service_hour.ServiceType} type
             * @memberof model.service_hour.ServiceHour
             * @instance
             */
            ServiceHour.prototype.type = 0;

            /**
             * ServiceHour location.
             * @member {string} location
             * @memberof model.service_hour.ServiceHour
             * @instance
             */
            ServiceHour.prototype.location = "";

            /**
             * ServiceHour supervisor.
             * @member {model.service_hour.IServiceSupervisor|null|undefined} supervisor
             * @memberof model.service_hour.ServiceHour
             * @instance
             */
            ServiceHour.prototype.supervisor = null;

            /**
             * ServiceHour submissionTimestamp.
             * @member {core.time.IDateTime|null|undefined} submissionTimestamp
             * @memberof model.service_hour.ServiceHour
             * @instance
             */
            ServiceHour.prototype.submissionTimestamp = null;

            /**
             * ServiceHour serviceDate.
             * @member {core.time.IDateTime|null|undefined} serviceDate
             * @memberof model.service_hour.ServiceHour
             * @instance
             */
            ServiceHour.prototype.serviceDate = null;

            /**
             * ServiceHour durationRequested.
             * @member {core.time.IDuration|null|undefined} durationRequested
             * @memberof model.service_hour.ServiceHour
             * @instance
             */
            ServiceHour.prototype.durationRequested = null;

            /**
             * ServiceHour durationApproved.
             * @member {core.time.IDuration|null|undefined} durationApproved
             * @memberof model.service_hour.ServiceHour
             * @instance
             */
            ServiceHour.prototype.durationApproved = null;

            /**
             * ServiceHour Image Path Name for uploads
             */
            ServiceHour.prototype.imgPath = "";

            /**
             * ServiceHour Approver name for testing purposes and approvals
             */
            ServiceHour.prototype.approver = "";

            /**
             * ServiceHour reasoning for approval modifications or disapproval.
             */
            ServiceHour.prototype.reason = "";

            /**
             * Creates a new ServiceHour instance using the specified properties.
             * @function create
             * @memberof model.service_hour.ServiceHour
             * @static
             * @param {model.service_hour.IServiceHour=} [properties] Properties to set
             * @returns {model.service_hour.ServiceHour} ServiceHour instance
             */
            ServiceHour.create = function create(properties) {
                return new ServiceHour(properties);
            };

            /**
             * Encodes the specified ServiceHour message. Does not implicitly {@link model.service_hour.ServiceHour.verify|verify} messages.
             * @function encode
             * @memberof model.service_hour.ServiceHour
             * @static
             * @param {model.service_hour.IServiceHour} message ServiceHour message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceHour.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
                if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                    $root.core.account.StudentUser.encode(message.user, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.type);
                if (message.location != null && Object.hasOwnProperty.call(message, "location"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.location);
                if (message.supervisor != null && Object.hasOwnProperty.call(message, "supervisor"))
                    $root.model.service_hour.ServiceSupervisor.encode(message.supervisor, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.submissionTimestamp != null && Object.hasOwnProperty.call(message, "submissionTimestamp"))
                    $root.core.time.DateTime.encode(message.submissionTimestamp, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.serviceDate != null && Object.hasOwnProperty.call(message, "serviceDate"))
                    $root.core.time.DateTime.encode(message.serviceDate, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.durationRequested != null && Object.hasOwnProperty.call(message, "durationRequested"))
                    $root.core.time.Duration.encode(message.durationRequested, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.durationApproved != null && Object.hasOwnProperty.call(message, "durationApproved"))
                    $root.core.time.Duration.encode(message.durationApproved, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.imgPath != null && Object.hasOwnProperty.call(message, "imgPath")) {
                    writer.uint32(/* id 11, wireType 2 =*/90).string(message.imgPath);
                } else {
                    writer.uint32(/* id 11, wireType 2 =*/90).string("");
                }
                if (message.approver != null && Object.hasOwnProperty.call(message, "approver")) {
                    writer.uint32(/* id 12, wireType 2 =*/98).string(message.approver);
                } else {
                    writer.uint32(/* id 12, wireType 2 =*/98).string("");
                }

                if (message.reason != null && Object.hasOwnProperty.call(message, "reason")) {
                    writer.uint32(/* id 13, wireType 2 =*/106).string(message.reason);
                } else {
                    writer.uint32(/* id 13, wireType 2 =*/106).string("");
                }
                return writer;
            };

            /**
             * Encodes the specified ServiceHour message, length delimited. Does not implicitly {@link model.service_hour.ServiceHour.verify|verify} messages.
             * @function encodeDelimited
             * @memberof model.service_hour.ServiceHour
             * @static
             * @param {model.service_hour.IServiceHour} message ServiceHour message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceHour.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ServiceHour message from the specified reader or buffer.
             * @function decode
             * @memberof model.service_hour.ServiceHour
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {model.service_hour.ServiceHour} ServiceHour
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceHour.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.model.service_hour.ServiceHour();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                        case 1: {
                            message.id = reader.int32();
                            break;
                        }
                        case 2: {
                            message.user = $root.core.account.StudentUser.decode(reader, reader.uint32());
                            break;
                        }
                        case 3: {
                            message.description = reader.string();
                            break;
                        }
                        case 4: {
                            message.type = reader.int32();
                            break;
                        }
                        case 5: {
                            message.location = reader.string();
                            break;
                        }
                        case 6: {
                            message.supervisor = $root.model.service_hour.ServiceSupervisor.decode(reader, reader.uint32());
                            break;
                        }
                        case 7: {
                            message.submissionTimestamp = $root.core.time.DateTime.decode(reader, reader.uint32());
                            break;
                        }
                        case 8: {
                            message.serviceDate = $root.core.time.DateTime.decode(reader, reader.uint32());
                            break;
                        }
                        case 9: {
                            message.durationRequested = $root.core.time.Duration.decode(reader, reader.uint32());
                            break;
                        }
                        case 10: {
                            message.durationApproved = $root.core.time.Duration.decode(reader, reader.uint32());
                            break;
                        }
                        case 11: {
                            message.imgPath = reader.string();
                            break;
                        }
                        case 12: {
                            message.approver = reader.string();
                            break;
                        }
                        case 13: {
                            message.reason = reader.string();
                            break;
                        }
                        default:
                            reader.skipType(tag & 7);
                            break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ServiceHour message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof model.service_hour.ServiceHour
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {model.service_hour.ServiceHour} ServiceHour
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceHour.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ServiceHour message.
             * @function verify
             * @memberof model.service_hour.ServiceHour
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ServiceHour.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                if (message.user != null && message.hasOwnProperty("user")) {
                    var error = $root.core.account.StudentUser.verify(message.user);
                    if (error)
                        return "user." + error;
                }
                if (message.description != null && message.hasOwnProperty("description"))
                    if (!$util.isString(message.description))
                        return "description: string expected";
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                            break;
                    }
                if (message.location != null && message.hasOwnProperty("location"))
                    if (!$util.isString(message.location))
                        return "location: string expected";
                if (message.supervisor != null && message.hasOwnProperty("supervisor")) {
                    var error = $root.model.service_hour.ServiceSupervisor.verify(message.supervisor);
                    if (error)
                        return "supervisor." + error;
                }
                if (message.submissionTimestamp != null && message.hasOwnProperty("submissionTimestamp")) {
                    var error = $root.core.time.DateTime.verify(message.submissionTimestamp);
                    if (error)
                        return "submissionTimestamp." + error;
                }
                if (message.serviceDate != null && message.hasOwnProperty("serviceDate")) {
                    var error = $root.core.time.DateTime.verify(message.serviceDate);
                    if (error)
                        return "serviceDate." + error;
                }
                if (message.durationRequested != null && message.hasOwnProperty("durationRequested")) {
                    var error = $root.core.time.Duration.verify(message.durationRequested);
                    if (error)
                        return "durationRequested." + error;
                }
                if (message.durationApproved != null && message.hasOwnProperty("durationApproved")) {
                    var error = $root.core.time.Duration.verify(message.durationApproved);
                    if (error)
                        return "durationApproved." + error;
                }
                if (message.imgPath != null && message.hasOwnProperty("imgPath")) {
                    if (!$util.isString(message.imgPath))
                        return "imgPath: string expected";
                }
                if (message.approver != null && message.hasOwnProperty("approver")) {
                    if (!$util.isString(message.approver))
                        return "approver: string expected";
                }
                if (message.reason != null && message.hasOwnProperty("reason")) {
                    if (!$util.isString(message.reason))
                        return "reason: string expected";
                }
                return null;
            };

            /**
             * Creates a ServiceHour message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof model.service_hour.ServiceHour
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {model.service_hour.ServiceHour} ServiceHour
             */
            ServiceHour.fromObject = function fromObject(object) {
                if (object instanceof $root.model.service_hour.ServiceHour)
                    return object;
                var message = new $root.model.service_hour.ServiceHour();
                if (object.id != null)
                    message.id = object.id | 0;
                if (object.user != null) {
                    if (typeof object.user !== "object")
                        throw TypeError(".model.service_hour.ServiceHour.user: object expected");
                    message.user = $root.core.account.StudentUser.fromObject(object.user);
                }
                if (object.description != null)
                    message.description = String(object.description);
                switch (object.type) {
                    default:
                        if (typeof object.type === "number") {
                            message.type = object.type;
                            break;
                        }
                        break;
                    case "UNKNOWN_TYPE":
                    case 0:
                        message.type = 0;
                        break;
                    case "HONORS_APPROVED":
                    case 1:
                        message.type = 1;
                        break;
                    case "SERVICE_TO_PROGRAM":
                    case 2:
                        message.type = 2;
                        break;
                    case "OTHER_ST_LAWRENCE":
                    case 3:
                        message.type = 3;
                        break;
                    case "OTHER_EXTERNAL":
                    case 4:
                        message.type = 4;
                        break;
                }
                if (object.location != null)
                    message.location = String(object.location);
                if (object.supervisor != null) {
                    if (typeof object.supervisor !== "object")
                        throw TypeError(".model.service_hour.ServiceHour.supervisor: object expected");
                    message.supervisor = $root.model.service_hour.ServiceSupervisor.fromObject(object.supervisor);
                }
                if (object.submissionTimestamp != null) {
                    if (typeof object.submissionTimestamp !== "object")
                        throw TypeError(".model.service_hour.ServiceHour.submissionTimestamp: object expected");
                    message.submissionTimestamp = $root.core.time.DateTime.fromObject(object.submissionTimestamp);
                }
                if (object.serviceDate != null) {
                    if (typeof object.serviceDate !== "object")
                        throw TypeError(".model.service_hour.ServiceHour.serviceDate: object expected");
                    message.serviceDate = $root.core.time.DateTime.fromObject(object.serviceDate);
                }
                if (object.durationRequested != null) {
                    if (typeof object.durationRequested !== "object")
                        throw TypeError(".model.service_hour.ServiceHour.durationRequested: object expected");
                    message.durationRequested = $root.core.time.Duration.fromObject(object.durationRequested);
                }
                if (object.durationApproved != null) {
                    if (typeof object.durationApproved !== "object")
                        throw TypeError(".model.service_hour.ServiceHour.durationApproved: object expected");
                    message.durationApproved = $root.core.time.Duration.fromObject(object.durationApproved);
                }
                if (object.imgPath != null) {
                    message.imgPath = String(object.imgPath);
                } else {
                    message.imgPath = "";
                }
                if (object.approver != null) {
                    message.approver = object.approver;
                } else {
                    message.approver = "";
                }
                if (object.reason != null) {
                    message.reason = String(object.reason);
                }
                return message;
            };

            /**
             * Creates a plain object from a ServiceHour message. Also converts values to other types if specified.
             * @function toObject
             * @memberof model.service_hour.ServiceHour
             * @static
             * @param {model.service_hour.ServiceHour} message ServiceHour
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServiceHour.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.id = 0;
                    object.user = null;
                    object.description = "";
                    object.type = options.enums === String ? "UNKNOWN_TYPE" : 0;
                    object.location = "";
                    object.supervisor = null;
                    object.submissionTimestamp = null;
                    object.serviceDate = null;
                    object.durationRequested = null;
                    object.durationApproved = null;
                    object.imgPath = "";
                    object.approver = "";
                    object.reason = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.user != null && message.hasOwnProperty("user"))
                    object.user = $root.core.account.StudentUser.toObject(message.user, options);
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.model.service_hour.ServiceType[message.type] === undefined ? message.type : $root.model.service_hour.ServiceType[message.type] : message.type;
                if (message.location != null && message.hasOwnProperty("location"))
                    object.location = message.location;
                if (message.supervisor != null && message.hasOwnProperty("supervisor"))
                    object.supervisor = $root.model.service_hour.ServiceSupervisor.toObject(message.supervisor, options);
                if (message.submissionTimestamp != null && message.hasOwnProperty("submissionTimestamp"))
                    object.submissionTimestamp = $root.core.time.DateTime.toObject(message.submissionTimestamp, options);
                if (message.serviceDate != null && message.hasOwnProperty("serviceDate"))
                    object.serviceDate = $root.core.time.DateTime.toObject(message.serviceDate, options);
                if (message.durationRequested != null && message.hasOwnProperty("durationRequested"))
                    object.durationRequested = $root.core.time.Duration.toObject(message.durationRequested, options);
                if (message.durationApproved != null && message.hasOwnProperty("durationApproved"))
                    object.durationApproved = $root.core.time.Duration.toObject(message.durationApproved, options);
                if (message.hasOwnProperty("imgPath"))
                    object.imgPath = message.imgPath;
                if (message.approver != null && message.hasOwnProperty("approver"))
                    object.approver = message.approver;
                if (message.reason != null && message.hasOwnProperty("reason"))
                    object.reason = message.reason;
                return object;
            };

            /**
             * Converts this ServiceHour to JSON.
             * @function toJSON
             * @memberof model.service_hour.ServiceHour
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServiceHour.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ServiceHour
             * @function getTypeUrl
             * @memberof model.service_hour.ServiceHour
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ServiceHour.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/model.service_hour.ServiceHour";
            };

            return ServiceHour;
        })();

        return service_hour;
    })();

    model.service_review = (function () {

        /**
         * Namespace service_review.
         * @memberof model
         * @namespace
         */
        var service_review = {};

        /**
         * ServiceReviewStatus enum.
         * @name model.service_review.ServiceReviewStatus
         * @enum {number}
         * @property {number} UNKNOWN_STATUS=0 UNKNOWN_STATUS value
         * @property {number} PENDING=1 PENDING value
         * @property {number} APPROVED=2 APPROVED value
         * @property {number} REJECTED=3 REJECTED value
         */
        service_review.ServiceReviewStatus = (function () {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNKNOWN_STATUS"] = 0;
            values[valuesById[1] = "PENDING"] = 1;
            values[valuesById[2] = "APPROVED"] = 2;
            values[valuesById[3] = "REJECTED"] = 3;
            return values;
        })();

        service_review.ServiceReview = (function () {

            /**
             * Properties of a ServiceReview.
             * @memberof model.service_review
             * @interface IServiceReview
             * @property {number|null} [id] ServiceReview id
             * @property {core.time.IDateTime|null} [timestamp] ServiceReview timestamp
             * @property {model.service_review.ServiceReviewStatus|null} [status] ServiceReview status
             * @property {core.time.IDuration|null} [durationApproved] ServiceReview durationApproved
             * @property {string|null} [username] ServiceReview username
             * @property {string|null} [name] ServiceReview name
             * @property {string|null} [comment] ServiceReview comment
             */

            /**
             * Constructs a new ServiceReview.
             * @memberof model.service_review
             * @classdesc Represents a ServiceReview.
             * @implements IServiceReview
             * @constructor
             * @param {model.service_review.IServiceReview=} [properties] Properties to set
             */
            function ServiceReview(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ServiceReview id.
             * @member {number} id
             * @memberof model.service_review.ServiceReview
             * @instance
             */
            ServiceReview.prototype.id = 0;

            /**
             * ServiceReview timestamp.
             * @member {core.time.IDateTime|null|undefined} timestamp
             * @memberof model.service_review.ServiceReview
             * @instance
             */
            ServiceReview.prototype.timestamp = null;

            /**
             * ServiceReview status.
             * @member {model.service_review.ServiceReviewStatus} status
             * @memberof model.service_review.ServiceReview
             * @instance
             */
            ServiceReview.prototype.status = 0;

            /**
             * ServiceReview durationApproved.
             * @member {core.time.IDuration|null|undefined} durationApproved
             * @memberof model.service_review.ServiceReview
             * @instance
             */
            ServiceReview.prototype.durationApproved = null;

            /**
             * ServiceReview username.
             * @member {string} username
             * @memberof model.service_review.ServiceReview
             * @instance
             */
            ServiceReview.prototype.username = "";

            /**
             * ServiceReview name.
             * @member {string} name
             * @memberof model.service_review.ServiceReview
             * @instance
             */
            ServiceReview.prototype.name = "";

            /**
             * ServiceReview comment.
             * @member {string} comment
             * @memberof model.service_review.ServiceReview
             * @instance
             */
            ServiceReview.prototype.comment = "";

            /**
             * Creates a new ServiceReview instance using the specified properties.
             * @function create
             * @memberof model.service_review.ServiceReview
             * @static
             * @param {model.service_review.IServiceReview=} [properties] Properties to set
             * @returns {model.service_review.ServiceReview} ServiceReview instance
             */
            ServiceReview.create = function create(properties) {
                return new ServiceReview(properties);
            };

            /**
             * Encodes the specified ServiceReview message. Does not implicitly {@link model.service_review.ServiceReview.verify|verify} messages.
             * @function encode
             * @memberof model.service_review.ServiceReview
             * @static
             * @param {model.service_review.IServiceReview} message ServiceReview message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceReview.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
                if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                    $root.core.time.DateTime.encode(message.timestamp, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.status);
                if (message.durationApproved != null && Object.hasOwnProperty.call(message, "durationApproved"))
                    $root.core.time.Duration.encode(message.durationApproved, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.username != null && Object.hasOwnProperty.call(message, "username"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.username);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.name);
                if (message.comment != null && Object.hasOwnProperty.call(message, "comment"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.comment);
                return writer;
            };

            /**
             * Encodes the specified ServiceReview message, length delimited. Does not implicitly {@link model.service_review.ServiceReview.verify|verify} messages.
             * @function encodeDelimited
             * @memberof model.service_review.ServiceReview
             * @static
             * @param {model.service_review.IServiceReview} message ServiceReview message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceReview.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ServiceReview message from the specified reader or buffer.
             * @function decode
             * @memberof model.service_review.ServiceReview
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {model.service_review.ServiceReview} ServiceReview
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceReview.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.model.service_review.ServiceReview();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                        case 1: {
                            message.id = reader.int32();
                            break;
                        }
                        case 2: {
                            message.timestamp = $root.core.time.DateTime.decode(reader, reader.uint32());
                            break;
                        }
                        case 3: {
                            message.status = reader.int32();
                            break;
                        }
                        case 4: {
                            message.durationApproved = $root.core.time.Duration.decode(reader, reader.uint32());
                            break;
                        }
                        case 5: {
                            message.username = reader.string();
                            break;
                        }
                        case 6: {
                            message.name = reader.string();
                            break;
                        }
                        case 7: {
                            message.comment = reader.string();
                            break;
                        }
                        default:
                            reader.skipType(tag & 7);
                            break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ServiceReview message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof model.service_review.ServiceReview
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {model.service_review.ServiceReview} ServiceReview
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceReview.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ServiceReview message.
             * @function verify
             * @memberof model.service_review.ServiceReview
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ServiceReview.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                if (message.timestamp != null && message.hasOwnProperty("timestamp")) {
                    var error = $root.core.time.DateTime.verify(message.timestamp);
                    if (error)
                        return "timestamp." + error;
                }
                if (message.status != null && message.hasOwnProperty("status"))
                    switch (message.status) {
                        default:
                            return "status: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                    }
                if (message.durationApproved != null && message.hasOwnProperty("durationApproved")) {
                    var error = $root.core.time.Duration.verify(message.durationApproved);
                    if (error)
                        return "durationApproved." + error;
                }
                if (message.username != null && message.hasOwnProperty("username"))
                    if (!$util.isString(message.username))
                        return "username: string expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.comment != null && message.hasOwnProperty("comment"))
                    if (!$util.isString(message.comment))
                        return "comment: string expected";
                return null;
            };

            /**
             * Creates a ServiceReview message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof model.service_review.ServiceReview
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {model.service_review.ServiceReview} ServiceReview
             */
            ServiceReview.fromObject = function fromObject(object) {
                if (object instanceof $root.model.service_review.ServiceReview)
                    return object;
                var message = new $root.model.service_review.ServiceReview();
                if (object.id != null)
                    message.id = object.id | 0;
                if (object.timestamp != null) {
                    if (typeof object.timestamp !== "object")
                        throw TypeError(".model.service_review.ServiceReview.timestamp: object expected");
                    message.timestamp = $root.core.time.DateTime.fromObject(object.timestamp);
                }
                switch (object.status) {
                    default:
                        if (typeof object.status === "number") {
                            message.status = object.status;
                            break;
                        }
                        break;
                    case "UNKNOWN_STATUS":
                    case 0:
                        message.status = 0;
                        break;
                    case "PENDING":
                    case 1:
                        message.status = 1;
                        break;
                    case "APPROVED":
                    case 2:
                        message.status = 2;
                        break;
                    case "REJECTED":
                    case 3:
                        message.status = 3;
                        break;
                }
                if (object.durationApproved != null) {
                    if (typeof object.durationApproved !== "object")
                        throw TypeError(".model.service_review.ServiceReview.durationApproved: object expected");
                    message.durationApproved = $root.core.time.Duration.fromObject(object.durationApproved);
                }
                if (object.username != null)
                    message.username = String(object.username);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.comment != null)
                    message.comment = String(object.comment);
                return message;
            };

            /**
             * Creates a plain object from a ServiceReview message. Also converts values to other types if specified.
             * @function toObject
             * @memberof model.service_review.ServiceReview
             * @static
             * @param {model.service_review.ServiceReview} message ServiceReview
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServiceReview.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.id = 0;
                    object.timestamp = null;
                    object.status = options.enums === String ? "UNKNOWN_STATUS" : 0;
                    object.durationApproved = null;
                    object.username = "";
                    object.name = "";
                    object.comment = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                    object.timestamp = $root.core.time.DateTime.toObject(message.timestamp, options);
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = options.enums === String ? $root.model.service_review.ServiceReviewStatus[message.status] === undefined ? message.status : $root.model.service_review.ServiceReviewStatus[message.status] : message.status;
                if (message.durationApproved != null && message.hasOwnProperty("durationApproved"))
                    object.durationApproved = $root.core.time.Duration.toObject(message.durationApproved, options);
                if (message.username != null && message.hasOwnProperty("username"))
                    object.username = message.username;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.comment != null && message.hasOwnProperty("comment"))
                    object.comment = message.comment;
                return object;
            };

            /**
             * Converts this ServiceReview to JSON.
             * @function toJSON
             * @memberof model.service_review.ServiceReview
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServiceReview.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ServiceReview
             * @function getTypeUrl
             * @memberof model.service_review.ServiceReview
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ServiceReview.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/model.service_review.ServiceReview";
            };

            return ServiceReview;
        })();

        return service_review;
    })();

    return model;
})();

module.exports = $root;
