import React, {
  ReactElement,
} from 'react';
import {
  Routes,
  Route,
} from 'react-router-dom';
import LandingPage from 'service_page/landing_page';
import ErrorPage from 'error_page';
import ServiceSubmitPage from './submit_service';

/**
 * Component for service page. The service page consists of two sub-routes:
 * landing page and submission page
 *
 * @returns {ReactElement} the service page component.
 */

export default function ServicePage() {
  return (
    <Routes>
      <Route exact path="/" element={<LandingPage />} />
      <Route exact path="/submit" element={<ServiceSubmitPage />} />
      <Route exact path="*" element={<ErrorPage />} />
    </Routes>
  );
}
