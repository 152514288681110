import React, {
    ReactElement,
} from 'react';
import PageTemplate from 'page_template';
import HeaderCard from 'components/header_card';
import Button from 'components/button';
import {
    Container,
    Inner,
} from './styles/service_page.style';
import {
    Heading,
    Content,
    ListSx,
    OrderedListSx,
    ListItemSx,
    SubmitServiceText,
    SubmitServiceContainer,
    ContainerSx
} from "./styles/landing_page.style";
import * as PATHS from 'routing/paths';


export default function LandingPage() {
    return (
        <PageTemplate active="service">
            <HeaderCard bkgSrc="/images/headers/student_center.png">
                <HeaderCard.Title>Community Service</HeaderCard.Title>
            </HeaderCard>
            <ContainerSx>
                {/* <Inner> */}
                <LandingPage.SubmitServiceButton>
                    SUBMIT HOURS
                </LandingPage.SubmitServiceButton>
                <Content>
                    Every Honors student must complete a minimum number of community service hours,
                    equivalent to five hours per semester in the Honors Program, in order to graduate
                    with an Honors degree. In order to ensure that progress is made towards completing
                    this requirement, students must complete a minimum of 50% of their required hours
                    by the last day of classes of the fall semester of their junior academic year.
                    These hours must be completed between the student’s enrollment in the Honors
                    Program and the end of his/her last academic year prior to graduation. If a student
                    does not complete the requirement before graduation, an Honors Program degree will
                    not be granted. Appeals regarding interpretation of the service guidelines may be
                    made in writing to the Steering Committee.
                </Content>
                <Heading>Goals of Service</Heading>
                <ListSx>
                    <ListItemSx>Directly benefits members of the community by fulfilling needs within the community</ListItemSx>
                    <ListItemSx>Develops ties between Clarkson University and the surrounding communities</ListItemSx>
                    <ListItemSx>Develops ties between Clarkson University and the surrounding communities</ListItemSx>
                    <ListItemSx>Provides an opportunity for Honors students to broaden their range of life experiences</ListItemSx>
                </ListSx>
                <Heading>Kinds of Service</Heading>
                <Content>
                    The five required hours of service per semester of participation in the Honors Program
                    can be completed in the following ways:
                </Content>
                <OrderedListSx>
                    <ListItemSx>Events planned by the service committee, or advertised by the service committee (i.e. if sent out in an email from a service representative)</ListItemSx>
                    <ListItemSx>Individual service projects completed within St. Lawrence County and approved by the service committee</ListItemSx>
                    <ListItemSx>Individual service projects completed outside of St. Lawrence County and approved by the service committee</ListItemSx>
                    <ListItemSx>Service to the Honors Program or any other Clarkson Department, (such as working during a phone-a-thon, at an Open House event, volunteering during orientation, etc.)</ListItemSx>
                </OrderedListSx>
                <Heading>Conditions for Service</Heading>
                <ListSx>
                    <ListItemSx>No more than one fourth of the required hours can be fulfilled from each of options 3 and 4.</ListItemSx>
                    <ListItemSx>At least half of the service hours must be performed while the student is at Clarkson.</ListItemSx>
                    <ListItemSx>Each act of service must uniquely fulfill the Honors Program requirement and no other. Service required for other organizations may not count as Honors service</ListItemSx>
                    <ListItemSx>Students receive credit for the actual duration of an event, not for the planned duration of an event. If an event that was supposed to last 2 hours only lasts 15 minutes, then only 15 minutes is counted.</ListItemSx>
                    <ListItemSx>The service activity must not express political commentary or otherwise render an opinion for or against a community issue (no protesting). The event may encourage political discussion, while remaining neutral.</ListItemSx>
                </ListSx>
                {/* </Inner> */}
            </ContainerSx>
        </PageTemplate>
    );
}

LandingPage.SubmitServiceButton = function LandingPageSubmitServiceButton({ children, ...otherProps }) {
    return (
        <SubmitServiceContainer>
            <Button variant="contained" border="rounded" {...otherProps}>
                <SubmitServiceText to={PATHS.SERVICE + '/submit'} state={{ from: location.pathname }}>{children}</SubmitServiceText>
            </Button>
        </SubmitServiceContainer>
    );
}
