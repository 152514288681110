import styled from '@mui/material/styles/styled';
import Button from '@mui/material/Button';
import { Link as ReactRouterLink } from 'react-router-dom';

export const Heading = styled('h1')`
    font-size: 25px;
    color: black;
    margin: 10px 0px 5px 0px;
`;

export const Content = styled('div')`
    font-size: 18px;
    color: black;
`;

export const List = styled('ul')`
    font-size: 18px;
    color: black;
    margin: 0px;
`;

export const ListItem = styled('li')``;

export const TextbooksButtonSx = styled(Button)`
    font-size: 15 px;
    font-weight: bold;
    margin-top: 15px;
    margin-botton: 5px;
    width: 100%;
`;
TextbooksButtonSx.defaultProps = {
    type: 'submit',
    variant: 'contained',
};

export const TextbooksContainer = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
`;

export const TextbooksText = styled(ReactRouterLink)`
    width: 400px;
    padding: 10px 0px;
    font-size: 30px;
    text-transform: none;
    text-decoration: none;


    color: white;
    font-weight: bold;
`;
