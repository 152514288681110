import CommitteeCard from 'about_page/committee_card';
import aboutPageController from 'about_page/controller/about_page.controller';
import MemberCard from 'about_page/member_card';
import {
  CommitteeGroupSx,
  ContainerSx,
  HeadingSx,
  InnerSx,
  MemberGroupSx,
  SnackbarSx,
  SummaryTextSx,
} from 'about_page/styles/about_page.style';
import HeaderCard from 'components/header_card';
import PageTemplate from 'page_template';
import React, {
  useState,
  useEffect,
} from 'react';
import { useLocation } from 'react-router-dom';

// todo: store duration in a common file
const SNACKBAR_DURATION = 3000;

export default function LandingPage() {
  // steering committee states and actions
  const [steeringCommittees, setSteeringCommittees] = useState([]);
  useEffect(() => {
    aboutPageController
      .readSteeringCommittees()
      .then((committees) => setSteeringCommittees(committees));
  }, []);

  // steering members states and actions
  const [steeringMembers, setSteeringMembers] = useState([]);
  useEffect(() => {
    aboutPageController
      .readSteeringMembers()
      .then((members) => setSteeringMembers(members));
  }, []);

  // error snackbar state and actions
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState('');
  useEffect(() => {
    if (location.state && location.state.errorMessage) {
      setErrorMessage(location.state.errorMessage);
      // clears the state that was previously set.
      window.history.replaceState(null, '', location.pathname);
    }
  }, [location]);
  const onSnackbarClose = () => setErrorMessage('');

  return (
    <PageTemplate active="about">
      <HeaderCard bkgSrc="/images/headers/governance.jpg">
        <HeaderCard.Title>Steering Board</HeaderCard.Title>
      </HeaderCard>
      <ContainerSx>
        <InnerSx>
          <SummaryTextSx>
            The Steering Board gives direction and guidance to the five
            committees and acts as the liaison between honors students, the
            Honors Program office,  and the Honors Council. Each class elects
            two representatives to sit on the  Steering Board; the co-chairs of
            the committee are elected by the entire honors student body.
            Additionally, each class elects a support-representative, who will
            fill-in for the class representative as needed.
          </SummaryTextSx>
          <SummaryTextSx>
            The Executive Board that represents the Honors Program consists of
            the Co-Chairs,  a secretary and a treasurer. The secretary and
            treasurer positions are filled by a class representative or support
            representative who are nominated by the Steering Board.
          </SummaryTextSx>
          <SummaryTextSx>
            Additional details on membership of the Steering Board and its
            duties are spelled out in The Steering Board Constitution.
          </SummaryTextSx>
          {/* <HeadingSx>Committees</HeadingSx>
          <CommitteeGroupSx>
            {steeringCommittees.map((committee) => (
              <CommitteeCard
                key={committee.id}
                committeeId={committee.id}
              >
                {committee.label}
              </CommitteeCard>
            ))}
          </CommitteeGroupSx> */}
          <HeadingSx>Members</HeadingSx>
          <MemberGroupSx>
            {steeringMembers.map((member) => (
              <MemberCard key={member.id}>
                <MemberCard.Image src={member.image} />
                <MemberCard.Name>
                  {member.name}
                </MemberCard.Name>
                <MemberCard.Position>
                  {member.position}
                </MemberCard.Position>
                <MemberCard.Email href={`mailto:${member.email}`}>
                  {member.email}
                </MemberCard.Email>
              </MemberCard>
            ))}
          </MemberGroupSx>
        </InnerSx>
      </ContainerSx>
      <SnackbarSx
        open={errorMessage.length > 0}
        autoHideDuration={SNACKBAR_DURATION}
        onClose={onSnackbarClose}
        message={errorMessage}
      />
    </PageTemplate>
  );
}
