const crypto = globalThis.crypto;
import { Buffer } from 'buffer';

const getPubKey = async (obj) => {
    return await crypto.subtle.importKey("jwk", {
        "kty": obj.kty,
        "n": obj.n,
        "e": obj.e,
    }, { name: 'RSA-OAEP', hash: { name: 'SHA-256' }, }, true, ["encrypt", "wrapKey"]);
}

const rsaEncrypt = async (stringVal, keyObj) => {
    const key = await getPubKey(keyObj);

    const enc = new TextEncoder();
    const val = enc.encode(stringVal);
    const val2 = await crypto.subtle.encrypt(
        { name: "RSA-OAEP", },
        key,
        val
    );
    return Buffer.from(val2, 'hex').toString("base64");
}

export default rsaEncrypt;