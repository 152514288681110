import React, {
    useState,
    useEffect,
} from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Button,
    Modal,
    Box,
    TextField,
    FormControl,
    CircularProgress,
    Select,
    MenuItem,
    InputLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const { updateProfExp, default: professionalExperienceApprovalPageController } = require('../controller/prof_exp_approval.controller');

export const TableProfessionalExperiencesNewApproval = ({ tableBody }) => {
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
                <TableHead>
                    <TableRow>
                        <TableCell align='left'>Name</TableCell>
                        <TableCell align='left'>Class</TableCell>
                        <TableCell align='left'>Event Date</TableCell>
                        <TableCell align='left'>Submission Date</TableCell>
                        <TableCell align='left'>Description</TableCell>
                        <TableCell align='left'>Reflection</TableCell>
                        <TableCell align='left'>Status</TableCell>
                        <TableCell align='left'>Update</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableBody}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const approvalOptionList = [
    { value: 0, label: "Pending" },
    { value: 1, label: "Approved" },
    { value: 2, label: "Rejected" },
];

export const NewExpRow = ({ expID, name, yearGrad, unixSubmit, unixEvent, approvalStat, location, description, reflection, imgPath, reviewerID, reviewerComment, reviewerName, newHoursTable = true, isCoChair = false }) => {

    let status = "";
    let statusColor = "darkorange"
    if (approvalStat == 1) {
        status = "Approved";
        statusColor = "green";
    } else if (approvalStat == 2) {
        status = "Rejected";
        statusColor = "red";
    } else {
        status = "Pending";
    }

    // Account for newlines in text 
    const descGen = (description) => {
        const descParts = description.replaceAll('\r\n', '\n').split('\n')
        const results = [];
        descParts.forEach(line => {
            results.push(<Typography>{line}</Typography>)
        });
        return results;
    }

    return (
        <TableRow>
            <TableCell>{name}</TableCell>
            <TableCell>{yearGrad}</TableCell>
            <TableCell>{new Date(unixEvent * 1000).toLocaleDateString()}</TableCell>
            <TableCell>{new Date(unixSubmit * 1000).toLocaleDateString()}</TableCell>
            <TableCell>{descGen(description)}</TableCell>
            <TableCell>{descGen(reflection)}</TableCell>
            <TableCell style={{ color: statusColor }}>{status}</TableCell>
            <TableCell><ExpDetailsModal expID={expID} name={name} yearGrad={yearGrad} unixSubmit={unixSubmit} unixEvent={unixEvent} approvalStatus={approvalStat} location={location} description={description} reflection={reflection} imgPath={imgPath} reviewerID={reviewerID} reviewerComment={reviewerComment} reviewerName={reviewerName} newHoursTable={newHoursTable} isCoChair={isCoChair}></ExpDetailsModal></TableCell>
        </TableRow>
    );
};

const WHITE = '#FFFFFF';
const CLARKSON_GREEN = '#004F42';
const CLARKSON_YELLOW = '#F3D200';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    height: '50%',
    bgcolor: WHITE,
    border: '4px solid ' + CLARKSON_GREEN,
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
}

const modalStyleOverlay = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    height: '50%',
    bgcolor: '#3e3e3e3e',
    border: '4px solid ' + CLARKSON_GREEN,
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
}

const waitCircleStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'none',
}

const imgStyle = {
    width: '100%',
    height: '75%',
}

export const ApproveExperienceButton = styled(Button)`
    font-size: 15 px;
    font-weight: bold;
    margin-top: 15px;
    margin-botton: 5px;
    width: 100%;
    background-color: green;
`;
ApproveExperienceButton.defaultProps = {
    type: 'submit',
    variant: 'contained',
};

export const UpdateExperienceButton = styled(Button)`
    font-size: 15 px;
    font-weight: bold;
    margin-top: 15px;
    margin-botton: 5px;
    width: 100%;
`;

UpdateExperienceButton.defaultProps = {
    type: 'submit',
    variant: 'contained',
};

const descGen = (description) => {
    const descParts = description.split('\r\n')
    const results = [];
    descParts.forEach(line => {
        results.push(<Typography>{line}</Typography>)
    });
    return results;
}

export const ExpDetailsModal = ({ expID, name, yearGrad, unixSubmit, unixEvent, approvalStatus, location, description, reflection, imgPath, reviewerID, reviewerComment, reviewerName, newHoursTable, isCoChair }) => {
    const [open, setOpen] = useState(false);
    const [openPending, setOpenPending] = useState(false);
    const [modalApprovalStatus, setModalApprovalStatus] = useState('');
    const [comment, setComment] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const openModal = () => {
        setOpen(true);
    }
    const closeModal = () => {
        setOpen(false);
    }

    const openModalOverlay = () => {
        setOpenPending(true);
    }
    const closeModalOverlay = () => {
        setOpenPending(false);
    }

    const getImage = (imgPath) => {
        if (imgPath == null || imgPath.length < 2) {
            return <Typography>&emsp;No Image Present</Typography>;
        }
        // return <img src="https://drive.google.com/thumbnail?id=1e6un5AsK7bdzKpIo1rq0-2hQJ0VsHSVm" style={imgStyle} alt='Image Failed to Render' />;
        return <iframe src={"https://drive.google.com/file/d/" + imgPath + "/preview"} style={imgStyle} allow="autoplay"></iframe>;
        //return <img src={"https://drive.usercontent.google.com/download?id=" + imgPath} style={imgStyle} alt='Image Failed to Render'></img>;
    }

    const defaultStat = (approvalStatus) => {
        setModalApprovalStatus(approvalStatus);
        setComment(reviewerComment);
    }

    const presetApproved = () => {
        setComment('');
        setModalApprovalStatus(1);
    }

    const presetRejected = () => {
        setComment('');
        setModalApprovalStatus(2);
    }

    const presetRejectedLate = () => {
        setComment('Rejected: Professional Experience turned in 3 months (1 semester) past event date.');
        setModalApprovalStatus(2);
    }

    useEffect(() => {
        defaultStat(approvalStatus);
    }, []);

    const submitExperienceUpdate = async (approvalStat, comment, id) => {
        event.preventDefault();
        if (approvalStat == null) {
            setErrorMessage('Error: Ensure approval status is set.')
        } else {
            setErrorMessage('');
            setOpenPending(true);
            if (newHoursTable) {
                localStorage.setItem('scrollposnew', window.scrollY);
            }
            const response = await professionalExperienceApprovalPageController.updateProfExp(approvalStat, comment, id);
            setOpenPending(false);
            window.location.reload();
        }
    }

    const setAutoApproveUpdate = (newHoursTable, comment, experienceID) => {
        if (newHoursTable) {
            return (<ApproveExperienceButton style={{ display: isCoChair ? 'none' : '' }} onClick={() => submitExperienceUpdate(1, comment, experienceID)}>Approve Requested Professional Experience</ApproveExperienceButton>);
        }
    }

    return (
        <div>
            <Button onClick={openModal}>{(newHoursTable) ? "More/Approval" : "More/Update"}</Button>
            <Modal open={openPending} onClose={closeModalOverlay}>
                <Box sx={modalStyleOverlay}>
                    <CircularProgress style={waitCircleStyle} />
                </Box>
            </Modal>
            <Modal open={open} onClose={closeModal} aria-labelledby={"modal-title-" + expID} aria-describedby={"modal-desc-" + expID}>
                <Box sx={modalStyle}>
                    <Typography style={{ fontSize: '24px' }} id={"modal-title-" + expID + ((newHoursTable) ? 'new' : 'old')}>{name} - {yearGrad}</Typography>
                    <Typography id={"modal-desc-" + expID + ((newHoursTable) ? 'new' : 'old')}>{name}</Typography>
                    <hr />
                    <table style={{ border: '1px solid white', borderCollapse: 'collapse', width: '100%' }}>
                        <tr>
                            <td style={{ verticalAlign: 'top' }}><Typography><strong>Event Date: </strong></Typography></td>
                            <td style={{ verticalAlign: 'top' }}><Typography>{new Date(unixEvent * 1000).toLocaleDateString()}</Typography></td>
                        </tr>
                        <tr>
                            <td style={{ verticalAlign: 'top' }}><Typography><strong>Submission Date: </strong></Typography></td>
                            <td style={{ verticalAlign: 'top' }}><Typography>{new Date(unixSubmit * 1000).toLocaleDateString()}</Typography></td>
                        </tr>
                        <tr>
                            <td style={{ verticalAlign: 'top' }}><Typography><strong>Location: </strong></Typography></td>
                            <td style={{ verticalAlign: 'top' }}><Typography>{location}</Typography></td>
                        </tr>
                        <tr>
                            <td style={{ verticalAlign: 'top' }}><Typography><strong>Description: </strong></Typography></td>
                            <td style={{ verticalAlign: 'top' }}><Typography>{descGen(description)}</Typography></td>
                        </tr>
                        <tr>
                            <td style={{ verticalAlign: 'top' }}><Typography><strong>Reflection: </strong></Typography></td>
                            <td style={{ verticalAlign: 'top' }}><Typography>{descGen(reflection)}</Typography></td>
                        </tr>
                    </table>
                    <hr />
                    <Typography><strong>Image:</strong></Typography>
                    {getImage(imgPath)}
                    <hr />
                    <Typography><strong>Event Approved:</strong></Typography>
                    <table style={{ width: '100%' }}>
                        <tr>
                            <td><Typography><strong>Presets:&emsp;&emsp;</strong></Typography></td>
                            <td><Button onClick={presetApproved} style={{ color: 'green' }}>Approved</Button></td>
                            <td><Button onClick={presetRejected} style={{ color: 'red' }}>Rejected</Button></td>
                            <td><Button onClick={presetRejectedLate} style={{ color: 'darkorange' }}>Late</Button></td>
                        </tr>
                    </table>
                    <br />
                    <Typography><strong>Previous Approver: </strong>{(reviewerName == null) ? "N/A" : reviewerName}</Typography>
                    <br />
                    <FormControl style={{ width: '100%' }}>
                        <table style={{ width: '100%' }}>
                            <tr>
                                <td colSpan='2'>
                                    <InputLabel id={'event-' + expID + ((newHoursTable) ? 'new' : 'old')} style={{ marginTop: '0.25rem' }}>Approval Status</InputLabel>
                                    <Select value={modalApprovalStatus} onChange={(e) => setModalApprovalStatus(e.target.value)} label="Approval Status" labelId={'event-' + expID + ((newHoursTable) ? 'new' : 'old')} style={{ width: '10rem' }}>
                                        {approvalOptionList.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                        ))}
                                    </Select>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan='2'><TextField style={{ width: '100%' }} label="Comments" value={comment} onChange={(e) => { setComment(e.target.value); }}>{comment}</TextField></td>
                            </tr>
                        </table>
                        <Typography style={{ color: 'red' }}>{errorMessage}</Typography>
                        <UpdateExperienceButton style={{ display: isCoChair ? 'none' : '' }} onClick={() => submitExperienceUpdate(modalApprovalStatus, comment, expID)}>Update Professional Experience Hours</UpdateExperienceButton>
                        {setAutoApproveUpdate(newHoursTable, comment, expID)}
                    </FormControl>
                </Box>
            </Modal>
        </div>
    );
}

export const ViewToggle = ({ idNewEvents, idAllEvents, idStudent }) => {
    const goToOverall = (event) => {
        document.getElementById(idAllEvents).style.display = "flex";
        document.getElementById(idNewEvents).style.display = "none";
        document.getElementById(idStudent).style.display = "none";
    };

    const goToNew = (event) => {
        document.getElementById(idAllEvents).style.display = "none";
        document.getElementById(idNewEvents).style.display = "flex";
        document.getElementById(idStudent).style.display = "none";
    };

    const goToStudent = (event) => {
        document.getElementById(idAllEvents).style.display = "none";
        document.getElementById(idNewEvents).style.display = "none";
        document.getElementById(idStudent).style.display = "flex";
    }

    return (
        <div style={{ maxWidth: 'max-content' }}>
            <Button onClick={goToOverall}>Student Overview</Button>
            <Button onClick={goToNew}>Manage New Experiences</Button>
            <Button onClick={goToStudent} id="studentIndividualTab" style={{ display: 'none' }}>Individual Student</Button>
        </div>
    );
};
