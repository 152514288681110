import {
  Box,
  Button,
  Typography,
} from '@mui/material';
import styled from '@mui/material/styles/styled';
import { Link as ReactRouterLink } from 'react-router-dom';

// Error Page container styles =================================================

export const ContainerSx = styled(Box)`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 5px 5%;
  
`;

// Error Page content styles ===================================================

export const ErrorButtonSx = styled(Button)`
  border-radius: 40px;
  font-size: 25px;
`;
ErrorButtonSx.defaultProps = {
  component: ReactRouterLink,
  variant: 'contained',
};

export const ErrorImageSx = styled('img')`
  height: 160px;
  margin-bottom: 20px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    height: 125px;
  }
`;

export const HeadingSx = styled(Typography)`
  font-size: 50px;
  text-align: center;
  margin-bottom: 5px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size: 25px;
  }
`;
HeadingSx.defaultProps = {
  variant: 'h1',
};

export const SubHeadingSx = styled(Typography)`
  font-size: 20px;
  text-align: center;
  margin-bottom: 50px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size: 15px;
    margin: 0px 0px 20px 0px;
    width: 90%;
  }
`;
SubHeadingSx.defaultProps = {
  variant: 'h2',
};
