import React, {
    useState,
    useEffect,
} from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const { updateProfExp, default: professionalExperienceApprovalPageController } = require('../controller/prof_exp_approval.controller');

export const TableAllStudentOverview = ({ tableBody }) => {
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
                <TableHead>
                    <TableRow>
                        <TableCell align='left'>Student Name</TableCell>
                        <TableCell align='left'>Total Professional Experiences</TableCell>
                        <TableCell align='left'>Graduation Date</TableCell>
                        <TableCell align='left'>Experiences Remaining</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableBody}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

const colorCompletionArray = ['#6de36d', '#c2efc2', '#efefc3', '#efd9c3', '#efc3c3'];

export const StudentRow = ({ studentID, user, name, gradYear, expReq, expDone }) => {
    const ratio = expDone / expReq;
    let color;
    if (ratio <= 0.25) {
        color = colorCompletionArray[4];
    } else if (ratio <= 0.5) {
        color = colorCompletionArray[3];
    } else if (ratio <= 0.75) {
        color = colorCompletionArray[2];
    } else if (ratio < 1) {
        color = colorCompletionArray[1];
    } else {
        color = colorCompletionArray[0];
    }

    return (
        <TableRow style={{ backgroundColor: color }}>
            <TableCell><Button className="studentSubEvents" id={user} name={name}>{name}</Button></TableCell>
            <TableCell>{parseFloat(expDone)}</TableCell>
            <TableCell>{gradYear}</TableCell>
            <TableCell>{(expDone - expReq < 0) ? (expReq - expDone) : 0}</TableCell>
        </TableRow>
    );
};

export const FilterButton = styled(Button)`
    font-size: 15 px;
    font-weight: bold;
    margin-top: 15px;
    margin-botton: 5px;
    width: 100%;
`;
FilterButton.defaultProps = {
    type: 'submit',
    variant: 'contained',
};
