import React, {
    ReactElement,
} from 'react';
import {
    Routes,
    Route,
} from 'react-router-dom';
import LandingPage from 'professional_experience_approval_page/landing_page';
import ErrorPage from 'error_page';

/**
 * Component for prof. exp. page. The service page consists of two sub-routes:
 * landing page and submission page
 *
 * @returns {ReactElement} the service page component.
 */

export default function ProfessionalExperienceApprovalPage() {
    return (
        <Routes>
            <Route exact path="/" element={<LandingPage />} />
            <Route exact path="*" element={<ErrorPage />} />
        </Routes>
    );
}
