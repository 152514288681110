import React, {
    useEffect,
    useState,
} from 'react';
import { useAccount } from 'account';
import { AccountPermissions } from 'account/view_model/account.viewmodel';
import PageTemplate from 'page_template';
import HeaderCard from 'components/header_card';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';
import * as PATHS from 'routing/paths';
import {
    Heading,
} from '../service_page/styles/landing_page.style';
import submitProfExpPageController from './controller/submit_prof_exp.controller';
import {
    ColumnsSx,
    ServiceSubmitButtonSx,
    ServiceSubmitDescSx,
    ServiceSubmitFormSx,
    BlankField,
    ImageUploadSx,
    ImageDataSx,
    ServiceSubmitErrorTextSx,
    ContainerSx
} from '../service_page/styles/submit_page.style';
import {
    ProfExpSubmitEventName,
    ProfExpSubmitReflection,
    ProfExpSubmitDate,
    ProfExpSubmitEventLocation,
    ViewToggle,
} from './styles/submit_profexp.style';
import {
    TableProfExpPast,
    TableProfExpPastRow,
} from './styles/past_prof_exp.style';
import {
    CircularProgress,
} from '@mui/material';

export default function ProfExpSubmit() {
    const navigate = useNavigate();
    const location = useLocation();
    const { account, refreshAccount } = useAccount();

    const [eventName, setEventName] = useState('');
    const [eventLocation, setEventLocation] = useState('');
    const [description, setDescription] = useState('');
    const [reflection, setReflection] = useState('');
    const [date, setDate] = useState('');
    const [photo, setPhoto] = useState('');
    const [photoSize, setPhotoSize] = useState('');
    const [errorText, setErrorText] = useState('');
    const [errorPastText, setErrorPastText] = useState('');

    const [requiredExp, setRequiredExp] = useState(0);
    const [doneExp, setDoneExp] = useState(0);
    const [chartData, setChartData] = useState([]);

    const onProfExpSubmit = (event) => {
        event.preventDefault();

        // Validate form - all inputs are in, else show modal
        if (eventName.length < 1 || description.length < 1 || reflection.length < 1 || date.length < 1 || photo.length < 1 || photoSize.length < 1) {
            setErrorText('Error: Please fill out all fields on the form.');
        } else if (eventName.toString().includes(";") || description.toString().includes(";") || reflection.toString().includes(";")) {
            setErrorText('Error: Don\'t include semicolons.');
        } else if (isNaN(new Date(date))) {
            setErrorText('Error: Invalid Date, please reenter.');
        } else if (photoSize > 5) {
            setErrorText('Error: Image File is too large. Max image size is 5 MiB.');
        } else {
            document.getElementById('loadingCircle').style.display = 'flex';
            const photoData = new FormData();
            photoData.append("file", photo);
            photoData.append("filename", `${account.username}-${Date.now()}`);
            submitProfExpPageController.sendProfExp({ name: eventName, date: date / 1000, location: eventLocation, description: description, reflection: reflection }, photoData).then(
                (response) => {
                    if (response.header.status) {
                        // Success sending hours
                        document.getElementById('loadingCircle').style.display = 'none';
                        refreshAccount();
                        setEventName('');
                        setEventLocation('');
                        setDescription('');
                        setDate('');
                        setErrorText('Success! You can now leave this page!');
                        setPhoto('');
                        setReflection('');
                        setErrorPastText('');
                        document.getElementById('image-button').innerText = "Upload Image *"
                        document.getElementById('imageSize').innerText = "None Selected";
                    } else {
                        // Error
                        setErrorText(response.errorMessage.length > 1 ? response.errorMessage : 'Error connecting to server. Contact a webmaster.');
                    }
                }
            );
        }
    };

    const requestPastProfExp = () => {
        refreshAccount();
        submitProfExpPageController.getProfExp(true, "null").then((response) => {
            // Parse Old User Data
            let rspData = [];
            let doneCount = 0;
            // console.log(response.studentProfExps);
            Object.values(response.studentProfExps).forEach(experience => {
                rspData.push(<TableProfExpPastRow date={experience.eventDate} name={experience.event_name} description={experience.description} reflection={experience.reflection} approvalStatus={experience.approval_status} reason={experience.reviewer_comment}></TableProfExpPastRow>);
                doneCount = experience.approval_status != 0 ? doneCount + 1 : doneCount;
                setRequiredExp(experience.required_prof_exp);
            });
            setDoneExp(doneCount);
            setChartData(rspData);
            setErrorPastText('');
        }).catch((e) => {
            console.log(e);
            setErrorPastText('Error connecting to database. Please contact the webmaster.');
        });
    }

    useEffect(() => {
        if (account === null || [AccountPermissions.INVALID, AccountPermissions.UNAUTH].includes(account.permissions) === true) {
            navigate(PATHS.LOGIN, { state: { from: location.pathname }, replace: true });
        } else {
            requestPastProfExp();
        }

    }, []);

    return (
        <PageTemplate active="professional experience">
            <HeaderCard bkgSrc="/images/headers/profexp_submit.png">
                <HeaderCard.Title>Submit Professional Experiences</HeaderCard.Title>
            </HeaderCard>
            <ContainerSx style={{ paddingBottom: "0rem" }}>
                <ViewToggle idPast="pastExperiences" idSubmit="submitProfessionalExperienceForm" />
            </ContainerSx>
            <ContainerSx id="submitProfessionalExperienceForm" style={{ paddingTop: "10px" }}>
                <Heading style={{ textAlign: 'center', paddingLeft: "10px", paddingRight: "10px" }}>
                    Fill out the form below to submit your attendance to a professional experience.
                </Heading>
                <ServiceSubmitFormSx onSubmit={onProfExpSubmit}>
                    <ProfExpSubmitEventName
                        value={eventName}
                        onChange={(e) => setEventName(e.target.value)}
                        label="Event Name"
                        id="eventName"
                        name="eventName"
                        required={true}
                    />
                    <ProfExpSubmitEventLocation
                        value={eventLocation}
                        onChange={(e) => setEventLocation(e.target.value)}
                        label="Event Location"
                        id="eventLocation"
                        name="eventLocation"
                        required={true}
                    />
                    <ProfExpSubmitDate
                        value={date}
                        onChange={(e) => {
                            try { setDate(e._d.getTime()); }
                            catch {
                                // Empty
                            }
                        }}
                        label="Date of Event"
                        id="date"
                        name="date"
                        helperText="MM/DD/YYYY"
                        renderInput={(params) => <BlankField {...params} />}
                    />
                    <ServiceSubmitDescSx
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        label="Description"
                        id="description"
                        name="description"
                        multiline={true}
                    />
                    <ProfExpSubmitReflection
                        value={reflection}
                        onChange={(e) => setReflection(e.target.value)}
                        label="Reflection"
                        id="reflection"
                        name="reflection"
                        multiline={true}
                    />
                    <ColumnsSx id="image-data">
                        <ImageUploadSx buttonText='Upload Image *' inputId='image-input' buttonId='image-button'
                            value={photo}
                            handleChange={(e) => {
                                setPhoto(e.target.files[0]);
                                document.getElementById('image-button').innerText = e.target.files[0].name;
                                document.getElementById('imageSize').innerText = Math.ceil(e.target.files[0].size / 1024 / 1024 * 100) / 100 + " MiB";
                                setPhotoSize(Math.ceil(e.target.files[0].size / 1024 / 1024 * 100) / 100);
                            }}
                        />
                        <ImageDataSx
                            imageSizeId='imageSize'
                        />
                    </ColumnsSx>
                    <ServiceSubmitButtonSx>Submit</ServiceSubmitButtonSx>
                    <ContainerSx id='loadingCircle' sx={{ display: 'none', paddingTop: '2rem' }}>
                        <CircularProgress />
                    </ContainerSx>
                </ServiceSubmitFormSx>
                <ServiceSubmitErrorTextSx>{errorText}</ServiceSubmitErrorTextSx>
            </ContainerSx>
            <ContainerSx id="pastExperiences" style={{ paddingTop: "10px", display: 'none' }}>
                <Heading>
                    Previous Experiences: {doneExp} / {requiredExp}
                </Heading>
                <TableProfExpPast
                    tableBody={chartData}
                >
                </TableProfExpPast>
                <ServiceSubmitErrorTextSx>{errorPastText}</ServiceSubmitErrorTextSx>
            </ContainerSx>
        </PageTemplate>
    );
}