import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const TableServiceHoursPast = ({ tableBody }) => {
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
                <TableHead>
                    <TableRow>
                        <TableCell align='left'>Service Date</TableCell>
                        <TableCell align='left'>Hours Approved</TableCell>
                        <TableCell align='left'>Type</TableCell>
                        <TableCell align='left'>Description</TableCell>
                        <TableCell align='left'>Status</TableCell>
                        <TableCell align='left'>Reason</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableBody}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export const TableServiceHoursRow = ({ date, minReq, minAppr, type, desc, approver, reason }) => {
    const hoursReq = minReq.hours + (minReq.minutes / 60);
    const hoursAppr = minAppr.hours + (minAppr.minutes / 60);

    let status = "";
    let statusColor = "darkorange"
    if ((hoursAppr != 0 || hoursAppr == null) && approver.length > 0) {
        status = "Approved";
        statusColor = "green";
    } else if ((hoursAppr == 0 || hoursAppr == null) && approver.length > 0) {
        status = "Rejected";
        statusColor = "red";
    } else {
        status = "Pending";
    }

    return (
        <TableRow>
            <TableCell>{new Date(date.unixTime * 1000).toLocaleDateString()}</TableCell>
            <TableCell>{hoursAppr} / {hoursReq}</TableCell>
            <TableCell>{type}</TableCell>
            <TableCell>{desc}</TableCell>
            <TableCell><p style={{ color: statusColor }}>{status}</p></TableCell>
            <TableCell>{reason}</TableCell>
        </TableRow>
    );
}
