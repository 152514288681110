import { styled } from "@mui/system";
import {
    Box,
    Typography,
} from '@mui/material';

export const Container = styled('div')`
    font-size: 20px;
    color: black;
`;

export const Inner = styled('div')`
    display: flex;
    flex-direction: column;
    padding: 50px 5%;
    max-width: 1100px;
    margin: auto;
`;

export const ColumnsSx = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: inherit;
    justify-content: space-between;
`;

export const Innerr = styled(Typography)`
    display: flex;
    flex-direction: column;
    padding: 50px 5%;
    max-width: 1100px;
    margin: auto;
`;

export const Heading1 = styled(Typography)`
    font-size: 36px;
    font-weight: bold;
    color: #004e42;
    text-align: center;
`;

export const Heading2 = styled(Typography)`
    font-size: 52px;
    font-weight: bold;
    color: #ffcd00;
    text-align: center;
`;