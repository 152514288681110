import ViewModel from "pattern/view_model";

export default class UserProfileUpdatePWViewModel extends ViewModel {
    constructor(isSuccessful, errorMessage) {
        super();
        this.isSuccessful = isSuccessful;
        this.errorMessage = errorMessage;
    }
}

const create = ({
    isSuccessful = false,
    errorMessage = '',
} = {}) => { new UserProfileUpdatePWViewModel(isSuccessful, errorMessage) };

export const UserProfileUpdatePWViewModelFactory = {
    create,
}