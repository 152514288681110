import {
  COMMITTEE_IDS,
} from 'about_page/view_model/steering_committee.viewmodel';
import ViewModel from 'pattern/view_model';

/**
 * ViewModel for the Committee.
 *
 * @property {String} id the id of the committee member.
 * @property {String} name the name of the committee member.
 * @property {String} image the path of the committee member image.
 * @property {String} email the email of the commmittee member.
 * @property {String} committee the id of the steering committee the member
 * belongs to.
 * @property {String} position the position of the steering committee member.
 */
export default class SteeringMemberViewModel extends ViewModel {
  /**
   * @param {String} id the id of the committee member.
   * @param {String} name the name of the committee member.
   * @param {String} image the path of the committee member image.
   * @param {String} email the email of the commmittee member.
   * @param {String} committee the id of the steering committee the member
   * belongs to.
   * @param {String} position the position of the steering committee member.
   */
  constructor(id, name, image, email, committee, position) {
    super();

    this.id = id;
    this.name = name;
    this.image = image;
    this.email = email;
    this.committee = committee;
    this.position = position;
  }
}

/**
 * Creates a new instance of the {@link SteeringMemberViewModel}.
 *
 * @param {Object} kwargs
 * @param {String} kwargs.id
 * @param {String} kwargs.name
 * @param {String} kwargs.image
 * @param {String} kwargs.email
 * @param {String} kwargs.committee
 * @param {String} kwargs.position
 *
 * @returns {SteeringMemberViewModel} the new instance.
 */
const create = ({
  id = '',
  name = '',
  image = '',
  email = '',
  committee = '',
  position = '',
} = {}) => new SteeringMemberViewModel(
  id,
  name,
  image,
  email,
  committee,
  position,
);

/**
 * Converts a POJO into a new instance of the {@link SteeringMemberViewModel}.
 *
 * @param {Object} model object model for summary.
 *
 * @returns {SteeringMemberViewModel} the new instance.
 */
const convert = (model) => {
  if (typeof model.id !== 'string') {
    throw new Error('the model.id is not valid');
  }
  if (typeof model.name !== 'string') {
    throw new Error('the model.name is not valid');
  }
  if (typeof model.image !== 'string') {
    throw new Error('the model.image is not valid');
  }
  if (
    typeof model.email !== 'string' ||
    (model.email !== '' && !model.email.endsWith('@clarkson.edu'))
  ) {
    throw new Error('the model.email is not valid');
  }
  if (model.committee !== 'none' && !COMMITTEE_IDS.has(model.committee)) {
    throw new Error('the model.committee is not valid');
  }
  if (typeof model.position !== 'string') {
    throw new Error('the model.position is not valid');
  }

  return new SteeringMemberViewModel(
    model.id,
    model.name,
    model.image,
    model.email,
    model.committee,
    model.position,
  );
};

export const steeringMemberViewModelFactory = {
  create,
  convert,
};
