import PropTypes from 'prop-types';
import React, {
  Context,
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import accountController from 'account/controller/account.controller';
import AccountViewModel from 'account/view_model/account.viewmodel';

/**
 * @typedef TAccountContext
 * @property {AccountViewModel?} account
 * @property {() => null} refreshAccount
*/

/** @type {Context<TAccountContext>} Context object for the user account. */
const AccountContext = createContext({
  account: null,
  refreshAccount: () => {},
});

/**
 * @param {Object} params
 * @param {ReactElement} params.children children of the AccountProvider
 * received the  account data.
 *
 * @returns the component
 */
export function AccountProvider({ children }) {
  // react state for user account to be provided to app components
  const [account, setAccount] = useState(null);

  const refreshAccount = () => {
    accountController.readAccount()
      .then((newAccount) => {
        setAccount(newAccount);
      })
      .catch(() => setAccount(null));
  };

  // gets user account on load, if a valid session exists
  useEffect(() => {
    refreshAccount();
  }, []);

  const value = useMemo(() => ({ account, refreshAccount }), [account]);
  return (
    <AccountContext.Provider value={value}>
      {children}
    </AccountContext.Provider>
  );
}
AccountProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

/**
 * Provides the react hooks for the account.
 *
 * @returns {TAccountContext} react hooks for account.
 */
export const useAccount = () => useContext(AccountContext);
