import React, {
    ReactElement,
    useEffect,
} from 'react';
import PageTemplate from 'page_template';
import HeaderCard from 'components/header_card';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';
import * as PATHS from 'routing/paths';

import {
    Container,
} from '../resources_page/styles/resources_page.style';
import { Innerr, Heading1, Heading2 } from 'textbooks_page/styles/textbooks_page.style';

export default function ReunionFAQLandingPage() {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <PageTemplate active="faq" reunion={true}>
            <HeaderCard bkgSrc="/images/headers/SpringAutumnSidewalk.jpg">
                <HeaderCard.Title>FAQ</HeaderCard.Title>
            </HeaderCard>
            <Container>
                <Innerr>
                    <Heading1>Clarkson University Honors Program Alumni Reunion Weekend: Summer 2025</Heading1>
                    <br/>
                    <Heading2>More details to come!</Heading2>
                </Innerr>
            </Container>
        </PageTemplate>
    );
}