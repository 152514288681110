import styled from '@mui/material/styles/styled';
import { Link as ReactRouterLink } from 'react-router-dom';

export const Heading = styled('h1')`
    font-size: 25px;
    color: black;
    margin: 10px 0px 5px 0px;
    text-align: start;
    line-height: 150%;
`;

export const Content = styled('div')`
    font-size: 18px;
    color: black;
    text-align: start;
    line-height: 150%;
`;

export const ListSx = styled('ul')`
    font-size: 18px;
    color: black;
    margin: 0px;
`;

export const OrderedListSx = styled('ol')`
    font-size: 18px;
    color: black;
    margin: 0px;
`;

export const ListItemSx = styled('li')`
    text-align: start;
    line-height: 150%;
`;

export const SubmitServiceContainer = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
`;

export const SubmitServiceText = styled(ReactRouterLink)`
    width: 400px;
    padding: 10px 0px;
    font-size: 30px;
    text-transform: none;
    text-decoration: none;
    color: white;
    font-weight: bold;
`;

export const ContainerSx = styled('div')`
  color: black;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 1100px;
  padding: 50px;
  font-family: "Roboto", san-serif;
`;
