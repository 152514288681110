import ViewModel from 'pattern/view_model';

/** ViewModel for the login. */
export default class LoginViewModel extends ViewModel {
  /**
   * @param {Boolean} isSuccessful whether the login is successful.
   * @param {String} errorMessage error message if the login is not successful.
   */
  constructor(isSuccessful, errorMessage) {
    super();

    this.isSuccessful = isSuccessful;
    this.errorMessage = errorMessage;
  }
}

/**
 * Creates a new instance of the the login view-model.
 *
 * @param {Object} kwargs
 * @param {Boolean} kwargs.isSuccessful whether the login is successful.
 * @param {String} kwargs.errorMessage error message if the login is not
 * successful.
 *
 * @returns {LoginViewModel} viewmodel for the document category.
 */
const create = ({
  isSuccessful = false,
  errorMessage = '',
} = {}) => new LoginViewModel(isSuccessful, errorMessage);

export const loginViewModelFactory = {
  create,
};
