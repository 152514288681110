import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styled from '@mui/material/styles/styled';

// Header Card container styles ================================================

export const ContainerSx = styled(Box)`
  background: url('${(props) => props.bkgsrc}');
  background-blend-mode: multiply;
  background-color: rgba(0, 79, 66, 0.8);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  height: 500px;
  justify-items: center;
  width: 100%;

  ${(props) => props.theme.breakpoints.down('sm')} {
    height: 400px;
  }

  ${(props) => props.theme.breakpoints.down('xs')} {
    height: 300px;
  }
`;
ContainerSx.defaultProps = {
  component: 'div',
};

export const InnerSx = styled(Box)`
    align-items: center;
    display: flex;
    flex-direction: column;
    grid-area: 1 / 1;
    justify-content: center;
`;
ContainerSx.defaultProps = {
  component: 'div',
};

// Header Card content styles ==================================================

export const TitleSx = styled(Typography)`
    color: white;
    font-size: 80px;
    font-weight: bold;
    margin: 5px;
    text-align: center; 

    ${(props) => props.theme.breakpoints.down('sm')} {
      font-size: 65px;
    }

    ${(props) => props.theme.breakpoints.down('xs')} {
      font-size: 50px;
    }
`;
ContainerSx.defaultProps = {
  component: 'h1',
};

export const SubTitleSx = styled(Typography)`
  color: white;
  margin: 10px;
  font-size: 50px;
  text-align: center;

  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size: 45px;
  }

  ${(props) => props.theme.breakpoints.down('xs')} {
    font-size: 30px;
  }
`;
ContainerSx.defaultProps = {
  component: 'h2',
};

export const DividerSx = styled(Box)`
    border-bottom: 3px solid white;
    display:block;
    width: 50%;
    
`;
ContainerSx.defaultProps = {
  component: 'span',
};
