import React, {
    ReactElement,
} from 'react';
import PageTemplate from 'page_template';
import HeaderCard from 'components/header_card';
import Button from 'components/button';
import * as PATHS from 'routing/paths';
import {
    Container,
    Innerr,
    ColumnsSx,
} from '../textbooks_page/styles/textbooks_page.style';
import {
    ListSx,
    ListItemSx,
} from '../service_page/styles/landing_page.style.js';
import {
    Heading,
    Content,
    TextbooksContainer,
    TextbooksText,
} from '../textbooks_page/styles/landing_page_textbooks.style';
import {
    List,
    ListItem,
} from '../textbooks_page/styles/landing_page_textbooks.style';

export default function ProfExpHomePage() {
    return (
        <PageTemplate active="textbooks">
            <HeaderCard bkgSrc="/images/headers/ProfExp.jpg">
                <HeaderCard.Title>Pre-Professional Events</HeaderCard.Title>
            </HeaderCard>
            <Container>
                <Innerr>
                    <ProfExpHomePage.SubmitProfExp>SUBMIT ATTENDANCE</ProfExpHomePage.SubmitProfExp>
                    <Content>
                        Similar to the service requirement, every Honors student must attend pre-professional events. Starting
                        with the Class of 2028, students must attend at least 4 pre-professional events. The Class of 2027
                        must also complete the pre-professional requirement, but are only required to attend 3 events. To document attendance
                        at these events, students will need to take a picture of themselves in front of a unique image presented by
                        the individual hosting the event, or in fron of the last slide of the presentation.
                    </Content>
                    <Heading>Goals of Pre-Professional Events</Heading>
                    <Content>
                        <ListSx>
                            <ListItemSx>Teach students how to interact professionally</ListItemSx>
                            <ListItemSx>Bring awareness to students of opportunities</ListItemSx>
                            <ListItemSx>Preparment sessions for professional opportunities</ListItemSx>
                        </ListSx>
                    </Content>
                    <Heading>Examples of Pre-Professional Events</Heading>
                    <Content>
                        <ListSx>
                            <ListItemSx>Writing sessions for scholarships/internships</ListItemSx>
                            <ListItemSx>Alumni Panels</ListItemSx>
                            <ListItemSx>Career fair prep</ListItemSx>
                            <ListItemSx>Career Panels</ListItemSx>
                        </ListSx>
                    </Content>
                    <Content>
                        ***Pre-professional events are informative sessions that benefit students after graduation.
                        Only Honors-specific events count towards this graduation requirement.***
                    </Content>
                </Innerr>
            </Container>
        </PageTemplate>
    );
}

ProfExpHomePage.SubmitProfExp = function SubmitProfExpButton({ children, ...otherProps }) {
    return (
        <TextbooksContainer>
            <Button variant="contained" border="rounded" {...otherProps}>
                <TextbooksText to={PATHS.PROFEXP + '/submit-professional-experiences'} state={{ from: location.pathname }}>{children}</TextbooksText>
            </Button>
        </TextbooksContainer>
    );
};