import React from 'react';
import {
    Table,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
} from '@mui/material';

export const UserProfileTable = ({ name, gradYear, requiredHours, requiredExp, doneHours, doneExp }) => {
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 300 }}>
                <TableRow>
                    <TableCell variant='head'>Name: </TableCell>
                    <TableCell>{name}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell variant='head'>Class: </TableCell>
                    <TableCell>{gradYear}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell variant='head'>Service Hours: </TableCell>
                    <TableCell>{doneHours} / {requiredHours}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell variant='head'>Professional Experiences: </TableCell>
                    <TableCell>{doneExp} / {requiredExp}</TableCell>
                </TableRow>
            </Table>
        </TableContainer>
    );
};