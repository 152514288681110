import {
  Box,
} from '@mui/material';
import styled from '@mui/material/styles/styled';

// Page Template container styles =============================================

export const ContentScreenContainerSx = styled(Box)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const HeaderContainerSx = styled(Box)`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 11;
`;

export const FooterContainerSx = styled(Box)``;

export const PageSx = styled(Box)`
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  min-height: 100vh;
`;
