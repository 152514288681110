import AppBar from '@mui/material/AppBar';
import Link from '@mui/material/Link';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import styled from '@mui/material/styles/styled';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

// Admin Bar container styles ==================================================

export const ContainerSx = styled(AppBar)`
  ${(props) => props.theme.breakpoints.down('sm')} {
    display: none;
  }
`;
ContainerSx.defaultProps = {
  color: 'primary',
  enableColorOnDark: true,
  position: 'static',
};

export const InnerSx = styled(Toolbar)`
  min-height: 32px;
`;
InnerSx.defaultProps = {
  variant: 'dense',
};

// Admin Bar contact menu styles ===============================================

export const ContactMenuSx = styled('span')`
  align-items: center;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  gap: 15px; 
`;

export const ContactLinkSx = styled(Link)`
  align-items: center;
  color: white;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  gap: 3px;
`;
ContactLinkSx.defaultProps = {
  underline: 'none',
};

export const ContactTextSx = styled(Typography)``;
ContactTextSx.defaultProps = {
  variant: 'body2',
};

// Admin Bar contact icon styles ===============================================

export const ContactMailIconSx = styled(MailIcon)``;

export const ContactPhoneIconSx = styled(PhoneIcon)``;
