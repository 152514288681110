import axiosInstance from 'api_adapter/axios_instance';
import axios from 'axios';
import schema from 'shared/schema';

export const changeUserPass = async (request) => {
    const response = await axiosInstance.post('/admin/changeuserpass', request);
    return response;
};

export const createUserAccount = async (request) => {
    const response = await axiosInstance.post('/admin/createuseraccount', request);
    return response;
};

export const disableUserAccount = async (request) => {
    const response = await axiosInstance.post('/admin/disableuseraccount', request);
    return response;
};

