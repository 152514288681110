import React from 'react';
import {
  ContactContainerSx,
  ContactHeaderSx,
  ContactTextSx,
  ContainerSx,
  CopyrightTextSx,
  FacebookIconSx,
  InnerSx,
  InstagramIconSx,
  SocialMediaContainerSx,
  SocialMediaLinkSx,
  TwitterIconSx,
} from 'footer/styles/footer.style';

export default function Footer() {
  return (
    <ContainerSx>
      <InnerSx>
        <ContactContainerSx>
          <ContactHeaderSx>
            Honors Program
          </ContactHeaderSx>
          <ContactTextSx>
            Price Hall 2101
            <br />
            Clarkson University
            <br />
            8 Clarkson Avenue
            <br />
            Potsdam, NY 13699
            <br />
          </ContactTextSx>
          <ContactTextSx>
            Phone: 315-268-2290/2320
            <br />
            Email: honors@clarkson.edu
            <br />
          </ContactTextSx>
        </ContactContainerSx>
        <SocialMediaContainerSx>
          <SocialMediaLinkSx href="https://www.instagram.com/clarksonhonors/">
            <InstagramIconSx />
          </SocialMediaLinkSx>
          <SocialMediaLinkSx href="https://twitter.com/clarksonhonors">
            <TwitterIconSx />
          </SocialMediaLinkSx>
          <SocialMediaLinkSx href="https://www.facebook.com/clarksonhonors/">
            <FacebookIconSx />
          </SocialMediaLinkSx>
        </SocialMediaContainerSx>
        <CopyrightTextSx>
          © 2023 Clarkson Honors Steering Board
        </CopyrightTextSx>
      </InnerSx>
    </ContainerSx>
  );
}
