import React, {
  ReactElement,
} from 'react';
import {
  CircularProgressSx,
  LoadingScreenContainerSx,
} from 'loading_screen/styles/loading_screen.style';

/**
 * A loading screen that can be placed within a page.
 *
 * @returns {ReactElement} screen.
 */
export default function LoadingScreen() {
  return (
    <LoadingScreenContainerSx>
      <CircularProgressSx />
    </LoadingScreenContainerSx>
  );
}
