import axiosInstance from 'api_adapter/axios_instance';
import schema from 'shared/schema';

const _LoginResponsePB = schema.lookup('http.login.LoginResponse');
const _LogoutResponsePB = schema.lookup('http.logout.LogoutResponse');
const _ReadUserResponsePB = schema.lookup('http.read_user.ReadUserResponse');

export const login = async (request) => {
  const response =
    await axiosInstance
      .post(
        '/auth/login',
        {
          username: request?.username ?? undefined,
          password: request?.password ?? undefined,
        },
      );

  return _LoginResponsePB.fromObject(response.data);
};

export const logout = async () => {
  const response = await axiosInstance.post('/auth/logout');

  return _LogoutResponsePB.fromObject(response.data);
};

export const readUser = async () => {
  const response = await axiosInstance.get('/auth/user');

  return _ReadUserResponsePB.fromObject(response.data);
};

export const defaultUserCheck = async () => {
  const response = await axiosInstance.get('/auth/defaultCheck');
  return response.data.default_pw[0].default_pw;
}

export const getUserProfile = async () => {
  const response = await axiosInstance.get("/auth/userProfile");
  return response.data.profileData[0];
}

export const changeOwnPW = async (request) => {
  const response = await axiosInstance.post("/auth/changePW", request);
  return response;
}

export const getKey = async () => {
  const response = await axiosInstance.get('/key');
  return response;
}